import * as _ from 'lodash';
import { IPaginationService } from '../interfaces/IPaginationService';
import { IRow } from '../interfaces/IRow';

export class PaginationService implements IPaginationService{
	itemsPerPage: number;
  page: number;
  totalRowsCount: number;

  transformRows(rows: Array<IRow>): Array<IRow>{
  	if(this.isActive() == false){
        return rows;
    }

    this.totalRowsCount = rows.length; // question if this is the right approach

    return _.slice(rows, this.getStartIndex(), this.getStartIndex() + this.itemsPerPage);
  }

  isActive(){
    return this.itemsPerPage > 0;
  }

  setItemsPerPage(pageSize: number){
    this.itemsPerPage = pageSize;
    this.page = 1;
  }

  setTotalRowsCount(totalRowsCount: number){
    this.totalRowsCount = totalRowsCount;
  }

  getStartIndex(): number{
    return (this.page-1) * this.itemsPerPage;
  }

  getEndIndex() : number{
    return Math.min(this.getStartIndex()+this.itemsPerPage,this.totalRowsCount);
  }

  nextPage(): void{
    if(this.hasNextPage()){
      this.page++;
    }
  }

  previousPage(): void{
    if(this.hasPreviousPage()){
      this.page --;
    }
  }

  hasPreviousPage(): boolean {
    return this.page > 1;
  }

  hasNextPage(): boolean {
    var totalPages = Math.ceil(this.totalRowsCount / this.itemsPerPage);
    return this.page < totalPages;
  }
}