import { Directive, ElementRef, Renderer2, ContentChild, AfterViewInit } from '@angular/core';

import { SidebarSubLevelDirective } from './../sidebar-sub-level/sidebar-sub-level.directive';

@Directive({
	selector: '[sidebar-item]'
})
export class SidebarItemDirective implements AfterViewInit{

	@ContentChild(SidebarSubLevelDirective) subItem: SidebarSubLevelDirective;

	constructor(
		private el: ElementRef,
		private renderer: Renderer2
	){

	}

	get element(){
		return this.el;
	}

	ngAfterViewInit(){

	}
}
