import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import { CustomDatepickerI18n } from 'global/services/custom-date-picker-i18n/custom-date-picker-i18n.service';
import { BlocAccessModalComponent } from 'administration/components/bloc-access-modal/bloc-access-modal.component';
import { BreadcrumbService } from 'global/services/breadcrumb/breadcrumb.service';
import { ParametresService } from 'global/services/parametres/parametres.service';
import { SiteModalComponent } from 'administration/components/user-externe/site-modal/site-modal.component';
import { LoginService } from 'app/services/login/login.service';
import { UserExterneService } from 'administration/services/user-externe/user-externe.service';
import { SiteService } from 'administration/services/site/site.service';
import { ParametresApplication } from 'administration/models/parametres-application';
import { Utilisateur } from 'administration/models/utilisateur';
import { Site } from 'administration/models/site';
import { Administrateur } from 'administration/models/administrateur';

@Component({
  selector: 'app-user-externe-add',
  templateUrl: './user-externe-add.component.html',
  styleUrls: ['./user-externe-add.component.scss']
})
export class UserExterneAddComponent implements OnInit {

	public user: Utilisateur = new Utilisateur();
	public sites : Site[] = [];
  public invalidSubmitted: boolean = false;
  public emailExists: boolean = false;
  public loggedUser: Administrateur;
  public parametres: any;
  public confirmPassword: string;
  public siteHeaders: any[];
  public siteColumnList: any[];
  equalPassword: boolean = true;
  selectAllBloc: boolean = false;
  selectedBloc: any[] = new Array<any>();
  loading: boolean = false;
  loadingMessage: string = '';
  blocCheckBox: boolean[] = new Array<boolean>();


  constructor(
  	private appConfigService: ParametresService,
  	private userService: UserExterneService,
  	private router: Router,
    private route : ActivatedRoute,
  	private loginService: LoginService,
    private siteService: SiteService,
    private modalService: NgbModal,
    private breadcrumbService: BreadcrumbService,
    private customDatePickerService: CustomDatepickerI18n
  ) 
  {
    this.user.lignesParPage = 25;
	  this.user.langue = "Français";
    this.user.adresse.pays = "France"; 
  }

  ngOnInit() {
    this.breadcrumbService.add({label : "Nouvel utilisateur", route : this.router.routerState.snapshot.url});
    this.loggedUser = this.loginService.getLoggedUser();
    this.parametres = this.appConfigService.getParametresApplication();
    this.siteService.list()
      .subscribe(sites=>{
        if(sites) this.sites = sites.map(site =>{
            site.dateCreation = this.customDatePickerService.getDateFromModel(site.dateCreation);
            site.dateMonitoring = this.customDatePickerService.getDateFromModel(site.dateMonitoring);
            return site;
          })
        
      })
    this.siteHeaders = this.userService.siteHeaders;
    this.constructSiteColumnList();
  }

  public userEmailChanged(){
    console.log(this.user.email)
    this.userService.checkIfExists(this.user.email)
      .subscribe((res: boolean) => {
        if(res != undefined) {
          this.emailExists = res;
        }
      })
  }

  public passwordConfirmChanged(){
    if(this.user.password != this.confirmPassword){
      this.equalPassword = false;
      this.invalidSubmitted = true;
    }
    else{
      this.equalPassword = true;
      this.invalidSubmitted = false;
    }
  }

  submit(formValid: boolean){
    formValid? this.addUser() : this.invalidSubmitted = true;
  }

  public addUser(){
    this.user.createdBy = this.loggedUser.email;
    this.user.modifiedBy = this.loggedUser.email;
    this.user.createdOn = Date.now();
    this.user.modifiedOn = Date.now();
    this.user.sites = this.user.sites.map(site =>{
      site.dateCreation = this.customDatePickerService.getModelFromDateString(site.dateCreation);
      site.dateMonitoring = this.customDatePickerService.getModelFromDateString(site.dateMonitoring);
      return site;
    })
    this.user.organisations = this.user.sites.map(site => site.organisation);
    this.loading = true;
    this.loadingMessage = "Ajout de l'utilisateur "+this.user.email;
    this.userService.add(this.user)
      .subscribe((res: any) => {
        if(res != null && res != undefined && res.id != undefined){
          this.breadcrumbService.removeFrom(-2);
          this.router.navigate(["/administration/user/list"]);
        }
      })
  }

  public addBlocAccess(){
    const modal = this.modalService.open(BlocAccessModalComponent, {size: 'lg'});
    modal.result.then(result =>{
      if(result.modules && result.modules.length){
        if(!this.user.blocs) this.user.blocs = new Array<any>();
        let bloc = this.user.blocs.filter(bloc => bloc.id == result.id)[0];
        if(bloc) _.mergeWith(bloc.modules,result.modules);
        else this.user.blocs.push(result);
      }
    })
    .catch( error => console.log(error))
  }

  public deleteBlocs(){
    this.user.blocs = this.user.blocs.filter(bloc => !this.selectedBloc.map(b=>b.id).includes(bloc.id));
  }

  public blockRowSelected(bloc: any){
    this.selectedBloc.push(bloc);
    this.selectedBloc = _.uniqBy(this.selectedBloc,'id');
  }

  public updateBloc(bloc: any){
    let help = Object.assign({}, bloc);
    const modal = this.modalService.open(BlocAccessModalComponent, {size: 'lg'});
    modal.componentInstance.bloc = help;
    modal.componentInstance.modules = help.modules;
    delete help.modules;
    modal.result.then(result =>{
      if(result.modules && result.modules.length){
        if(!this.user.blocs) this.user.blocs = new Array<any>();
        let bloc = this.user.blocs.filter(bloc => bloc.id == result.id)[0];
        if(bloc) bloc.modules = result.modules;
      }
    })
    .catch( error => console.log(error))
  }

  public toggleBlocSelection(){
    if(this.selectAllBloc){
      this.selectedBloc = this.user.blocs;
      this.blocCheckBox = this.user.blocs.map(b => true);
    }
    else{
      this.selectedBloc = [];
      this.blocCheckBox = this.user.blocs.map(b => false);
    }
  }

  public siteSelected(event: any){
    this.user.sites = this.sites
      .filter(site => event.map(e => e.rowId).includes(site.id));
    console.log(this.user.sites);
  }


  public deleteSite(s: Site){
    if(this.user.sites != undefined && this.user.sites.length){
      let index = this.user.sites.map(site => site.id).indexOf(s.id);
      this.user.sites.splice(index,1);
      this.sites.push(s);
    }
  }

  constructSiteColumnList(){
    this.siteColumnList = [];
    for(let header of this.siteHeaders){
      let col : any = {};
      col.dataKey = header.nom;
      col.name = header.label;
      col.excluded = true;
      this.siteColumnList.push(col);
    }
  }

}
