import { BiogasviewDocument } from 'global/models/BiogasviewDocument';
import { Analyse } from './analyses';
import { Type , Exclude, Expose} from 'class-transformer';
import { AnalyseService } from 'administration/services/analyse/analyse.service';
import { CaracteristiquePhysicoChimique } from './caracteristique-physico-chimique';

@Exclude()
export class Intrant extends BiogasviewDocument {
  @Expose()
  code  : number;

  @Expose()
  nom  : string;

  @Expose()
  categorie  : string;

  @Expose()
  codeDechet  : string;

  @Expose()
  consistance  : string;

  @Expose()
  modeDeLivraison  : string;

  @Expose()
  sousProduitsAnimaux  : string;

  @Expose()
  documentAEmettre : string;

  @Expose()
  tags  : string;

  @Type(()=>Analyse)
  analyses : Analyse;

  @Type(()=>CaracteristiquePhysicoChimique)
  cpco : CaracteristiquePhysicoChimique[] ;
  
  constructor(){
     super();
     this.dataType = 'intrant';
     this.code = null;
     this.nom ="";
     this.categorie ="";
     this.codeDechet = "";
     this.consistance = "";
     this.modeDeLivraison = "";
     this.sousProduitsAnimaux = "";
     this.documentAEmettre = "";
     this.tags = "";
     this.analyses = new Analyse();
     this.cpco = [];
  }
}
