import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { IColumn } from '../interfaces/IColumn';
import { IRow } from '../interfaces/IRow';
import { ICell } from '../interfaces/ICell';
import { Column } from '../models/Column';
import { AlignRule } from '../enums/align-rule';
import { PaginationService } from './PaginationService';
import { SortService } from './SortService';
import { FilterService } from './FilterService';


@Injectable()
export class DataService{
	private columns_: Array<Column>;
	private rows_: Array<IRow>;
	private selectedRows_: Array<IRow>;
	private rowSelectionEnabled_: boolean;
  private rowId?: any;

	constructor(
		private paginationService_: PaginationService,
		private sortService_: SortService,
    private filterService_: FilterService
	){
		this.columns_ = [];
    this.rows_ = [];
    this.rowSelectionEnabled_ = false;
    this.selectedRows_ = [];
	}
  /**
  * Remove column
  */
  removeColumns(){
    this.columns_ = [];
  }
	/**
	* Add columns_
	* @param columns_ - columns_ to add
	*/
	addColumns(columns_:Array<IColumn>){
    _.each(columns_, (aColumn) => {
      let col = new Column();

      col.dataKey = aColumn.dataKey;
      col.name = aColumn.name;
      col.align = aColumn.align || AlignRule.Left;
      col.exclude = aColumn.exclude || false;
      if(aColumn.filterable) col.filterable = aColumn.filterable;


      this.columns_.push(col);
    });
  }
  /**
	* Add rows_
	* @param rows_ - rows_ to add
	*/
  addRows(rows_:Array<any>){
    this.rows_ = [];
    _.each(rows_,(aRow:any, rowIndex:number) => {
      var newRow: Array<ICell> = [];
      _.each(this.columns_, (aColumn:IColumn) => {
        newRow.push({
          dataKey: aColumn.dataKey,
          value: _.get(aRow,aColumn.dataKey)
        });
      });
	  if(aRow){
	      if(this.rowId){
	        this.rows_.push({
	          index: rowIndex,
	          value: newRow,
	          rowId: aRow[this.rowId]
	        });
	      }else{
	        this.rows_.push({
	          index: rowIndex,
	          value: newRow,
	          rowId: aRow._id
	        });
	      }
	    }
      if(this.selectedRows_ && this.selectedRows_.length)
        this.setSelectedRows(this.selectedRows_);
    });
  }

  setSelectedRows(rows_:Array<any>){
    if(this.rows_ && this.rows_.length){
      this.rows_.forEach(row =>{
        if(rows_.map(r => _.get(r,this.rowId)).includes(row.rowId)) this.selectRow(row);
      })
    }
  }
  /**
  * Select all showed rows
  */
  selectAllRows(){
  	_.each(this.getRows(), (aRow: IRow) =>{
  		aRow.selected = true;
  	})
  	this.selectedRows_ = this.getRows();
  }
  /**
  * Deselect all showed rows
  */
  deselectAllRows(){
  	_.each(this.getRows(), (aRow: IRow) =>{
  		aRow.selected = false;
  	})
  	this.selectedRows_ = [];
  }

  getSelectedRows():Array<IRow>{
  	return this.selectedRows_;
  }

  getColumns(): Array<Column>{
    return this.columns_;
  }

  /**
  * Get showed rows
  */
  getRows(): Array<IRow>{
    //filter rows
    let rows_ = this.filterService_.transforms(this.rows_);
  	//sorting rows_
    rows_ = this.sortService_.transformRows(rows_);
    //paginating rows_
    rows_ = this.paginationService_.transformRows(rows_);
    return rows_;
  }

  /**
  * Get cell data
  * @param column - cell column
  * @param row - cell row
  */
  getCellData(column:IColumn, row:IRow){
	let value = _.find(row.value, (aCell:ICell) => {
      return aCell.dataKey === column.dataKey
    });
	column.dataKey == 'site.nom' && value? value.value = value.value || "Tous les sites" : null;
	value? value.value = value.value || "-" : null;
	if(Array.isArray(value.value)){
		value.value = _.join(value.value,' | ');
	}
    return value || "-";
  }
  /**
  * Define row selection property
  * @param selectEnabled - selection property
  */
  setRowSelectionEnabled(isEnabled: boolean){
  	this.rowSelectionEnabled_ = isEnabled ? true : false;
  }
  /**
  * check wether select is enabled or not
  * @return row selection proerty
  */
  isRowSelectionEnabled(): boolean{
  	return this.rowSelectionEnabled_;
  }
  /**
  * Add row to selection list
  * @param row - row to add
  */
  selectRow(row: IRow){
    row.selected = true;
    if(!this.selectedRows_.map(row => row.rowId).includes(row.rowId)) 
      this.selectedRows_.push(row);
  }
  /**
  * Remove row from selection list
  * @param row - row to remove
  */
  deselectRow(row: IRow){
    row.selected = false;
    this.selectedRows_ = this.selectedRows_.filter((r: IRow) => r.rowId != row.rowId);
  }
  /**
  * Get table data row id
  */
  getRowId(): any{
      return this.rowId;
  }
  /**
  * Set table data row id
  * @param rowId - data row id
  */
  setRowId(rowId: any){
     this.rowId = rowId;
  }

}
