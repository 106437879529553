import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { CustomDatepickerI18n } from 'global/services/custom-date-picker-i18n/custom-date-picker-i18n.service';
import { PasswordModalComponent } from 'administration/components/password-modal/password-modal.component';
import { BreadcrumbService } from 'global/services/breadcrumb/breadcrumb.service';
import { BlocAccessModalComponent } from 'administration/components/bloc-access-modal/bloc-access-modal.component';
import { Site } from 'administration/models/site';
import { SiteService } from 'administration/services/site/site.service';
import { LoginService } from 'app/services/login/login.service';
import { Administrateur } from 'administration/models/administrateur';
import { Utilisateur } from 'administration/models/utilisateur';
import { UserExterneService } from 'administration/services/user-externe/user-externe.service';
import { ParametresService } from 'global/services/parametres/parametres.service';

@Component({
  selector: 'app-user-externe-update',
  templateUrl: './user-externe-update.component.html',
  styleUrls: ['./user-externe-update.component.scss']
})
export class UserExterneUpdateComponent implements OnInit {

	user : Utilisateur = new Utilisateur();
	sites: Site[];
  public invalidSubmitted: boolean = false;
  public emailExists: boolean = false;
  public loggedUser: Administrateur;
  public parametres: any;
  public confirmPassword: string;
  public siteHeaders: any[];
  public siteColumnList: any[];
  public moduleColumnList: any[];
  public moduleHeaders: any[];
  oldUser: Utilisateur = new Utilisateur();
  selectAllBloc: boolean = false;
  selectedBloc: any[] = new Array<any>();
  loading: boolean = false;
  loadingMessage: string = '';
  blocCheckBox: boolean[] = new Array<boolean>();


  constructor(private router : Router, 
  	private route : ActivatedRoute, 
  	private userService : UserExterneService,
  	private modalService : NgbModal,
  	private siteService : SiteService,
    private loginService : LoginService,
    private parametresService : ParametresService,
    private breadcrumbService: BreadcrumbService,
    private customDatePickerService: CustomDatepickerI18n
  ) {
  }

  ngOnInit() {
    this.loggedUser = this.loginService.getLoggedUser();
    // get appConfig
    this.parametres = this.parametresService.getParametresApplication();
  	if(this.route.snapshot.paramMap.keys.indexOf('idUser')>=0){
      this.siteService.list()
        .subscribe((res: Site[]) => {
          this.sites = res.map(site =>{
            site.dateCreation = this.customDatePickerService.getDateFromModel(site.dateCreation);
            site.dateMonitoring = this.customDatePickerService.getDateFromModel(site.dateMonitoring);
            return site;
          });
          this.userService.get(this.route.snapshot.paramMap.get('idUser'))
            .subscribe(user => {
              if(user && user.id ){
                this.oldUser = user;
                this.user = user;
                this.breadcrumbService.add({label : user.email, route : this.router.routerState.snapshot.url});
              }
            })
        });
  	}else this.router.navigate(["/administration/user/list"]);

    this.siteHeaders = this.userService.siteHeaders;
    this.moduleHeaders = this.userService.moduleHeaders;
    this.constructModuleColumnList();
    this.constructSiteColumnList(); 
  }

  public userEmailChanged(){
    if(this.oldUser.email != this.user.email)
      this.userService.checkIfExists(this.user.email)
        .subscribe((res: boolean) => {
          if(res != undefined) {
            this.emailExists = res;
          }
        })
  }

  submit(formValid: boolean){
    formValid? this.updateUser() : this.invalidSubmitted = true;
  }

  public updateUser(){
    this.user.createdBy = this.loggedUser.email;
    this.user.modifiedBy = this.loggedUser.email;
    this.user.modifiedOn = Date.now();
    this.user.sites = this.user.sites.map(site =>{
      site.dateCreation = this.customDatePickerService.getModelFromDateString(site.dateCreation);
      site.dateMonitoring = this.customDatePickerService.getModelFromDateString(site.dateMonitoring);
      return site;
    })
    this.user.organisations = this.user.sites.map(site => site.organisation);
    this.user.organisations = _.uniqBy(this.user.organisations, 'id');
    this.loading = true;
    this.loadingMessage = "Mise à jour de l'utilisateur "+this.user.email;
    this.userService.update(this.user)
      .subscribe((res: any) => {
        if(res != null && res != undefined && res.id != undefined){
          this.breadcrumbService.removeFrom(-2);
          this.router.navigate(["/administration/user/list"]);
        }
      })
  }

  public siteSelected(event: any){
    this.user.sites = this.sites
      .filter(site => event.map(e => e.rowId).includes(site.id));
  }


  public deleteSite(s: Site){
    if(this.user.sites != undefined && this.user.sites.length){
      let index = this.user.sites.map(site => site.id).indexOf(s.id);
      this.user.sites.splice(index,1);
      // add to sites list
      this.sites.push(s);
    }
  }

  public changePassword(){
    const modal = this.modalService.open(PasswordModalComponent,{size: 'lg'});
    modal.componentInstance.user = this.user;
    modal.result.then(result =>{
      this.loadingMessage = 'Changement du mot de passe';
      this.loading = true;
      this.userService.changePassword(result)
        .subscribe(res =>{
          if(res != undefined){
            this.user.revision = res.revision;
            this.loading = false;
          }
        })
    })
    .catch(error => console.log(error));
  }

  public addBlocAccess(){
    const modal = this.modalService.open(BlocAccessModalComponent, {size: 'lg'});
    modal.result.then(result =>{
      if(result.modules && result.modules.length){
        if(!this.user.blocs) this.user.blocs = new Array<any>();
        let bloc = this.user.blocs.filter(bloc => bloc.id == result.id)[0];
        if(bloc) _.mergeWith(bloc.modules,result.modules);
        else this.user.blocs.push(result);
      }
    })
    .catch( error => console.log(error))
  }

  public deleteBlocs(){
    this.user.blocs = this.user.blocs.filter(bloc => !this.selectedBloc.map(b=>b.id).includes(bloc.id));
  }

  public blockRowSelected(bloc: any){
    this.selectedBloc.push(bloc);
    this.selectedBloc = _.uniqBy(this.selectedBloc,'id');
  }

  public updateBloc(bloc: any){
    let help = Object.assign({}, bloc);
    const modal = this.modalService.open(BlocAccessModalComponent, {size: 'lg'});
    modal.componentInstance.bloc = help;
    modal.componentInstance.modules = help.modules;
    delete help.modules;
    modal.result.then(result =>{
      if(result.modules && result.modules.length){
        if(!this.user.blocs) this.user.blocs = new Array<any>();
        let bloc = this.user.blocs.filter(bloc => bloc.id == result.id)[0];
        if(bloc) bloc.modules = result.modules;
      }
    })
    .catch( error => console.log(error))
  }

  public toggleBlocSelection(){
   if(this.selectAllBloc){
     this.selectedBloc = this.user.blocs;
     this.blocCheckBox = this.user.blocs.map(b => true);
   }
   else{
     this.selectedBloc = [];
     this.blocCheckBox = this.user.blocs.map(b => false);
   }
  }

  constructSiteColumnList(){
    this.siteColumnList = [];
    for(let header of this.siteHeaders){
      let col : any = {};
      col.dataKey = header.nom;
      col.name = header.label;
      col.excluded = true;
      this.siteColumnList.push(col);
    }
  }

  constructModuleColumnList(){
    this.moduleColumnList = [];
    for(let header of this.moduleHeaders){
      let col : any = {};
      col.dataKey = header.nom;
      col.name = header.label;
      col.excluded = true;
      this.moduleColumnList.push(col);
    }
  }



}
