import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { TreeModule } from 'angular-tree-component';

import {  ExportCsvService } from './services/export-csv/export-csv.service';
import { ParametresService } from './services/parametres/parametres.service';
import { BreadcrumbService } from './services/breadcrumb/breadcrumb.service';
import { NavigationService } from './services/navigation/navigation.service';
import { DataTableModule } from './modules/data-table/data-table.module';
import { ToggleTableModule } from './modules/toggle-table/toggle-table.module';
import { HomeComponent } from './components/home/home.component';
import { CustomInputComponent } from './components/custom-input/custom-input.component';
import { CloseMobileDirective } from './directives/close-mobile/close-mobile.directive';
import { DropdownPositionDirective } from './directives/dropdown-position/dropdown-position.directive';
import { NavSidebarDirective } from './directives/nav-sidebar/nav-sidebar.directive';
import { PageLayoutDirective } from './directives/page-layout/page-layout.directive';
import { PatternValidatorDirective } from './directives/pattern-validator/pattern-validator.directive';
import { SidebarItemDirective } from './directives/sidebar-item/sidebar-item.directive';
import { SidebarSubLevelDirective } from './directives/sidebar-sub-level/sidebar-sub-level.directive';
import { SidebarTogglerDirective } from './directives/sidebar-toggler/sidebar-toggler.directive';
import { SidebarTopLevelDirective } from './directives/sidebar-top-level/sidebar-top-level.directive';
import { MobileTogglerDirective } from './directives/mobile-toggler/mobile-toggler.directive';
import { FirstChartPipe } from './pipes/first-chart/first-chart.pipe';
import { JoinArrayPipe } from './pipes/join-array/join-array.pipe';
import { ExporterComponent } from './components/exporter/exporter.component';

import { NgbDateCustomParserFormatter } from './services/ngb-date-custom-parser-formatter/ngb-date-custom-parser-formatter';
import { DatePickerAdapter } from './services/date-picker-adapter/date-picker-adapter.service';
import { I18n, CustomDatepickerI18n } from './services/custom-date-picker-i18n/custom-date-picker-i18n.service';
import { XhrinterceptorService } from './services/xhrinterceptor/xhrinterceptor.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ConfirmationGuardService } from './services/confirmation-guard/confirmation-guard.service';
import { AlertModalComponent } from './services/confirmation-guard/confirmation-guard.service';
import { ConfirmModalComponent } from './services/confirmation-guard/confirmation-guard.service';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ProvideParentFormDirective } from './directives/provide-parent-form/provide-parent-form.directive';


@NgModule({
  imports: [
    CommonModule,
    DataTableModule,
    ToggleTableModule,
    FormsModule,
    NgbModule.forRoot(),
    RouterModule.forChild([]),
    TreeModule
  ],
  exports:[
    FirstChartPipe,
    JoinArrayPipe,
    DataTableModule,
    ToggleTableModule,
    BreadcrumbComponent,
    HomeComponent,
    CustomInputComponent,
    FormsModule
  ],
  declarations: [
  	CustomInputComponent,
  	CloseMobileDirective,
  	DropdownPositionDirective,
  	NavSidebarDirective,
  	PageLayoutDirective,
  	PatternValidatorDirective,
  	SidebarItemDirective,
  	SidebarSubLevelDirective,
  	SidebarTogglerDirective,
  	SidebarTopLevelDirective,
  	MobileTogglerDirective,
  	FirstChartPipe,
  	JoinArrayPipe,
  	ExporterComponent,
  	BreadcrumbComponent,
    HomeComponent,
    ConfirmModalComponent,
    AlertModalComponent,
    NavigationComponent,
    ProvideParentFormDirective
	],
  providers:[
    ExportCsvService,
    ConfirmationGuardService,
    ParametresService,
    BreadcrumbService,
    NavigationService,
    {
      provide : NgbDateParserFormatter, useClass : NgbDateCustomParserFormatter
    },
    I18n,
    {
      provide : NgbDatepickerI18n, useClass : CustomDatepickerI18n 
    },
    {
      provide: NgbDateAdapter, useClass: DatePickerAdapter
    },
    { provide: HTTP_INTERCEPTORS, useClass: XhrinterceptorService, multi: true }
  ],
  entryComponents:[
    AlertModalComponent,
    ConfirmModalComponent
  ]
})
export class GlobalModule { }
