import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ParametresService } from 'global/services/parametres/parametres.service';

@Component({
  selector: 'app-attachement-modal',
  templateUrl: './attachement-modal.component.html',
  styleUrls: ['./attachement-modal.component.scss']
})
export class AttachementModalComponent implements OnInit {
	@Input() attachements: any[] = [];
	parametres: any = {};
	att: any = {file: {} };

  constructor(
  	private _activeModal: NgbActiveModal,
  	private parametresService: ParametresService
	) { }

  ngOnInit() {
  	this.parametres = this.parametresService.getParametresApplication();
  	if(this.attachements && this.attachements.length) this.parametres.siteAttachmentTypes = this.parametres.siteAttachmentTypes.filter(type => !this.attachements.map(att => att.documentType).includes(type))
  }

  onFileChange(event){
    if(event.target.files.length){
    	this.att.name = event.target.files.item(0).name;
    	this.att.file = event.target.files.item(0);
    	this.att.size = event.target.files.item(0).size;
    	this.att.createdOn =  new Date();
    }
  }

  save(){
  	this._activeModal.close(this.att);
  }

  close(){
  	this._activeModal.dismiss('cancelled');
  }

}
