import { Exclude, Expose, Type } from 'class-transformer';
import { CaracteristiquePhysicoChimique } from './caracteristique-physico-chimique';

@Exclude()
export class AnalyseOligoEtm {

	@Type(()=>CaracteristiquePhysicoChimique)
    cpc : CaracteristiquePhysicoChimique[];

	constructor(){}
}
