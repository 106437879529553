import { Directive, ElementRef } from '@angular/core';

@Directive({
	selector: '[toggle-sidebar]'
})
export class SidebarTogglerDirective{

	constructor(
		private el: ElementRef
	){

	}

	get element(){
		return this.el;
	}
}