import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators';

import { Intrant } from 'administration/models/intrant';
import { AnalyseAgronomique } from 'administration/models/analyse-agronomique';
import { AnalyseBiologique } from 'administration/models/analyse-biologique';
import { AnalyseBMP } from 'administration/models/analyse-bmp';
import { AnalyseCTO } from 'administration/models/analyse-cto';
import { AnalyseNutritionnelle } from 'administration/models/analyse-nutritionnelle';
import { AnalyseOligoEtm } from 'administration/models/analyse-oligo-etm';
import { AnalyseSanitaire } from 'administration/models/analyse-sanitaire';
import { Analyse } from 'administration/models/analyses';

import { AnalyseService } from 'administration/services/analyse/analyse.service';

import { plainToClass, deserialize } from "class-transformer";

import { CaracteristiquePhysicoChimique } from 'administration/models/caracteristique-physico-chimique';


const httpOptions = {
headers: new HttpHeaders({
'Content-Type':'application/json',
'Access-Control-Allow-Origin':'*'
})
};

const header = "code,nbValeursObligatoires,typeAnalyse,categorie,siteMetha,nom,dateAnalyse,laboratoire,commentaire,codeDechet,consistance,modeDeLivraison,sousProduitsAnimaux,documentAEmettre,tags,matiereSeche,matiereOrganique,potentielMethane,potentielBiogaz,azoteAmmoniacal,azoteTotal,phosphoreP2O5,potassiumK2O,tauxCh4,nh4N,carboneAzote,carboneOrganique,celluloseBrute,cendreTotal,digestibiliteMo,extractifNonAzoteReel,matiereGrasseBrute,matiereOrganiqueIndigeree,proteineBrute,proteineBruteReelle,ammoniacalNonIonique,azoteNitrique,calciumCaO,magnesiumMgO,sodiumNa2O,souffreSO3,chlorures,bore,fer,manganese,molybdene,cobalt,cadmium,chrome,cuivre,mercure,nickel,plomb,zinc,phosphoreP,potassiumK,calciumCa,magnesiumMg,sodiumNa,souffreS,selenium,arsenic,crCuNiZn,acideAcetique,acidePropionique,acideIsoButyrique,acideNButyrique,acideIsoValerique,acideNValerique,ph,agvTotal,tac,fos,fostac,salmonella,oeufsHelminthes,germesButyriques,coliformesThermotolerants,escherichiaColi,sporesAnaerobies,fluoranthene,benzoFluoranthene,benzoPyrene,pcbTotal,hapTotaux"

const neededKeys = ["nom","categorie","codeDechet","consistance","modeDeLivraison","sousProduitsAnimaux","documentAEmettre","tags","matiereSeche","matiereOrganique","potentielMethane","azoteAmmoniacal","azoteTotal","phosphoreP2O5","potassiumK2O"]

@Injectable()
export class IntrantService {

	private defaultColumnListForIntrantTable = ["categorie","nom","matiereSeche","matiereOrganique","potentielMethane"];

constructor(private http : HttpClient, private analyseService : AnalyseService) { }

	/**
	* Handle Http operation that failed.
	* Let the app continue.
	* @param operation - name of the operation that failed
	* @param result - optional value to return as the observable result
	*/


	private handleError<T> (operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {

		// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead
		// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}

	/**
	* List all intrants
	*/
	public list(): Observable<Intrant[]>{
		return this.http.get<Intrant[]>(environment.api+'/intrants/list',httpOptions)
		.pipe(
			catchError(this.handleError<Intrant[]>('intrant-list'))
			)
	}

	/**
	*Get intrant by id
	*@param id - intrant document id
	*/
	public get(id : string) : Observable<Intrant>{
		return this.http.get<Intrant>(environment.api+'/intrants/find/'+id,httpOptions)
		.pipe(
			catchError(this.handleError<Intrant>('intrant-find'))
			)
	}

	/**
	*Delete intrant by id
	*@param id - intrant document id
	*/
	public delete(id : string) : Observable<Intrant>{
		return this.http.delete<Intrant>(environment.api+'/intrants/delete/'+id,httpOptions)
		.pipe(
			catchError(this.handleError<Intrant>('intrant-delete'))
			)
	}

	/**
	* Add an intrant
	*@param Intrant intrant
	*/
	public add(intrant : Intrant) : Observable<Intrant>{
		console.log("FROM SERVICE", intrant)
		return this.http.post<Intrant>(environment.api+'/intrants/create/',intrant,httpOptions)
		.pipe(
			catchError(this.handleError<Intrant>('intrant-create'))
			)
	}

	/**
	* Update an existing intrant
	*@param Intrant intrant
	*/
	public update(intrant : Intrant) : Observable<Intrant>{
		return this.http.put<Intrant>(environment.api+'/intrants/update/',intrant,httpOptions)
		.pipe(
			catchError(this.handleError<Intrant>('intrant-update'))
			)
	}

	public constructIntrantDTO() : Intrant {
		let intrant = this.constructIntrantBase();
		intrant.analyses = this.analyseService.newAnalyseDTO();
		intrant.analyses.analyseOligoEtm = this.analyseService.createTypeAnalyseDTO("analyseOligoEtm");
		intrant.analyses.analyseNutritionnelle = this.analyseService.createTypeAnalyseDTO("analyseNutritionnelle");
		intrant.analyses.analyseAgronomique = this.analyseService.createTypeAnalyseDTO("analyseAgronomique");
		return intrant;
	}

	public constructIntrantBase() : Intrant {
		let intrant = new Intrant();
		intrant.cpco = [
	       new CaracteristiquePhysicoChimique("matiereSeche", "Matière sèche", this.analyseService.units["matiereSeche"], null, true),
	       new CaracteristiquePhysicoChimique("matiereOrganique", "Matière Organique", this.analyseService.units["matiereOrganique"], null, true),
	       new CaracteristiquePhysicoChimique("tauxCh4","Taux de CH4", this.analyseService.units["tauxCh4"], null, true),
	       new CaracteristiquePhysicoChimique("potentielMethane", "Potentiel méthane", this.analyseService.units["potentielMethane"], null, true),
	       new CaracteristiquePhysicoChimique("potentielBiogaz", "Potentiel biogaz", this.analyseService.units["potentielBiogaz"], null, true),
	       new CaracteristiquePhysicoChimique("azoteAmmoniacal", "Azote ammoniacal", this.analyseService.units["azoteAmmoniacal"], null, true),
	       new CaracteristiquePhysicoChimique("azoteTotal", "Azote total", this.analyseService.units["azoteTotal"], null, true),
	       new CaracteristiquePhysicoChimique("phosphoreP2O5", "Phosphore ", this.analyseService.units["phosphoreP2O5"], null, true),
	       new CaracteristiquePhysicoChimique("potassiumK2O", "Potassium", this.analyseService.units["potassiumK2O"], null, true)
	    ]
	    return intrant;
	}

	public csvToJson(csv,sep) {
		let lines = csv.split('\n');
		lines[0] = header;
		var headers = lines[0].split(",");
		var intrants : any = [];
		var rawIntrants : any = [];
		var result : any = {};

		for (var i = 1; i < lines.length; i++) {

			var obj = {};
			var currentline = lines[i].split(sep);
			for (var j = 0; j < headers.length; j++) {
				if(headers[j]=="dateAnalyse"){
					try{
						let date = new Date(currentline[j]);
						obj[headers[j]] = date.getTime();
					}
					catch(error){
						obj[headers[j]] = null;
					}
				}else{
					obj[headers[j]] = currentline[j];
				}

			}

			intrants.push(obj);
			rawIntrants.push(obj);

		}
		result.intrants = intrants;
		result.rawIntrants = rawIntrants;
		return result;
	}

	public isValid(intrant : Intrant) : boolean{
		let keys = Object.keys(intrant);
		keys= keys.filter(key=>neededKeys.indexOf(key)>-1);
		for (let key of keys){
			if(intrant[key]==null || intrant[key]==undefined || intrant[key]=="" || intrant[key]==NaN){
				return false;
			}
		}
		return true;
	}

	public mapPlainJsonToIntrant(jsonObject) : Intrant{
		//require to have excluse and expose on every class concerned by the transformation
		let intrant = deserialize(Intrant,jsonObject);
		console.log("jsonObject = ",jsonObject);
		console.log("intrant = ",intrant);
		let analyse = deserialize(Analyse, jsonObject);
		let analyseOligoEtm = deserialize(AnalyseOligoEtm,jsonObject);
		let analyseAgronomique = deserialize(AnalyseAgronomique,jsonObject);
		let analyseBiologique = deserialize(AnalyseBiologique,jsonObject);
		let analyseCTO = deserialize(AnalyseCTO,jsonObject);
		let analyseNutritionnelle = deserialize(AnalyseNutritionnelle,jsonObject);
		let analyseBMP = deserialize(AnalyseBMP,jsonObject);
		let analyseSanitaire = deserialize(AnalyseSanitaire,jsonObject);
		analyse.analyseOligoEtm = analyseOligoEtm;
		analyse.analyseAgronomique = analyseAgronomique;
		analyse.analyseBiologique = analyseBiologique;
		analyse.analyseCTO = analyseCTO;
		analyse.analyseNutritionnelle = analyseNutritionnelle;
		analyse.analyseBMP = analyseBMP;
		analyse.analyseSanitaire = analyseSanitaire;
		intrant.analyses = analyse;
		console.log(intrant);
		return intrant as Intrant;

	}

	public createTypeAnalyseDTO(analyseName){
		return this.analyseService.createTypeAnalyseDTO(analyseName);
	}

	get analyseNames(){
		return this.analyseService.analyseNames;
	}

	get defaultColumnList(){
		return this.defaultColumnListForIntrantTable;
	}

	get fieldNames(){
		return this._fieldNames;
	}

	get categories(){
		return this._categories;
	}

	get consistances(){
		return this._consistances;
	}

	get sousProduitsAnimaux(){
		return this._sousProduitsAnimaux;
	}

	get documentAEmettre(){
		return this._documentAEmettre;
	}

	get codeDechets(){
		return this._codeDechets;
	}

	get modeDeLivraison(){
		return this._modeDeLivraison;
	}

	private _codeDechets = {
	    "02 01 06" : "Effluents d'élevages",
	    "02 01 03" : "Déchets de tissus végétaux issus de l'agriculture",
	    "02 02 02" : "Déchets IAA animaux (sauf lait)",
	    "02 03 04" : "Déchets IAA végétaux (sauf sucre, alcool)",
	    "02 04 99" : "Déchets d'industrie sucre",
	    "02 05 02" : "Eaux blanches de laiterie",
	    "02 05 01" : "Déchets d'industrie lait",
	    "02 07 04" : "Déchets d'industrie de l'alcool",
	    "19 08 05" : "Eaux usées et boues de STEP",
	    "19 08 09" : "Graisses d'épuration ou de flottation de STEP",
	    "20 01 08" : "Biodéchets",
	    "20 02 01" : "Déchets verts fermentescibles",
	    "20 03 02" : "Déchets de marchés",
	    "03 03 11" : "Boues de papeteries",
	    "19 05 03" : "Compost déclassé",
	    "19 06 06" : "Digestats de méthanisation"
	};

	private _modeDeLivraison : string[] = ["Citerne (Transport de liquide ou gaz en vrac)",
		"Benne (Transport de solide en vrac)",
		"GRV (Grands Récipients pour Vrac - en. IBC)",
		"Fût (Bidons de transport)",
		"Pompage"]

	private _categories : string[] = ["Effluents d'élevage solides",
		"Effluents d'élevage liquides",
		"Cultures principales",
		"Cultures intermédiaires",
		"Résidus de cultures",
		"Déchets IAA d'origine végétale",
		"Déchets IAA d'origine animal",
		"Boues de STEP",
		"Ordures ménagères",
		"Biodéchets",
		"Déchets verts",
		"Sous produits IAA d'origine végétale",
		"Autre"];

	private _consistances : string[] = ["Solide",
		"Liquide",
		"Gazeux"];

	private _sousProduitsAnimaux : string[] = ["SPA C1","SPA C2","SPA C3","Non"];

	private _documentAEmettre : string[] = ["BSD","DAC","Aucun"];

	private _fieldNames = {
		ms : "Matière sèche",
		mo : "Matière organique",
		matiereSeche : "Matière sèche",
		matiereOrganique : "Matière organique",
		codeDechet : "Code déchet",
		typeAnalyse : "Type d'analyse",
		nom : "Description",
		categorie : "Catégorie",
		siteMetha : "Site de méthanisation",
		code : "Code",
		nbValeursObligatoires :"# valeurs obligatoires",
		laboratoire : "Laboratoire",
		commentaire : "Commentaires",
		consistance : "Consistance",
		modeDeLivraison : "Mode de livraison",
		sousProduitsAnimaux  : "Sous-produit-animaux",
		documentAEmettre : "Document à émettre",
		tags : "Tags",
		potentielMethane : "Potentiel méthane",
		azoteAmmoniacal : "Azote ammoniacal",
		azoteTotal : "Azote totale",
		phosphoreP2O5 :"Phosphore (P2O5)",
		potassiumK2O : "Potassium (K2O)",
		dateAnalyse : "Date d'analyse",
		ammoniacalNonIonique : "Ammoniacal non ionique",
	    azoteNitrique : "Azote nitrique",
	    calciumCaO : "Calcium (CaO)",
	    magnesiumMgO : "Magnesium (MgO)",
	    sodiumNa2O : "Sodium (Na2O)",
	    souffreSO3 : "Souffre (SO3)",
	    chlorures : "Chlorures",
	    acideAcetique : "Acide acétique",
	    acidePropionique : "Acide propionique",
	    acideIsoButyrique : "Acide iso-butyrique",
	    acideNButyrique : "Acide n-butyrique",
	    acideIsoValerique : "Acide iso-valerique",
	    acideNValerique : "Acide n-valerique",
	    ph : "Ph",
	    agvTotal : "AGV total",
	    tac : "TAC",
	    fos : "FOS",
	    fostac : "FOSTAC",
	    tauxCh4 : "Taux CH4",
		nh4 : "NH4/N",
		nh4N : "NH4/N",
		potentielBiogaz : "Potentiel biogaz",
		fluoranthene : "Fluoranthene",
	    benzoFluoranthene : "Benzo-fluoranthene",
	    benzoPyrene : "Benzo-pyrene",
	    pcbTotal : "PCB total",
	    hapTotaux : "Hap totaux",
	    cn : "C/N",
	    cN : "C/N",
	    carboneAzote : "C/N",
	    carboneOrganique : "Carbone organique",
	    celluloseBrute : "Cellulose brute",
	    cendreTotal : "Cendre total",
	    digestibiliteMo : "Digestibilité de la MO",
	    extractifNonAzoteReel : "Extractif non azoté réel",
	    matiereGrasseBrute : "Matière grasse brute",
	    matiereOrganiqueIndigeree : "Matière organique indigérée",
	    proteineBrute : "Protéine brute",
	    proteineBruteReelle : "Protéine brute réelle",
	    bore : "Bore",
		fer : "Fer",
		manganese : "Manganese",
		molybdene : "Molybdene",
		cobalt : "Cobalt",
		cadmium : "Cadmium",
		chrome : "Chrome",
		cuivre : "Cuivre",
		mercure : "Mercure",
		nickel : "Nickel",
		plomb : "Plomb",
		zinc : "Zinc",
		phosphoreP : "Phosphore (P)",
		potassiumK : "Potassium (K)",
		calciumCa : "Calcium (Ca)",
		magnesiumMg : "Magnesium (Mg)",
		sodiumNa : "Sodium (Na)",
		souffreS : "Souffre (S)",
		selenium : "Selenium",
		arsenic : "Arsenic",
		crCuNiZn : "Cr+Cu+Ni+Zn",
		salmonella : "Salmonella",
	    oeufsHelminthes : "Oeufs d'helmynthes",
	    germesButyriques : "Germes butyriques",
	    coliformesThermotolerants : "Coliformes thermotolérants",
	    escherichiaColi : "Escherichia Coli",
	    sporesAnaerobies : "Spores d'anaérobies sulfito-réducteurs"
	}

	private _cpcIntrant = [
		"matiereSeche",
		"matiereOrganique",
		"tauxCh4",
		"potentielBiogaz",
		"potentielMethane",
		"phosphoreP2O5",
		"potassiumK2O",
		"azoteAmmonical",
		"azoteTotal"
	]


}
