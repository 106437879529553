import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GlobalModule } from 'global/global.module';
import { ConfigurationRoutingModule } from './configuration-routing.module';
import { ConfigurationComponent } from './components/configuration.component';
import { LogComponent } from './components/log/log.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    GlobalModule,
    ConfigurationRoutingModule,
    NgbModule
  ],
  declarations: [
  	LogComponent,
  	ConfigurationComponent
	]
})
export class ConfigurationModule { }
