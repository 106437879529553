import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Intrant } from 'administration/models/intrant';
import { IntrantService } from 'administration/services/intrant/intrant.service';
import { FileService } from 'administration/services/file/file.service';
import { LoginService } from 'app/services/login/login.service';
import { Router } from '@angular/router';
import { Administrateur } from 'administration/models/administrateur';

@Component({
  selector: 'app-intrant-add-from-file',
  templateUrl: './intrant-add-from-file.component.html',
  styleUrls: ['./intrant-add-from-file.component.scss']
})
export class IntrantAddFromFileComponent implements OnInit, AfterViewInit {

  public intrants : Intrant[] = [];
  public headers ;
  public isValid = true;
  public fieldNames;
  public invalidIntrant : string[] = [];
  public rawIntrants : any[];
  private loggedUser : Administrateur;

  constructor(private intrantService : IntrantService,
    private fileService : FileService, 
    private router : Router, 
    private loginService : LoginService,
    private cd : ChangeDetectorRef) { }

  ngOnInit() {
    this.loggedUser = this.loginService.getLoggedUser();
    this.fieldNames = this.intrantService.fieldNames;
	
  }

  public uploadFile(event){
    this.fileService.readFileToIntrantArray(event).subscribe(obj=>{
      this.intrants = this.filterIntrants(obj.intrants);
      console.log(this.intrants);
      this.headers = obj.headers;
      console.log("rawIntrants",obj.rawIntrants);
      this.rawIntrants = this.filterIntrants(obj.rawIntrants);
      this.cd.detectChanges();
    });
  }

  ngAfterViewInit(){
    this.cd.detectChanges();
  }



  public getValues(obj){
    let res = <any>obj;
    if(res.dateAnalyse){
      let date : Date = new Date(res.dateAnalyse);
      res.dateAnalyse = date.toLocaleDateString();
    }else{
      res.dateAnalyse = null;
    }
    return Object.values(res);
  }

  public validate(){this.isValid = true;}

  public filterIntrants(intrants : Intrant[]) : Intrant[] {
    return intrants.filter(intrant => intrant.nom);
  }

  public validIntrant(intrant : Intrant) : string{
    if(this.intrantService.isValid(intrant)){
      return "";
    }else{
      return "table-danger";
    }
  }


  //TODO implement a bulk here
  public addIntrants(){
    this.intrants.forEach(intrant=>{
      if(this.intrantService.isValid(intrant)){
        intrant.modifiedBy = this.loggedUser.email;
        intrant.createdBy = this.loggedUser.email;
        intrant.dataType = 'intrant';
        intrant.createdOn = Date.now();
        intrant.modifiedOn = Date.now();
        this.intrantService.add(intrant)
        .subscribe((res : Intrant)=>{
          res? this.router.navigate(['/administration/intrants/list']) : console.log(res) ;
        });
      }else{
        console.error(intrant.nom," n'a pas pu être enregistré, veuillez vérifier que les champs requis sont remplis.");
        this.invalidIntrant.push(intrant.nom);
      }
    })
    if(this.invalidIntrant.length>0) {
      let msg = "Attention!\n Les intrants : \n";
      this.invalidIntrant.forEach(nom=>{
        msg+="-"
        msg+=nom;
        msg+='\n';
      })
      msg+="n'ont pas pu être enregistré, veuillez réessayer en complétant les champs obligatoires. \n \n";
      msg+="Les intrants valides seront créés en doublon s'ils sont présents dans le fichier importé.";
      alert(msg);
    }
  }

 
    

}
