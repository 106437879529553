import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs/Observable';
import { IndexFacturationAnnuelle } from 'administration/models/index-facturation-annuelle';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Access-Control-Allow-Origin':'*'
  })
};

@Injectable()
export class IndexFacturationService {

  constructor(
  	private _router: Router,
  	private _http: HttpClient
	) { }

   /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
	private handleError<T> (operation = 'operation', result?: T) {
	  return (error: any): Observable<T> => {

      if(error.status == 401 && error.statusText == 'OK'){
        this._router.navigate(['signin']);
      }
	    // TODO: send the error to remote logging infrastructure
	    console.error(error); // log to console instead

	    

	    // Let the app keep running by returning an empty result.
	    return of(result as T);
	  };
	}

	/**
	* Get index by year
	* @param year - index year
	*/
	public get(year: number): Observable<IndexFacturationAnnuelle>{
		return this._http.get<IndexFacturationAnnuelle>(environment.api+'/indexfacturation/year/'+year)
		.pipe(
			catchError(this.handleError<IndexFacturationAnnuelle>('indexfacturation-by-year'))
			)
	}

	/**
	* Add an organization to the database
	* @param organisation - the organisation to add
	*/
  public add(index : IndexFacturationAnnuelle): Observable<IndexFacturationAnnuelle>{
    return this._http.post<IndexFacturationAnnuelle>(environment.api+'/indexfacturation/create',index)
    .pipe(
      catchError(this.handleError<IndexFacturationAnnuelle>('indexfacturation'))
    )
  }

  /**
	* Update an organization to the database
	* @param index - the index to update
	*/
  public update(index : IndexFacturationAnnuelle){
    return this._http.put<IndexFacturationAnnuelle>(environment.api+'/indexfacturation/update',index)
    .pipe(
      catchError(this.handleError<IndexFacturationAnnuelle>('indexfacturation'))
    )
  }

}
