import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import { CustomDatepickerI18n } from 'global/services/custom-date-picker-i18n/custom-date-picker-i18n.service';
import { BlocAccessModalComponent } from 'administration/components/bloc-access-modal/bloc-access-modal.component';
import { BreadcrumbService } from 'global/services/breadcrumb/breadcrumb.service';
import { ParametresService } from 'global/services/parametres/parametres.service';
import { LoginService } from 'app/services/login/login.service';
import { UserService } from 'administration/services/user/user.service';
import { SiteService } from 'administration/services/site/site.service';
import { ParametresApplication } from 'administration/models/parametres-application';
import { UtilisateurOrganisation } from 'administration/models/utilisateur-organisation';
import { Site } from 'administration/models/site';
import { Administrateur } from 'administration/models/administrateur';
import { OrganisationService } from 'administration/services/organisation/organisation.service';
import { Organisation } from 'administration/models/organisation';


@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent implements OnInit {

  public sites: Site[] = [];
	public user: UtilisateurOrganisation = new UtilisateurOrganisation();
	public invalidSubmitted: boolean = false;
  public emailExists: boolean = false;
	public loggedUser: Administrateur;
  private organisation : any = {};
  public parametres: any;
  public confirmPassword: string;
  public siteHeaders: any[];
  public siteColumnList: any[];
  equalPassword: boolean = true;
  selectAllBloc: boolean = false;
  selectedBloc: any[] = new Array<any>();
  loading: boolean = false;
  loadingMessage: string = '';
  blocCheckBox: boolean[] = new Array<boolean>();

  constructor(
  	private appConfigService: ParametresService,
  	private userService: UserService,
  	private router: Router,
    private route : ActivatedRoute,
  	private loginService: LoginService,
    private siteService: SiteService,
    private modalService: NgbModal,
    private organisationService : OrganisationService,
    private breadcrumbService: BreadcrumbService,
    private customDatePickerService: CustomDatepickerI18n
	) {
		this.user.lignesParPage = 25;
		this.user.langue = "Français";
    this.user.adresse.pays = "France";
    this.user.organisation = new Organisation();
    this.user.sites = new Array<Site>();
	}

  ngOnInit() {
  	this.loggedUser = this.loginService.getLoggedUser();
    // get appConfig
  	this.parametres = this.appConfigService.getParametresApplication();
    if(this.route.snapshot.paramMap.keys.indexOf('idOrga')>=0){
      this.organisationService.get(this.route.snapshot.paramMap.get('idOrga'))
        .subscribe((res: Organisation) => {
          this.organisation = res;
          this.sites = res.sites.map(site =>{
            site.dateCreation = this.customDatePickerService.getDateFromModel(site.dateCreation);
            site.dateMonitoring = this.customDatePickerService.getDateFromModel(site.dateMonitoring);
            return site;
          })
          this.user.organisation.id = res.id;
          this.user.organisation.nom = res.nom;
          this.breadcrumbService.add({label : 'Nouvel utilisateur', route : this.router.routerState.snapshot.url});
        });
    }
    this.siteHeaders = this.siteService.headers;
    this.constructSiteColumnList();
  }

  public userEmailChanged(){
    console.log(this.user.email)
  	this.userService.checkIfExists(this.user.email)
  		.subscribe((res: boolean) => {
  			if(res != undefined) {
          this.emailExists = res;
        }
  		})
  }

  public passwordConfirmChanged(){
    if(this.user.password != this.confirmPassword){
      this.equalPassword = false;
      this.invalidSubmitted = true;
    }
    else{
      this.equalPassword = true;
      this.invalidSubmitted = false;
    }
  }

  submit(formValid: boolean){
    formValid? this.addUser() : this.invalidSubmitted = true;
  }

  public addUser(){
    this.user.createdBy = this.loggedUser.email;
    this.user.modifiedBy = this.loggedUser.email;
    this.user.createdOn = Date.now();
    this.user.modifiedOn = Date.now();
    this.user.sites = this.user.sites.map(site =>{
      site.dateCreation = this.customDatePickerService.getModelFromDateString(site.dateCreation);
      site.dateMonitoring = this.customDatePickerService.getModelFromDateString(site.dateMonitoring);
      return site;
    })
    this.loading = true;
    this.loadingMessage = "Ajout de l'utilisateur "+this.user.email;
    this.userService.add(this.user)
      .subscribe((res: any) => {
        if(res != null && res != undefined && res.id != undefined){
          this.router.navigate(["/administration/organisation/",this.organisation.id]);
        }
      })
  }

  public addBlocAccess(){
    const modal = this.modalService.open(BlocAccessModalComponent, {size: 'lg'});
    modal.result.then(result =>{
      if(result.modules && result.modules.length){
        if(!this.user.blocs) this.user.blocs = new Array<any>();
        let bloc = this.user.blocs.filter(bloc => bloc.id == result.id)[0];
        if(bloc) _.mergeWith(bloc.modules,result.modules);
        else this.user.blocs.push(result);
        console.log(this.user.blocs);
      }
    })
    .catch( error => console.log(error))
  }

  public deleteBlocs(){
    this.user.blocs = this.user.blocs.filter(bloc => !this.selectedBloc.map(b=>b.id).includes(bloc.id));
  }

  public blockRowSelected(bloc: any){
    this.selectedBloc.push(bloc);
    this.selectedBloc = _.uniqBy(this.selectedBloc,'id');
  }

  public updateBloc(bloc: any){
    let help = Object.assign({}, bloc);
    const modal = this.modalService.open(BlocAccessModalComponent, {size: 'lg'});
    modal.componentInstance.bloc = help;
    modal.componentInstance.modules = help.modules;
    delete help.modules;
    modal.result.then(result =>{
      if(result.modules && result.modules.length){
        if(!this.user.blocs) this.user.blocs = new Array<any>();
        let bloc = this.user.blocs.filter(bloc => bloc.id == result.id)[0];
        if(bloc) bloc.modules = result.modules;
        console.log(this.user.blocs);
      }
    })
    .catch( error => console.log(error))
  }

  public toggleBlocSelection(){
    if(this.selectAllBloc){
      this.selectedBloc = this.user.blocs;
      this.blocCheckBox = this.user.blocs.map(b => true);
    }
    else{
      this.selectedBloc = [];
      this.blocCheckBox = this.user.blocs.map(b => false);
    }
  }

  public siteSelected(event: any){
    this.user.sites = this.sites
      .filter(site => event.map(e => e.rowId).includes(site.id));
    console.log(this.user.sites);
  }


  public deleteSite(s: Site){
    if(this.user.sites != undefined && this.user.sites.length){
      let index = this.user.sites.map(site => site.id).indexOf(s.id);
      this.user.sites.splice(index,1);
      // add to sites list
      this.sites.push(s);
    }
  }

  constructSiteColumnList(){
    this.siteColumnList = [];
    for(let header of this.siteHeaders){
      let col : any = {};
      col.dataKey = header.nom;
      col.name = header.label;
      col.excluded = true;
      this.siteColumnList.push(col);
    }
  }

}
