import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from 'environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable()
export class ParametresService {

  constructor(private http: HttpClient) { }

  /**
* Handle Http operation that failed.
* Let the app continue.
* @param operation - name of the operation that failed
* @param result - optional value to return as the observable result
*/
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead



      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  public getParametresApplication(): any {
    return {
      "accessOrga": [
        { label: 'Oui', value: true },
        { label: 'Non', value: false }
      ],
      "blocs": [
        {
          id: "appro",
          label: "Appro",
          modules: [
            { id: "reception", label: "Réceptions" },
            { id: "planningReception", label: "Planning" },
            { id: "tiers", label: "Tiers" },
            { id: "gisement", label: "Gisements" },
            { id: "planAppro", label: "Plan d\'appro" }
          ]
        },
        {
          id: "intrant",
          label: "Intrants",
          modules: [
            { id: "gisement", label: "Gisements" },
            { id: "tiers", label: "Tiers" },
            { id: "analyseIntrant", label: "Analyses d\'intrants" },
            { id: "stockIntrant", label: "Stock d\'intrants" },
            { id: "contratClient", label: "Contrats client" },
            { id: "contratFournisseur", label: "Contrats fournisseur" },
            { id: 'badges', label: 'Badges' }
          ]
        },
        {
          "id": "ration2",
          "label": "Rations 2",
          "modules": [
            {
              "id": "incorporations",
              "label": "Ration",
              "children": [
                {
                  "id": "rationincorporation",
                  "label": "Incorporations",
                },
                {
                  "id": "rationbilan",
                  "label": "Bilan",
                }
              ]
            },
            {
              "id": "consignes",
              "label": "Consignes",
            },
            {
              "id": "stockIntrant",
              "label": "Stock d'intrants",
              "children": [
                {
                  "id": "rationliststock",
                  "label": "Liste des stocks",
                },
                {
                  "id": "stocketat",
                  "label": "Etat des stocks",
                }
              ]
            },
            {
              "id": "ouvragesIncorporation",
              "label": "Ouvrages d'incorporation",
            },
            {
              "id": "ouvragesDigestion",
              "label": "Ouvrages de digestion",
            }
          ]
        },
        {
          id: "ration",
          label: "Rations",
          modules: [
            { id: "rationHebdo", label: "Incorporations" },
            { id: "consigneHebdo", label: "Consignes" },
            { id: "stockIntrant", label: "Stock d\'intrants" }
          ]
        },
        {
          id: "digestat",
          label: "Digestats",
          modules: [
            { id: "stockDigestat", label: "Stock de digestat" },
            { id: "analyseDigestat", label: "Analyses de digestat" },
            { id: "expedition", label: "Expéditions" },
            { id: "tiers", label: "Tiers" },
            { id: 'badges', label: 'Badges' }
          ]
        },
        {
          id: "epandage",
          label: "Epandages",
          modules: [
            { id: "expedition", label: "Expéditions" },
            {
              id: "planExport",
              label: "Plan d'export",
              children: [
                { id: "digestatliquide", label: "Digestat liquide" },
                { id: "digestatsolide", label: "Digestat solide" },
                { id: "digestatbrut", label: "Digestat brut" },
                { id: "digestatseche", label: "Digestat séché" },
                { id: "autreproduit", label: "Autre produit" }
              ]
            },
            { id: "parcelle", label: "Parcelles" },
            { id: "baseEchange", label: "Base d'échange" },
            { id: "tiers", label: "Tiers" }
          ]
        },
        {
          id: "facturation",
          label: "Facturation",
          modules: [
            { id: "factureClient", label: "Factures client" },
            { id: "factureFournisseur", label: "Factures fournisseur" },
            { id: "contratClient", label: "Contrats client" },
            { id: "contratFournisseur", label: "Contrats fournisseur" },
            { id: "contratEchange", label: "Contrats d\'échange" },
            { id: "tiers", label: "Tiers" }
          ]
        },
        {
          id: "suivi",
          label: "Suivi d'exploitation",
          modules: [
            { id: "saisie", label: "Saisies manuelles" }
          ]
        },
        {
          id: "rondesExploit",
          label: "Rondes",
          modules: [
            { id: "saisies", label: "Saisies manuelles" },
            { id: "rondes", label: "Rondes" },
            { id: "variables", label: "Variables" },
            { id: "tableaux", label: "Tableaux" }
          ]
        },
        {
          id: "journal",
          label: "Journal",
          modules: [
            { id: "calendrier", label: "Calendrier" },
            { id: "evenements", label: "Evénements" },
            { id: "intervenants", label: "Intervenants" },
            { id: "taches", label: "Tâches" },
            { id: "ticketMaintenance", label: "Tickets de maintenance" },
            { id: "ficheIntervention", label: "Fiches d'intervention" },
            { id: "indispos", label: "Indispos" },
          ]
        },
        {
          id: "materiel",
          label: "Materiel",
          modules: [
            { id: "equipements", label: "Equipements" },
            { id: "stockPieces", label: "Stock des pièces" },
            { id: "pieces", label: "Pièces" },
            { id: "consommables", label: "Consommables" },
            { id: "contratMaintenance", label: "Contrats de maintenance" },
          ]
        },
        {
          id: "performances",
          label: "Performances",
          modules: [
            { id: "qantum", label: "Qantum" }
          ]
        }
      ],
      "pays": [
        "France",
        "Belgique"
      ],
      "rolesPorteur": [
        "Transporteur",
        "Négociant",
        "Receveur de digestat",
        "Producteur de biomasse ou de déchets"
      ],
      "devise": [
        "Euro (€)"
      ],
      "typeOperation": [
        "Biométhanisation - R3b",
        "Compostage - R3c",
        "Épandage en agriculture - R10",
        "Stockage hors-site avant valorisation - R13"
      ],
      "timeZones": [
        "Europe/Paris"
      ],
      "siteAttachmentTypes": [
        "LOGO",
        "SIGNATURE"
      ]
    }
  }

}
