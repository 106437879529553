import { Injectable } from '@angular/core';
import { CaracteristiquePhysicoChimique } from 'administration/models/caracteristique-physico-chimique';

@Injectable()
export class CpcService {


  constructor() { }

  public transformToJson(cpcArray : CaracteristiquePhysicoChimique[]){
  	let cpcObj : any = {};
  	for(let cpc of cpcArray){
  		cpcObj[cpc.nom] = cpc;
  	}
  	return cpcObj;
  }

}
