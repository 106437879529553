import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class CaracteristiquePhysicoChimique{
	@Expose()
	nom : string;

	@Expose()
	label : string;

	@Expose()
	unit : string;

	@Expose()
	valeur : number;

	@Expose()
	obligatoire : boolean;

	constructor(_nom = "", _label="", _unit="", _valeur=null, _obligatoire=false){
		this.nom = _nom;
		this.label = _label;
		this.unit = _unit;
		this.valeur = _valeur;
		this.obligatoire = _obligatoire;
	}

	
}