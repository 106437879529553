import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { LoginService } from 'app/services/login/login.service';

@Injectable()
export class XhrinterceptorService implements HttpInterceptor{

  constructor(private loginService: LoginService){

  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let xhr;
    const userLogged = this.loginService.getLoggedUser();
    const token = sessionStorage.getItem('token');
    if(token != undefined){
      xhr = req.clone({
        headers: req.headers
          .set('X-Requested-With', 'XMLHttpRequest')
          .set('Access-Control-Allow-Origin', '*')
          .set('Authorization', `Bearer ${token}`)
      });
    }
    else{
      xhr = req.clone({
        headers: req.headers
          .set('X-Requested-With', 'XMLHttpRequest')
          .set('Access-Control-Allow-Origin', '*')
      });
    }
    return next.handle(xhr);
  }
}
