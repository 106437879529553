import { Entreprise } from './entreprise';
import { Contact } from './contact';
import { Banque } from './banque';

export class PorteurProjet extends Entreprise{
	roleAdditionnels: string[] = new Array<string>();
	domiciliationBancaire: string;
	libelleBanque: string;
	titulaire: string;
	codeBic: string;
	iban: string;
	logo: string;
	devise: string;
	codeRib: string;
	numeroAgrementsanitaire: string;
	contactFacturation: Contact = new Contact();
}
