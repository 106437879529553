import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConnexionUtilisateur } from 'administration/models/connexion-utilisateur';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Access-Control-Allow-Origin':'*'
  })
};

@Injectable()
export class ConnexionUtilisateurService {

  private _headers: any[];

  constructor(private http : HttpClient,private router : Router) {
  	this._headers = [
      {nom:'dateConnexionString', label: 'Date de connexion'},
      {nom: 'utilisateur.nom', label: 'Nom'},
      {nom: 'utilisateur.prenom', label: 'Prénom'},
      {nom :'utilisateur.email', label: 'Email'}
    ]
  }

  get headers(){
    return this._headers;
  }

  /**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
   private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if(error.status == 401 && error.statusText == 'OK'){
        this.router.navigate(['signin']);
      }
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
   
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /**
  * List all Utilisateurs
  */
  public list(): Observable<ConnexionUtilisateur[]>{
    return this.http.get<ConnexionUtilisateur[]>(environment.api+'/connexionUtilisateur/list')
      .pipe(
        catchError(this.handleError<ConnexionUtilisateur[]>('connexion-utilisateur-list'))
      )
  }

}
