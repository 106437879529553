import { Directive, ElementRef } from '@angular/core';
@Directive({
	selector: '[toggle-mobile]'
})
export class MobileTogglerDirective{

	constructor(
		private el: ElementRef
	){

	}

	get element(){
		return this.el;
	}
}