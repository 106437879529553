import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'global/services/breadcrumb/breadcrumb.service';
import { IndexFacturationService } from 'administration/services/index-facturation/index-facturation.service';
import { IndexFacturationAnnuelle } from 'administration/models/index-facturation-annuelle';
import { CustomDatepickerI18n } from 'global/services/custom-date-picker-i18n/custom-date-picker-i18n.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-index-facturation',
  templateUrl: './index-facturation.component.html',
  styleUrls: ['./index-facturation.component.scss']
})
export class IndexFacturationComponent implements OnInit {

	headers: string[];
	legende: string[];
	currentYear: number;
	rows: any[];
	index: IndexFacturationAnnuelle;

  constructor(
  	private _breadcrumbService: BreadcrumbService,
  	private _router: Router,
  	private _service: IndexFacturationService,
  	private _dateService: CustomDatepickerI18n
	) { }

  ngOnInit() {
  	this._breadcrumbService.add({label : "Indexs", route : this._router.routerState.snapshot.url});
    this.headers = ["Jan.","Fev.","Mars","Avr.","Mai","Juin","Jui.","Août","Sep.","Oct.","Nov.","Déc"];
    this.legende = ["Index"];
    let date = new Date();
    this.currentYear = date.getFullYear();
    this.fetchData(this.currentYear);
  }

  public viewIndex(){
  	this.fetchData(this.currentYear);
  }

  public dateChanged(date){
    if(date.year) date = this._dateService.dateFromNgbDate(date);
    this.currentYear =  date.getFullYear();
  }

  public goToPreviousYear(){
    let date = new Date();
    date.setFullYear(this.currentYear-1);
    this.fetchData(this.currentYear-1);
    this.dateChanged(date);
	}

  public goToNextYear(){
    let date = new Date();
    date.setFullYear(this.currentYear+1);
    this.fetchData(this.currentYear+1);
    this.dateChanged(date);
  }

  public rowChanged(event: any){
  	console.log(event);
  }

  public rowClickedFunction(event: any){
  	console.log(event);
  }

  public save(){
  	_.merge(this.index,this.constructIndex(this.rows,this.currentYear));
  	console.log(this.index);
  	if(this.index.revision){
  		this._service.update(this.index)
  			.subscribe((index: IndexFacturationAnnuelle) =>{
  				this.index = index;
  				console.log(this.index);
  			})
  	}else{
  		this._service.add(this.index)
  			.subscribe((index: IndexFacturationAnnuelle)=>{
  				this.index = index;
  				console.log(this.index);
  			})
  	}
  }

  private fetchData(year: number){
  	this._service.get(year)
  		.subscribe((index: IndexFacturationAnnuelle) =>{
  			if(index !=undefined && index != null){
  				this.rows = this.constructRows(index);
  				this.index = index;
  			}
  			else{
  				this.index = new IndexFacturationAnnuelle(this.currentYear);
  				this.rows = this.constructRows(this.index);
  			}
  		})
  }

  private constructRows(index: IndexFacturationAnnuelle): any[]{
  	let rows = [];
  	index.janvier.forEach(fi =>{
  		let row = [
  			{class: 'mesuree', value : fi.valeur},
  			{class: 'mesuree', value : this.getIndexValue(index,'fevrier',fi.nom)},
  			{class: 'mesuree', value : this.getIndexValue(index,'mars',fi.nom)},
  			{class: 'mesuree', value : this.getIndexValue(index,'avril',fi.nom)},
  			{class: 'mesuree', value : this.getIndexValue(index,'mai',fi.nom)},
  			{class: 'mesuree', value : this.getIndexValue(index,'juin',fi.nom)},
  			{class: 'mesuree', value : this.getIndexValue(index,'juillet',fi.nom)},
  			{class: 'mesuree', value : this.getIndexValue(index,'aout',fi.nom)},
  			{class: 'mesuree', value : this.getIndexValue(index,'septembre',fi.nom)},
  			{class: 'mesuree', value : this.getIndexValue(index,'octobre',fi.nom)},
  			{class: 'mesuree', value : this.getIndexValue(index,'novembre',fi.nom)},
  			{class: 'mesuree', value : this.getIndexValue(index,'decembre',fi.nom)}
			];
  		rows.push({
  			source:{nom: fi.nom},
  			firstRow: row
  		})
  	})
  	return rows;
  }

  private getIndexValue(index: IndexFacturationAnnuelle,key:string,nom: string): number{
  	let help = index[key].find(fi =>fi.nom == nom);
  	return help ? help.valeur : 0.0;
  }

  private constructIndex(rows: any[],year: number): IndexFacturationAnnuelle{
  	let index = new IndexFacturationAnnuelle(year);
  	index.janvier = this.getMonthIndexs(0,rows);
  	index.fevrier = this.getMonthIndexs(1,rows);
  	index.mars = this.getMonthIndexs(2,rows);
  	index.avril = this.getMonthIndexs(3,rows);
  	index.mai = this.getMonthIndexs(4,rows);
  	index.juin = this.getMonthIndexs(5,rows);
  	index.juillet = this.getMonthIndexs(6,rows);
  	index.aout = this.getMonthIndexs(7,rows);
  	index.septembre = this.getMonthIndexs(8,rows);
  	index.octobre = this.getMonthIndexs(9,rows);
  	index.novembre = this.getMonthIndexs(10,rows);
  	index.decembre = this.getMonthIndexs(11,rows);
  	return index;
  }

  private getMonthIndexs(month: number = 0, rows: any[]){
  	return rows.map(row => {
  		if(row.firstRow[month].value) return {nom:row.source.nom, valeur:row.firstRow[month].value}
  		else return {nom:row.source.nom, valeur: 0.0}
  	})
  }

}
