import { Entreprise } from './entreprise';
import { PorteurProjet } from './porteur-projet';
import { Adresse } from './adresse';
import { BiogasviewDocument } from 'global/models/BiogasviewDocument';
import { Organisation } from './organisation';
import { Attachment } from './attachment';
export class Site extends BiogasviewDocument{
	nom: string;
	codeSite: string = 'FA';
	codeAvoir: string = 'AV';
	fuseauHoraire: string;
	database: string;
	dateCreation: any;
	dateRaccordement: any;
	dateMonitoring: any;
	typeOperation: string;
	organisation : Organisation = new Organisation();
	installateur: Entreprise = new Entreprise();
	proprietaire: Entreprise = new Entreprise();
	negociant: Entreprise = new Entreprise();
	fournisseurValorisation: Entreprise = new Entreprise();
	porteurProjet: PorteurProjet = new PorteurProjet();
	societeNegoce: Entreprise = new Entreprise();
	adresse: Adresse = new Adresse();
	biogasviewAttachments: Attachment[] = new Array<Attachment>();
}