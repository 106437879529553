import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { v4 } from 'uuid';

import { Organisation } from 'administration/models/organisation';

import { BreadcrumbService } from 'global/services/breadcrumb/breadcrumb.service';
import { OrganisationService } from 'administration/services/organisation/organisation.service';
import { LoginService } from 'app/services/login/login.service';

@Component({
  selector: 'app-organisation-add',
  templateUrl: './organisation-add.component.html',
  styleUrls: ['./organisation-add.component.scss']
})
export class OrganisationAddComponent implements OnInit {

	organisation = new Organisation();
  nomOrgaExists: boolean = false;
  invalidSubmitted: boolean = false;
  loading: boolean = false;
  loadingMessage: string = '';

  constructor(
    private organisationService : OrganisationService, 
    private router : Router,
    private loginService : LoginService,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit() {
    this.breadcrumbService.add({label : "Nouvelle organisation", route : this.router.routerState.snapshot.url});
  }

  siteAdd(){
    this.setOrganisationParameters();
    this.organisationService.add(this.organisation)
    .subscribe(res=>{
      if(res != undefined && res.id ) this.router.navigate(['administration/organisation',res.id,'site','add'])
    })
    
  }

  userAdd(){
    this.setOrganisationParameters();
    this.organisationService.add(this.organisation)
    .subscribe(res=>{
      if(res != undefined && res.id ) this.router.navigate(['administration/organisation',res.id,'utilisateur','add', {title: this.organisation.nom}])
    })
  }

  private setOrganisationParameters(){
    this.organisation.createdOn = Date.now();
    this.organisation.createdBy = this.loginService.getLoggedUser().principal.username;
    this.organisation.modifiedBy = this.organisation.createdBy;
    this.organisation.modifiedOn = Date.now();
  }

  submit(formValid : boolean){
    formValid? this.organisationAdd() : this.invalidSubmitted = true;
  }

  organisationAdd(){
    this.loading = true;
    this.loadingMessage = "Ajout de l'organisation: "+this.organisation.nom;
    this.setOrganisationParameters();
    this.organisationService.add(this.organisation)
    .subscribe(res=>{
      if(res != undefined && res.id ) {
        this.loading = false;
        this.breadcrumbService.removeFrom(-2);
        this.router.navigate(['administration/organisation/list']);
      }
    })
  }

  onBlur(){
    this.organisationService.checkIfExists(this.organisation.nom)
      .subscribe(res => {
        if(res != null && res != undefined) this.nomOrgaExists = true;
      });
  }

}
