import { Site } from './site';
import { UtilisateurOrganisation } from './utilisateur-organisation';
import { BiogasviewDocument } from 'global/models/BiogasviewDocument';

export class Organisation extends BiogasviewDocument{
	public database: string;
	public nom : string;
	public sites : Site[];
	public utilisateurs : UtilisateurOrganisation[];
	constructor(){
		super();
		this.dataType = 'organisation';
	}
	
}
