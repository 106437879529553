import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';

import { Organisation } from 'administration/models/organisation';
import { Site } from 'administration/models/site';
import { UtilisateurOrganisation } from 'administration/models/utilisateur-organisation';
import { BreadcrumbService } from 'global/services/breadcrumb/breadcrumb.service';

import { CustomDatepickerI18n } from 'global/services/custom-date-picker-i18n/custom-date-picker-i18n.service';
import { OrganisationService } from 'administration/services/organisation/organisation.service';
import { ConfirmationGuardService } from 'global/services/confirmation-guard/confirmation-guard.service';

import { LoginService } from 'app/services/login/login.service';
import { SiteService } from 'administration/services/site/site.service';
import { UserService } from 'administration/services/user/user.service';

@Component({
  selector: 'app-organisation-update',
  templateUrl: './organisation-update.component.html',
  styleUrls: ['./organisation-update.component.scss']
})
export class OrganisationUpdateComponent implements OnInit {

	private organisation = new Organisation;
  private oldOrg = new Organisation;
  siteColumnList : any = [];
  siteHeaders : any[] = new Array<any>();
  userColumnList : any = [];
  userHeaders : any[] = new Array<any>();
  nomOrgaExists: boolean = false;
  invalidSubmitted: boolean = false;
  loading: boolean = false;
  loadingMessage: string = '';

  constructor(
    private datePipe: DatePipe,
    private router : Router,
    private route : ActivatedRoute, 
    private organisationService : OrganisationService,
    private loginService : LoginService,
    private siteService : SiteService,
    private confirmationGuardService: ConfirmationGuardService,
    private userService : UserService,
    private breadcrumbService: BreadcrumbService,
    private customDatePickerService: CustomDatepickerI18n
  ) { }

  ngOnInit() {
  	if(this.route.snapshot.paramMap.keys.indexOf('idOrga')>=0){
      let id: string = this.route.snapshot.paramMap.get('idOrga');
      this.organisationService.get(id).subscribe(organisation =>{
        this.organisation = organisation;
        this.oldOrg.nom = organisation.nom;
        this.organisation.sites = this.organisation.sites.map(site =>{
          site.dateMonitoring = this.datePipe.transform(new Date(site.dateMonitoring),'dd/MM/yyyy');
          site.dateRaccordement = this.datePipe.transform(new Date(site.dateRaccordement), 'dd/MM/yyyy');
          return site;
        })
        this.organisation.sites = _.sortBy(this.organisation.sites, ['nom']);
        this.breadcrumbService.add({label : organisation.nom, route : this.router.routerState.snapshot.url});
      })
    }
    this.siteHeaders = this.siteService.headers;
    this.constructSiteColumnList();
    this.userHeaders = this.userService.headers;
    this.constructUserColumnList();
  }

  nomChanged(){
    if(this.oldOrg.nom != this.organisation.nom)
      this.organisationService.checkIfExists(this.organisation.nom)
        .subscribe(res => {
          if(res != null && res != undefined) this.nomOrgaExists = true;
        });
  }

  submit(formValid : boolean){
    formValid? this.organisationUpdate() : this.invalidSubmitted = true;
  }

  organisationUpdate(){
    this.loading = true;
    this.loadingMessage = "Mise à jour de l'organisation: "+this.organisation.nom;
    this.setOrganisationParameters();
    this.organisation.sites = this.organisation.sites.map(site =>{
      site.dateCreation = this.customDatePickerService.getModelFromDateString(site.dateCreation);
      site.dateMonitoring = this.customDatePickerService.getModelFromDateString(site.dateMonitoring);
      site.dateRaccordement = this.customDatePickerService.getModelFromDateString(site.dateRaccordement);
      return site;
    })
    this.organisationService.update(this.organisation)
    .subscribe(res=>{
      if(res != undefined && res.id ) {
        this.breadcrumbService.removeFrom(-2);
        this.router.navigate(['administration/organisation/list']);
      }
    })
  }


  updateSite(id : string){
    this.breadcrumbService.add({label : 'Site', route : this.router.routerState.snapshot.url});
    if(this.oldOrg.nom === this.organisation.nom){
      this.router.navigate(['administration/organisation',this.organisation.id,'site','update',id]);
    } else {
      this.setOrganisationParameters();
      this.organisationService.update(this.organisation)
      .subscribe(orga =>{
        if(orga && orga.id) this.router.navigate(['administration/organisation',this.organisation.id,'site','update',id]);
      })
    }
  }

  updateUser(id : string){
    this.setOrganisationParameters();
    this.breadcrumbService.add({label : 'Utilisateur', route : this.router.routerState.snapshot.url});
    if(this.oldOrg.nom === this.organisation.nom){
      this.router.navigate(['administration/organisation',this.organisation.id,'user','update',id]);
    } else {
      this.organisationService.update(this.organisation)
      .subscribe(orga =>{
        if(orga && orga.id) this.router.navigate(['/administration/organisation',this.organisation.id,'user','update',id]);
      })
    }
  }

  siteAdd(){
    this.setOrganisationParameters();
    if(this.oldOrg.nom === this.organisation.nom){
      this.router.navigate(['administration/organisation',this.organisation.id,'site','add']);
    } else {
      this.organisationService.update(this.organisation)
      .subscribe(orga =>{
        if(orga && orga.id) this.router.navigate(['administration/organisation',this.organisation.id,'site','add']);
      })
    }
  }

  userAdd(){
    this.setOrganisationParameters();
    if(this.oldOrg.nom === this.organisation.nom){
      this.router.navigate(['administration/organisation',this.organisation.id,'user','add']);
    } else {
      this.organisationService.update(this.organisation)
      .subscribe(orga =>{
        if(orga && orga.id) this.router.navigate(['administration/organisation',this.organisation.id,'user','add']);
      })
    }
  }

  siteRemove(site : Site){
    this.confirmationGuardService.confirm('Voulez vous vraiment supprimer le site '+site.nom+' ?')
      .then(result =>{
        this.siteService.delete(site.id)
          .subscribe(res=>{
            if(res && res.id) this.organisation.sites = this.organisation.sites.filter(s => s.id !== site.id);
          })
      })
    /// remove from server
  }

  userRemove(user : UtilisateurOrganisation){
    this.confirmationGuardService.confirm('Voulez vous vraiment supprimer l\'user '+user.prenom+' '+user.nom.toUpperCase()+'?')
      .then(result =>{
        this.userService.delete(user.id)
          .subscribe(res=>{
            if(res && res.id) this.organisation.utilisateurs = this.organisation.utilisateurs.filter(u => u.id !== user.id);
          })
      })
      .catch( error => console.log(error));
    // remove from server
  }

  private setOrganisationParameters(){
    this.organisation.modifiedBy = this.loginService.getLoggedUser().principal.username;
    this.organisation.modifiedOn = Date.now();
  }

  getSiteCardValue(site: Site, key : string){
    return _.get(site,key);
  }

  getUserCardValue(user: UtilisateurOrganisation, key : string){
    return _.get(user,key);
  }

  constructSiteColumnList(){
    this.siteColumnList = [];
    for(let header of this.siteHeaders){
      let col : any = {};
      col.dataKey = header.nom;
      col.name = header.label;
      col.excluded = true;
      this.siteColumnList.push(col);
    }
    console.log(this.siteColumnList);
    console.log(this.siteService)
  }

  constructUserColumnList(){
    this.userColumnList = [];
    for(let header of this.userHeaders){
      let col : any = {};
      col.dataKey = header.nom;
      col.name = header.label;
      col.excluded = true;
      this.userColumnList.push(col);
    }
  }


}
