import { Component, OnInit, EventEmitter, Input, Output, ViewChild, ViewEncapsulation, ElementRef, AfterContentChecked, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { NgbModule, NgbModal, NgbActiveModal, NgbTypeahead, NgbInputDatepicker, NgbTimepicker } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'global/services/breadcrumb/breadcrumb.service';
import { DatePickerAdapter } from 'global/services/date-picker-adapter/date-picker-adapter.service';
import { Observable, Subject } from 'rxjs';
import { TimepickerAdapter } from 'global/services/timepicker-adapter/timepicker-adapter.service';
import { map, merge, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'custom-input',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss']
})
export class CustomInputComponent implements OnInit{

  @Input('prop-name') propName: string = 'nom';
	@Input() type : string;
	@Input() placeholder : string;
  @Input() label : string;
	@Input() role : string;
  @Input() id : string;
  @Input() lastDatepicker : boolean;
  @Input() list : any;
  @Input() listButtonAddValue : any;
  @Input() listButtonCancelValue : boolean = false;
  @Input() required : boolean;
  @Input() invalid : boolean;
  @Input() disabled : boolean;
  @Input() valueExists : boolean;
  @Input() maxlength : number;
  @Input() minlength : number;
  @Input() min : number;
  @Input() max : number;
  @Input() rows : number;
  @Input() unit : string;
  @Input() value : any ;
	@Output() valueChange = new EventEmitter();
  @Output() navigateToAnotherForm = new EventEmitter();
  @Output() pipedBlur = new EventEmitter();

  @ViewChild('date') instanceDate : NgbInputDatepicker;
  @ViewChild('instanceTypeAhead') instanceTypeAhead : NgbTypeahead;
  focusTypeAhead$ = new Subject<string>();
  clickTypeAhead$ = new Subject<string>();

  private complexeData : boolean = false;
  private toggled = false;
  private pattern : string;
  private test : any;
  private composedData : boolean = false;
  private selectedOptions : boolean[] = [];
  private selectedOptionsName : string[] = [];
  private oldValue;
  private timeValue;

  private classes = {
    toggled: this.toggled
  }

  constructor(private ref : ChangeDetectorRef,
      private router : Router,
      private breadcrumbService : BreadcrumbService) { }

  ngOnInit() {
    if(this.role == "date-time"){
      this.timeValue = new Date(this.value);
    }
    if(this.role == 'list' && !this.list) this.list = [];
    if(this.list && this.list.length && (this.list[0]._id || this.list[0].id)) this.complexeData = true;
    if(this.list && this.list.length && Array.isArray(this.list[0])) this.composedData = true;
    if(this.role == 'email') this.pattern = '^[^\W][a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*\@[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*\.[a-zA-Z]{2,4}$';
    if(this.role == 'phone') this.pattern = '^0[1-9]([-. ]?[0-9]{2}){4}$';
    if(this.role == 'number' && this.min >= 0 || this.role == 'cpc') this.pattern = '^[0-9]';
    if((this.role == 'list-multiple' || this.role == 'checkbox-multiple') && this.list && this.selectedOptions && this.selectedOptions.length == 0) {
      this.initalizeSelectedValues();
    }
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.list && changes.list.currentValue && changes.list.currentValue.length){
        if(changes.list.currentValue[0]._id || changes.list.currentValue[0].id) this.complexeData = true;
        if(this.list && this.list.length && Array.isArray(this.list[0])) this.composedData = true;
        if((this.role == 'list-multiple' || this.role == 'checkbox-multiple') && this.selectedOptions && this.selectedOptions.length == 0){
            this.initalizeSelectedValues();
        }
    }
    if(this.role == "date-time"){
        this.timeValue = new Date(this.value);
    }
    if(changes.value && changes.value.currentValue){
        this.oldValue = _.cloneDeep(this.value);
    }
    // console.log(this.id, this.value);
  }

  ngDoCheck(){
      if(!_.isEqual(this.oldValue, this.value)){
          if(this.role == "date-time"){
              this.timeValue = new Date(this.value);
          }
          this.oldValue = _.cloneDeep(this.value);
          if(this.list && this.list.length && (this.list[0]._id || this.list[0].id)) this.complexeData = true;
          if(this.list && this.list.length && Array.isArray(this.list[0])) this.composedData = true;
          if(this.role == 'email') this.pattern = '^[^\W][a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*\@[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*\.[a-zA-Z]{2,4}$';
          if(this.role == 'phone') this.pattern = '^0[1-9]([-. ]?[0-9]{2}){4}$';
          if((this.role == 'list-multiple' || this.role == 'checkbox-multiple') && this.list && this.selectedOptions && this.selectedOptions.length == 0) {
            this.initalizeSelectedValues();
          }
      }
  }

  ngAfterContentChecked(){
    this.ref.detectChanges();
  }

  compareFn(complexeObject1 : any, complexeObject2 : any){
      if(complexeObject1 && complexeObject2 && complexeObject1[this.propName] && complexeObject1.id == complexeObject2.id && complexeObject2[this.propName]){
          return complexeObject1[this.propName] == complexeObject2[this.propName];
      } else {
          return complexeObject1 && complexeObject2 ? complexeObject1.id == complexeObject2.id : complexeObject1 == complexeObject2;

      }
  }

  compareFnComposed(composedObject1 : any, composedObject2 : any){
      return composedObject1 && composedObject2 ? composedObject1[0] == composedObject2[0] : composedObject1 == composedObject2;
  }

    /**
    * for checkboxes.
    * basically comparison between the initial value and the list of all the possible values
    */
  initalizeSelectedValues(){
    this.selectedOptions = [];
    this.selectedOptionsName = [];
    this.list.forEach(option => {
      if(this.value.length){
        if(_.findIndex(this.value,option) >= 0 || this.value.includes(option)){
          this.selectedOptions.push(true);
          this.complexeData? this.selectedOptionsName.push(option[this.propName]) : this.selectedOptionsName.push(option);
        } else {
          this.selectedOptions.push(false);
        }
      }
    })
  }

  change(newValue : any){
    if(newValue == 'add'){
        this.navigateToAnotherForm.emit(this.listButtonAddValue);
    }
    else {
        if(this.composedData && Array.isArray(newValue)) this.valueChange.emit(newValue[0]);
        else this.valueChange.emit(newValue);
    }
  }

  changeTime(newValue : any){
      if(newValue){
          this.value.setHours(newValue.getHours());
          this.value.setMinutes(newValue.getMinutes());
          this.valueChange.emit(this.value);
      }
      //
  }

  /**
  * changeOptions - listening for changes in the checkboxes and emitting the new value
  */
  changeOptions(event : any){
    this.value = [];
    this.selectedOptionsName = [];
    for(let i = 0; i < this.selectedOptions.length; i++){
      if(this.selectedOptions[i]){
        this.value.push(this.list[i])
        this.complexeData? this.selectedOptionsName.push(this.list[i][this.propName]) : this.selectedOptionsName.push(this.list[i])
      }
    }
    this.valueChange.emit(this.value);
  }

  validateInput(event : any){
      let char = event.which || event.keyCode;
      char = String.fromCharCode(char);
      if(this.min == 0 && this.type == 'number' && !/[0-9.,]/.test(char)){
          event.preventDefault();
      }
  }


  /**
  *
  * Function to handle the typeahead instance
  */
  searchTypeAhead = (text$: Observable<string>) =>
      text$.pipe(
          debounceTime(100),
          distinctUntilChanged(),
          merge(this.focusTypeAhead$),
          merge(this.clickTypeAhead$.pipe(filter(() => !this.instanceTypeAhead.isPopupOpen()))),
          map(term => (term === '' ? this.list : _.sortBy(this.list,
              c => {
                  c = String(c);
                  c = c.toLowerCase();
                  let score = 0;
                  let termArray = _.split(term.toLowerCase(),'');
                  termArray.forEach(termChar => {
                      c.indexOf(termChar) > -1? score +=1 : null;
                  })
                  return -score;
                })
          )));

  /**
  *
  * Template for the results displayed in the list for typeaheads
  */
  resultFormatterComposedData = ((item : any) =>{
      if(this.composedData && item[0] && item[1]) return item[0] + " - " + item[1];
      else if(this.composedData && item[0] && !item[1]) return item[0];
      else if(!this.composedData) return item;
  })

  inputFormatterComposedData = ((item : any) => {
      if(this.composedData && Array.isArray(item)) return item[0];
      else return item;
  })

  toggleSelect(event : any, source : string) : void {
    if(event.target.id == this.id && source == 'in'){
       this.toggled = !this.toggled;

    } else if(source == 'out' && event.target.id != this.id) {
      this.toggled = false;
    }
  }

  /**
  *Permet de gérer les click event pour fermer le popup calendrier lorsque l'on clique en dehors
  * @param event - $event depuis html
  * @param id - valeur de l'attribut id de l'élément visé
  */
  public handleClickDatepicker(event : any,id : string){
    let element = document.getElementById(id);
    let clickedInside = false;
    if(element){
      let el = event.target;
      while(el.parentElement){
        if(el == element) {
          clickedInside = true;
          event.target.focus();
          break;
        }
        el = el.parentElement;
      }
    }
    // console.log("-------------------------------------------");
    // console.log(element, clickedInside);
    // console.log(event.target);
    if(!clickedInside){
      if(this.instanceDate) this.instanceDate.close();
      event.stopPropagation();
    } else {
      if(this.lastDatepicker) event.stopPropagation();
    }

  }


}
