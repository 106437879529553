import { Component, OnInit, Input, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { Router, RoutesRecognized, ActivatedRoute }from '@angular/router';
import { Location } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';

import { LoginService } from 'app/services/login/login.service';
import { ConfirmationGuardService } from 'global/services/confirmation-guard/confirmation-guard.service';
import { BreadcrumbService } from 'global/services/breadcrumb/breadcrumb.service';
import { NavigationService } from 'global/services/navigation/navigation.service';
import { ParametresService } from 'global/services/parametres/parametres.service';
import * as _ from 'lodash';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit{

  @Input() topNav : string = "";
  @Input() subNavs : string[] = [];
  @Input() menu : boolean = false;
  private login = false;

  private _previousUrl: string;
  private sites = [];
  private organisation = null;
  private perimetre = null;
  private perimetreString = "";
  private menuOverflow = false;
  private visibleSubNavs = [];
  private hiddenSubNavs = [];
  private perimetreDisabled = false;
  private perimetreSubscription;
  private activeItems : boolean[];
  private activeItemsSubscription;
  private breadcrumbSubscription;
  private parametres;

  constructor(
    private _router : Router,
    private _route: ActivatedRoute,
    private _location : Location,
    private _confirmationGuard: ConfirmationGuardService,
    private _breadcrumbService : BreadcrumbService,
    private _navigationService : NavigationService,
    private _parametresService : ParametresService,
    private loginService: LoginService,
    private ref : ChangeDetectorRef
  ) {
    
  }

  ngOnInit() {
    this._router.events
    // .subscribe(e => console.log(e))
    .pipe(
        filter(event => event instanceof RoutesRecognized),
        pairwise()
    )
    .subscribe((e: any ) => {
        this._previousUrl = e.urlAfterRedirects;
    });

    this._route.queryParams.subscribe(params=>{
      if(params.origin && params.origin == 'login') {
        this.login = true;
      }
    })
    this.parametres = this._parametresService.getParametresApplication();
    this.visibleSubNavs = _.cloneDeep(this.subNavs);
    this.hiddenSubNavs = [];
    this.handleNavOverflow();
    this._navigationService.subNavs = this.subNavs;
    this.activeItemsSubscription = this._navigationService.activeItems$.subscribe( activeItems => {
      this.activeItems = activeItems;
    })
    this.breadcrumbSubscription = this._breadcrumbService.breadcrumb.subscribe( _breadcrumb => {
      let subNavsRoutes = this.subNavs.map(array => array[1]);
      let breadcrumbRoutes = _breadcrumb.map(breadcrumbItem => breadcrumbItem.route);
      let activeItemIndex = -1;
      for(let i = 0; i < breadcrumbRoutes.length; i++){
        for(let j = 0; j < subNavsRoutes.length; j++){
          if(breadcrumbRoutes[i].includes(subNavsRoutes[j]) && !this.activeItems[j]) activeItemIndex = j;
        }
      }
      if(activeItemIndex >= 0){
        this._navigationService.setActive(activeItemIndex);
      }
    });
  }

  ngAfterViewInit(){
    this.checkNavOverflow()? this.handleNavOverflow() : null;
    this.ref.detectChanges();
  }

  ngDoCheck(){
    this.checkNavOverflow()? this.handleNavOverflow() : null;
    this.ref.detectChanges();
  }

  navigateBack(){
    console.log(this._location)
    console.log(this._previousUrl);
    if(this._previousUrl != undefined && !this._previousUrl.includes("/login"))
      this._location.back();
    else if(this._previousUrl != undefined && this._previousUrl.includes("/login")){
      this._confirmationGuard.confirm("Vous allez être déconnecté")
        .subscribe(res =>{
          if(res){
            
          }
        })
    }
  }

  setActiveItem(index : number){
    if(index >= this.visibleSubNavs.length){
      let hiddenIndex = index - this.visibleSubNavs.length;
      let tmp1 = this.hiddenSubNavs[hiddenIndex];
      let tmp2 = this.visibleSubNavs[this.visibleSubNavs.length - 1];
      this.visibleSubNavs[this.visibleSubNavs.length - 1] = tmp1;
      this.hiddenSubNavs[hiddenIndex] = tmp2;
      this._navigationService.setActive(this.visibleSubNavs.length -1);
    } else {
      this._navigationService.setActive(index);
    }
  }

  logout(){
  	this.loginService.logout()
  		.subscribe(res =>{
         this.loginService.resetLoggedUser();
         this._router.navigate(["/signin"]);
       })
  }

  navigateHome(){
  	this._breadcrumbService.reset();
    this._router.navigate(["administration/organisation/list"]);
  }

  navigate(route, index){
    if(!_.includes(this._location.path(),route)){
      this.setActiveItem(index);
      this._breadcrumbService.reset();
      this._router.navigate([this._route.snapshot.url[0].path+'/'+route]);
    }
  }

  handleNavOverflow(){
    let i = 0;
    if(this.checkNavOverflow()){
      let tmp = this.visibleSubNavs.pop();
      tmp? this.hiddenSubNavs = _.concat([tmp], this.hiddenSubNavs) : null;
    }
  }

  checkNavOverflow(){
    let el = document.getElementById("nav-section-menu");
    if(el){
      var curOverflow = el.style.overflow;
      if ( !curOverflow || curOverflow === "visible" )
        el.style.overflow = "hidden";
      var isOverflowing = el.clientWidth < el.scrollWidth - 1
        || el.clientHeight < el.scrollHeight - 1;
      el.style.overflow = curOverflow;
      this.menuOverflow = isOverflowing;
      return isOverflowing;
    }
  }

  ngOnDestroy(){
    this.activeItemsSubscription.unsubscribe();
    this.breadcrumbSubscription.unsubscribe();
  }
}
