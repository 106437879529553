import { BiogasviewUtilisateur } from './BiogasviewUtilisateur';
import { Organisation } from './organisation';

export class UtilisateurOrganisation extends BiogasviewUtilisateur {
	public organisation : Organisation;
	constructor(){
		super();
		this.organisation = new Organisation();
		this.dataType = 'utilisateurOrganisation'
	}
}