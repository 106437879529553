import { Injectable } from '@angular/core';
import { Router }from '@angular/router';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators';

import { UtilisateurOrganisation } from 'administration/models/utilisateur-organisation';

@Injectable()
export class UserService {
	private _headers: any[];
	private _moduleHeaders: any[];
  constructor(
  	private http: HttpClient,
  	private router : Router
	) { 
		this._headers = [
			{nom: 'nom', label: 'Nom'},
			{nom: 'prenom', label: 'Prenom'},
			{nom :'email', label: 'Email'}
		]

		this._moduleHeaders = [
			{nom: 'name', label: 'Bloc'},
			{nom: 'name', label: 'Module'},
			{nom: 'access', label: "Droit d'accès"}
		]
	}

	get headers(){
		return this._headers;
	}

	get moduleHeaders(){
		return this._moduleHeaders;
	}

	 /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
	private handleError<T> (operation = 'operation', result?: T) {
	  return (error: any): Observable<T> => {
	  	if(error.status == 401 && error.statusText == 'OK'){
	        this.router.navigate(['signin']);
	    }
	    // TODO: send the error to remote logging infrastructure
	    console.error(error); // log to console instead

	    

	    // Let the app keep running by returning an empty result.
	    return of(result as T);
	  };
	}

	/**
	* Delete Utilisateur
	* @param id - Utilisateur document id
	*/
	public delete(id: string): Observable<UtilisateurOrganisation>{
		return this.http.delete<UtilisateurOrganisation>(environment.api+'/utilisateurOrganisation/delete/'+id)
  		.pipe(
  			catchError(this.handleError<UtilisateurOrganisation>('utilisateur-delete'))
			)
	}


	/**
	* Get Utilisateur by id
	* @param id - Utilisateur document id
	*/
	public get(id: string): Observable<UtilisateurOrganisation>{
		return this.http.get<UtilisateurOrganisation>(environment.api+'/utilisateurOrganisation/find/'+id)
  		.pipe(
  			catchError(this.handleError<UtilisateurOrganisation>('utilisateur-get'))
			)
	}

	/**
	* Add UtilisateurOrganisation
	* @param Utilisateur - new Utilisateur to add
	*/
	public add(Utilisateur: UtilisateurOrganisation): Observable<UtilisateurOrganisation>{
		return this.http.post<UtilisateurOrganisation>(environment.api+'/utilisateurOrganisation/create',Utilisateur)
  		.pipe(
  			catchError(this.handleError<UtilisateurOrganisation>('utilisateur-create'))
			)
	}

	/**
	* Update Utilisateur
	* @param Utilisateur - Utilisateur to update
	*/
	public update(Utilisateur: UtilisateurOrganisation): Observable<UtilisateurOrganisation>{
		return this.http.put<UtilisateurOrganisation>(environment.api+'/utilisateurOrganisation/update',Utilisateur)
  		.pipe(
  			catchError(this.handleError<UtilisateurOrganisation>('utilisateur-update'))
			)
	}

	/**
	* List all Utilisateurs
	*/
	public list(): Observable<UtilisateurOrganisation[]>{
		return this.http.get<UtilisateurOrganisation[]>(environment.api+'/utilisateurOrganisation/list')
  		.pipe(
  			catchError(this.handleError<UtilisateurOrganisation[]>('utilisateur-list'))
			)
	}

	/**
	* Check by email if Utilisateur exists
	* @param name - site database name
	*/
	public checkIfExists(email: string): Observable<boolean>{
		return this.http.get<boolean>(environment.api+'/utilisateurOrganisation/byEmail?email='+email)
  		.pipe(
  			catchError(this.handleError<boolean>('utilisateur-exists'))
			)
	}


	/**
	* Change password of the user
	* @param user - user to update
	*/
	public changePassword(user : any): Observable<UtilisateurOrganisation>{
		return this.http.put<UtilisateurOrganisation>(environment.api+'/utilisateurOrganisation/changePassword',user)
		.pipe(
			catchError(this.handleError<UtilisateurOrganisation>('utilisateur-changePassword'))
			)
	}
}
