import {AnalyseAgronomique} from './analyse-agronomique';
import {AnalyseBiologique} from './analyse-biologique';
import {AnalyseBMP} from './analyse-bmp';
import {AnalyseCTO} from './analyse-cto';
import {AnalyseNutritionnelle} from './analyse-nutritionnelle';
import {AnalyseOligoEtm} from './analyse-oligo-etm';
import {AnalyseSanitaire} from './analyse-sanitaire';
import { Type, Exclude, Expose } from 'class-transformer';

@Exclude()
export class Analyse {
	@Expose()
	typeAnalyse : string;

	@Expose()
	siteMetha : string;

	@Expose()
	dateAnalyse :  any;

	@Expose()
	@Type(()=>AnalyseOligoEtm)
	analyseOligoEtm : AnalyseOligoEtm;

	@Expose()
	@Type(()=>AnalyseAgronomique)
	analyseAgronomique : AnalyseAgronomique;

	@Expose()
	@Type(()=>AnalyseBiologique)
	analyseBiologique : AnalyseBiologique;

	@Expose()
	@Type(()=>AnalyseCTO)
	analyseCTO : AnalyseCTO;

	@Expose()
	@Type(()=>AnalyseNutritionnelle)
	analyseNutritionnelle : AnalyseNutritionnelle;

	@Expose()
	@Type(()=>AnalyseBMP)
	analyseBMP : AnalyseBMP;

	@Expose()
	@Type(()=>AnalyseSanitaire)
	analyseSanitaire : AnalyseSanitaire;

	constructor(){
		this.typeAnalyse = "";
		this.siteMetha = "";
		this.dateAnalyse = null;
		this.analyseAgronomique = null;
		this.analyseOligoEtm = null;
		this.analyseBiologique = null;
		this.analyseCTO = null;
		this.analyseNutritionnelle = null;
		this.analyseBMP = null;
		this.analyseSanitaire = null;
	}
}

