import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TREE_ACTIONS, KEYS, IActionMapping, TreeComponent } from 'angular-tree-component';
import * as _ from 'lodash';

const actionMapping:IActionMapping = {
	mouse: {
		click: TREE_ACTIONS.TOGGLE_SELECTED
	}
}

@Component({
	selector: 'app-exporter',
	templateUrl: './exporter.component.html',
	styleUrls: ['./exporter.component.scss']
})
export class ExporterComponent implements OnInit{
	@Input('nodes') nodes1: any[];
	@ViewChild('tree1') private tree1: TreeComponent;
	@ViewChild('tree2') private tree2: TreeComponent;
	private selectedTree: any;
	private selectEvent: any
	fileType: string;
	options1: any;
	options2: any;
	nodes2: any[];
	
	private _selectedNodes: any[];
	private selectedNode2: any;
	private selectedNode1: any;
	constructor(public activeModal: NgbActiveModal) { 

		
	}

	ngOnInit(){
		this.fileType = 'csv';
		this.nodes2 = [];

		this.options1 = {
			allowDrag: (node) => node.isLeaf,
			allowDrop: false,
			actionMapping: {
				mouse: {
					click: (tree, node, $event) => {
						if(node.isLeaf){
							TREE_ACTIONS.TOGGLE_SELECTED(tree, node, $event)
							let children = _.find(this.nodes2, (n)=>{ return node.data.id == n.parent});
							if(children == undefined) this.selectedNode1 = node;
							else this.selectedNode1 = undefined;
						}
					}
				}
			}
		};

		this.options2 = {
			allowDrag: true,
			allowDrop: (element, {parent, index}) => parent.parent == null,
			actionMapping: {
				mouse: {
					click: (tree, node, $event) => {
						TREE_ACTIONS.TOGGLE_SELECTED(tree, node, $event)
						this.selectedNode2 = node;
						this.selectEvent = $event;
						this.selectedTree = tree;
					}
				}
			}
		};
	}

	get selectedNodes(): any{
		return this._selectedNodes;
	}

	deleteNode(node: any){
		if(node != undefined && node != null){
			_.remove(this.nodes2, (n) => {
				return node.id == n.id;
			})
			// child node
			if(node.data.parent) this.addNodeToList(this.nodes1,node.data);
			// root node
			else this.nodes1.push(node.data);
			// update trees
			this.tree1.treeModel.update();
			this.tree2.treeModel.update();
		}
	}

	addAllNodes(){
		console.log(this.tree1.treeModel);
		this.tree1.treeModel.doForAll(node => node.isLeaf? this.addNode(node) : null)
	}

	addNode(node: any){
		if(node != undefined && node != null){
			this.deleteNodeFromList(this.nodes1,node);
			this.nodes2.push(node.data);
			this.tree2.treeModel.update();
			this.tree1.treeModel.update();
		}
	}

	drillUp(node: any){
		if(node != undefined && node.index != 0){
			[this.nodes2[node.index-1],this.nodes2[node.index]] = [this.nodes2[node.index],this.nodes2[node.index-1]];
			node.index--;
			this.tree2.treeModel.update();
		} 
	}

	drillDown(node: any){
		if(node != undefined && node.index < this.nodes2.length-1){
			[this.nodes2[node.index+1],this.nodes2[node.index]] = [this.nodes2[node.index],this.nodes2[node.index+1]];
			node.index++;
			this.tree2.treeModel.update();
		} 
	}

	deleteSelectedNodes(){
		_.each(this.nodes2, (node)=>{
			// child node
			if(node.parent) this.addNodeToList(this.nodes1,node);
			// root node
			else this.nodes1.push(node);
		})
		this.nodes2 = [];
		this.tree2.treeModel.update();
		this.tree1.treeModel.update();
	}

	private deleteNodeFromList(list: any,node: any): any{
		_.remove(list,(n:any)=>{
			if(n.children) this.deleteNodeFromList(n.children,node);
			return n.id == node.id;
		})
	}

	private addNodeToList(list: any,node: any): any{
		for(let i in list){
			if(list[i].id == node.parent) list[i].children.push(node);
			else this.addNodeToList(list[i].children, node);
		} 
	}

}

