import { Exclude, Expose, Type } from 'class-transformer';
import { CaracteristiquePhysicoChimique } from './caracteristique-physico-chimique';

@Exclude()
export class AnalyseNutritionnelle {

    @Type(()=>CaracteristiquePhysicoChimique)
    cpc : CaracteristiquePhysicoChimique[];
    
    constructor(){}
}
