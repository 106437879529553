import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal, NgbModule, NgbTypeahead, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { LoginService } from 'app/services/login/login.service';
import { IntrantService } from 'administration/services/intrant/intrant.service';
import { AnalyseService } from 'administration/services/analyse/analyse.service';



import { Administrateur } from 'administration/models/administrateur';
import { ParametresApplication } from 'administration/models/parametres-application';
import { Intrant } from 'administration/models/intrant';
import { Analyse } from 'administration/models/analyses';
import { DatePickerAdapter } from 'global/services/date-picker-adapter/date-picker-adapter.service';

import { CaracteristiquePhysicoChimique } from 'administration/models/caracteristique-physico-chimique';
import { CpcService } from 'administration/services/cpc/cpc.service';

@Component({
	selector: 'app-intrant-update',
	templateUrl: './intrant-update.component.html',
	styleUrls: ['./intrant-update.component.scss']
})
export class IntrantUpdateComponent implements OnInit {

	@ViewChild('instanceCode') instanceCode: NgbTypeahead;
	@ViewChild('instanceMode') instanceMode : NgbTypeahead;
	@ViewChild("date") instanceDate : NgbInputDatepicker;
	focusCode$ = new Subject<string>();
	clickCode$ = new Subject<string>();
	focusMode$ = new Subject<string>();
	clickMode$ = new Subject<string>();

	public loggedUser: Administrateur;
	public intrant : Intrant;
	public categories;
	public consistances;
	public modeDeLivraison;
	public documentAEmettre;
	public codeDechets;
	public sousProduitsAnimaux;
	public analyseNames;
	public analyses : any ;
	public analyseArray;
	public toBeCreatedAnalyses = [];
	public dateAnalyse : Date;
	public fieldNames;
	public typesAnalyseDTO;
	public units;
	public cpc = {};
	private onLoad = false;


	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private loginService: LoginService,
		private intrantService : IntrantService,
		private modalService : NgbModal,
		private analyseService : AnalyseService,
		private dateService : DatePickerAdapter,
		private _eref: ElementRef,
		private cpcService : CpcService
		) { 
			this.intrant = this.intrantService.constructIntrantBase();
			this.analyseNames = this.analyseService.analyseNames;
			this.units = this.analyseService.units;			
			this.modeDeLivraison = this.intrantService.modeDeLivraison;
			this.codeDechets = Object.entries(this.intrantService.codeDechets);
		}

	ngOnInit() {
		this.onLoad = true;
		this.loggedUser = this.loginService.getLoggedUser();
		
		if(this.route.snapshot.paramMap.keys.indexOf('id')>=0){
			let id: string = this.route.snapshot.paramMap.get('id');
			this.getIntrant(id);
		}
		this.fieldNames = this.intrantService.fieldNames;
		this.typesAnalyseDTO = this.analyseService.typesAnalyseDTO;
		this.categories = this.intrantService.categories;
		this.consistances = this.intrantService.consistances;
		this.sousProduitsAnimaux = this.intrantService.sousProduitsAnimaux;
		this.documentAEmettre = this.intrantService.documentAEmettre;
	}

	/**
	* get the intrant and populate the object corresponding to the analyses
	*/
	private getIntrant(id: string){
		this.intrantService.get(id)
			.subscribe((res: Intrant) => {
				if(res != undefined && res.id != undefined) {
					this.intrant = res;
					if(this.intrant.analyses){
						for(let analyse of Object.entries(this.intrant.analyses)){
							if(this.typesAnalyseDTO.includes(analyse[0])){								
								if(analyse[1] != null && analyse[1] != undefined){
									let analyseDTO = this.analyseService.createTypeAnalyseDTO(analyse[0]);
									if(!this.analyses) this.analyses = {};
									this.analyses[analyse[0]]=this.analyseService.toDTO(analyseDTO,analyse[1]);
								}else{
									this.toBeCreatedAnalyses.push(analyse[0]);
								}
							}
						}
						this.intrant.analyses.dateAnalyse ? this.dateAnalyse = new Date(this.intrant.analyses.dateAnalyse) : this.dateAnalyse = null;
						this.analyseArray = Object.entries(this.analyses);
					} else {
						this.toBeCreatedAnalyses = this.analyseService.typesAnalyseDTO;
					}
					console.log(this.intrant);
					this.cpc = this.cpcService.transformToJson(this.intrant.cpco);
					console.log(this.cpc);		 
				}
				this.onLoad = false;
			}
		);
	}

	private deleteIntrant(){
		this.intrantService.delete(this.intrant.id)
		.subscribe((res: Intrant) =>{
			if(res != undefined && res.id != undefined){
				this.router.navigate(["/administration/intrants/list"])
			}
		})
	}

	private updateIntrant(){
		this.intrant.modifiedBy = this.loggedUser.email;
		this.intrant.modifiedOn = Date.now();
		this.intrant.codeDechet = this.intrant.codeDechet[0];
		this.setAnalyseHeader();
		this.analyses? this.intrant.analyses = <Analyse>this.analyses : this.intrant.analyses = null;
		this.intrant.cpco = Object.values(this.cpc);
		console.log("Saving",this.intrant.analyses);
		this.intrantService.update(this.intrant)
		.subscribe((res : Intrant)=>{
			if(res != undefined && res.id != undefined) this.router.navigate(['/administration/intrants/list']);
		})
	}

	/**
	* Lorsque l'analyse change, met à jour l'intrant
	*/
	private onChange(event : any, analyse){
		//analyse[0] is the name of the analyse
		this.intrant.analyses[analyse[0]] = event;
	}

	/**
	* Gère l'écriture des valeurs des cpc, et calcule le pb ou le pm si besoin
	*/
	public calc(event, cpc){
		if(cpc == "potentielBiogaz"){
			this.calcPM(event);
			this.cpc[cpc].valeur = event;
		}else if(cpc == "potentielMethane"){
			this.calcPB(event);
			this.cpc[cpc].valeur = event;
		}else{
			this.cpc[cpc].valeur = event;
		}
	}

	private calcPB(pm){
		this.cpc["potentielBiogaz"].valeur = this.analyseService.calcPB(pm,this.cpc["tauxCh4"].valeur,this.cpc["matiereOrganique"].valeur);
	}

	private calcPM(pb){
		this.cpc["potentielMethane"].valeur = this.analyseService.calcPM(pb,this.cpc["tauxCh4"].valeur,this.cpc["matiereOrganique"].valeur);
	}


	private deleteAnalyse(){
		this.analyses = {};
		this.analyseArray = [];
		this.intrant.analyses = null;
		this.toBeCreatedAnalyses = this.typesAnalyseDTO;

	}
	
	/**
	* Crée une analyse qui contiendra les types d'analyse ainsi que la date, lieu, type d'analyse crée
	*/
	public addAnalyse(){
		if(!this.intrant.analyses){
			this.intrant.analyses = this.analyseService.newAnalyseDTO();
			this.toBeCreatedAnalyses = [];
			this.analyses = {};
			for(let analyse of Object.entries(this.intrant.analyses)){
				if(this.typesAnalyseDTO.includes(analyse[0])){
					if(analyse[1] != null && analyse[1] != undefined){
						let analyseDTO = this.analyseService.createTypeAnalyseDTO(analyse[0]);
						this.analyses[analyse[0]] = this.analyseService.toDTO(analyseDTO,analyse[1]);				
					}else{
						this.toBeCreatedAnalyses.push(analyse[0]);
					}
				}
			}
			this.analyseArray = Object.entries(this.analyses);
		}
	}

	/**
	* Ajouter un type d'analyse 
	* @param analyse name - Type d'analyse à ajouter
	*/
	private addTypeAnalyse(analyseName){
		let newAnalyse = this.analyseService.createTypeAnalyseDTO(analyseName);
		this.analyses[analyseName] = newAnalyse;
		console.log("Before",this.analyseArray);
		this.analyseArray = Object.entries(this.analyses);
		console.log("After",this.analyseArray);
		this.toBeCreatedAnalyses = this.toBeCreatedAnalyses.filter(analyse => analyse !== analyseName);
	}

	private deleteTypeAnalyse(analyseName : string ){
		this.analyses[analyseName] = undefined;
		this.analyseArray = Object.entries(this.analyses);
		this.toBeCreatedAnalyses.push(analyseName);
	}
	

	/**
	*Permet de gérer les click event pour fermer le popup calendrier lorsque l'on clique en dehors
	* @param event - $event depuis html
	* @param id - valeur de l'attribut id de l'élément visé
	*/
	public handleClickDatepicker(event : any,id : string){
		let element = document.getElementById(id);
		let clickedInside = false;
		if(element){
			let el = event.target;
			while(el.parentElement){				
				if(el == element) {
					clickedInside = true;
				}
				el = el.parentElement;
			}
		}
		if(!clickedInside){
			if(this.instanceDate) this.instanceDate.close();
		}
	}

	/**
	* Ecrit les valeurs de l'analyse de l'intrant dans l'analyse créée 
	*/
	private setAnalyseHeader(){
		if(this.intrant.analyses){
			this.analyses["typeAnalyse"] = this.intrant.analyses.typeAnalyse;
			if(this.analyses["typeAnalyse"] != "Biblio"){
				this.analyses["siteMetha"] = this.intrant.analyses.siteMetha;
				this.analyses["dateAnalyse"] = this.dateAnalyse.getTime();
			}
		}
	}

	searchCode = (text$: Observable<string>) =>
		text$
			.debounceTime(200).distinctUntilChanged()
			.merge(this.focusCode$)
			.merge(this.clickCode$.filter(() => !this.instanceCode.isPopupOpen()))
			.map(term => (term === '' ? this.codeDechets : this.codeDechets.filter(
				c => {
					c = String(c);
					c = c.toLowerCase();
					return c.indexOf(term.toLowerCase()) > -1;
					})
			.slice(0, 10)));


	searchMode = (text$: Observable<string>) =>
		text$
			.debounceTime(200).distinctUntilChanged()
			.merge(this.focusMode$)
			.merge(this.clickMode$.filter(() => !this.instanceMode.isPopupOpen()))
			.map(term => (term === '' ? this.modeDeLivraison : this.modeDeLivraison.filter(
				(m : string) => 
				m.toLowerCase().indexOf(term.toLowerCase()) > -1))
			.slice(0, 10));

}

