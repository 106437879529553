import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Organisation } from 'administration/models/organisation';

import { OrganisationService } from 'administration/services/organisation/organisation.service';
import { BreadcrumbService } from 'global/services/breadcrumb/breadcrumb.service';
import { ConfirmationGuardService } from 'global/services/confirmation-guard/confirmation-guard.service';


@Component({
	selector: 'app-organisation-list',
	templateUrl: './organisation-list.component.html',
	styleUrls: ['./organisation-list.component.scss']
})
export class OrganisationListComponent implements OnInit {
	organisations : Organisation[] = new Array<Organisation>();
	filteredOrganisations: Organisation[] = new Array<Organisation>();
	selectedOrganisations: Organisation[];
	headers : any[] = new Array<any>();
	view: string = 'table';
	columnList: any[] = [];
	loading: boolean = false;
	loadingMessage: string = '';

	constructor(
		private organisationService : OrganisationService,
		private breadcrumbService: BreadcrumbService,
		private confirmationGuardService: ConfirmationGuardService,
		private router : Router
	) { }

	ngOnInit() {
		this.fetchData();
		this.breadcrumbService.add({label : "Organisations", route : this.router.routerState.snapshot.url});
		this.headers  = this.organisationService.headers;
		this.constructColumnList();
	}


	organisationDetail(id : string){
		this.router.navigate(['administration/organisation/',id]);
	}

	organisationAdd(){
		this.router.navigate(['administration/organisation/add']);
	}

	organisationRemove(orga : Organisation){
		this.confirmationGuardService.confirm('Voulez-vous vraiment supprimer l\'organisation '+orga.nom+' et tous les sites et utilisateurs liés?')
			.then(result =>{
				this.organisationService.delete(orga.id)
					.subscribe(res=>{
						if(res && res.id) this.fetchData();
					})
			})
			.catch(error => console.log(error));
	}

	organisationSelected(event: any){
		this.selectedOrganisations = event.length ? this.organisations.filter(orga => event.map(e => e.rowId).includes(orga.id)) : undefined;
	}
	deleteSelectedOrganisation(){
		let message = this.selectedOrganisations.length == 1 ? "Voulez-vous supprimer cette organisation ?" : "Voulez-vous supprimer ces "+this.selectedOrganisations.length+" organisations?";
    this.confirmationGuardService.confirm(message)
      .then(confirm =>{
      	this.loading = true;
  			this.loadingMessage = "Suppression d'organisations";
        this.organisationService.bulkDelete(this.selectedOrganisations)
          .subscribe(result =>{
            if(result && !result.error) {
            	this.loading = false;
            	this.fetchData();
            }
            this.selectedOrganisations = [];
          })
      })
      .catch(cancel => console.log(cancel))
	}

	searchTermChanged(term : string){
    this.filteredOrganisations = this.organisations.filter(tier => {
      for(let header of this.headers){
        let value = _.get(tier,header.nom);
        if(value && value.toString().toLowerCase().includes(term.toLowerCase())) return true;
      }
      return false;
    })
    return this.filteredOrganisations;
  }

  toggleView(view : string){
		this.view = view;
	}

  getCardValue(recep : Organisation, key : string){
    return _.get(recep,key);
  }

  private fetchData(){
  	this.loading = true;
  	this.loadingMessage = 'Chargement de la liste des organisations';
  	this.organisationService.list()
	.subscribe((organisations: Organisation[])=>{
		if(organisations && !organisations[0].id) {
			organisations = [];
		}
		organisations = _.sortBy(organisations, ['nom']);
		this.organisations = organisations;
		this.filteredOrganisations = organisations;
		this.loading = false;
	});
  }

  private constructColumnList(){
		this.columnList = [];
		for(let header of this.headers){
			let col : any = {};
			col.dataKey = header.nom;
			col.name = header.label;
    	col.excluded = true;
    	this.columnList.push(col);
		}
	}

}
