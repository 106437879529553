import { Directive, ElementRef, Renderer2, AfterViewInit, ContentChildren, QueryList, forwardRef } from '@angular/core';

import { SidebarItemDirective } from './../sidebar-item/sidebar-item.directive';

@Directive({
	selector: '[sidebar-sub-level-items]'
})
export class SidebarSubLevelDirective implements AfterViewInit{

	@ContentChildren(forwardRef(() => SidebarItemDirective)) items: QueryList<SidebarItemDirective>;

	constructor(
		private el: ElementRef,
		private renderer: Renderer2
	){

	}

	ngAfterViewInit(){
		if(this.items != undefined){
			this.items.forEach(item =>{
				this.renderer.listen(item.element.nativeElement, 'click',(event)=>{
					this.toggleActivation();
					this.renderer.addClass(item.element.nativeElement,'active');
				})
			})
		}
	}

	get element(){
		return this.el;
	}

	/**
	* remove active class from items
	*/
	public toggleActivation(){
		if(this.items != undefined && this.items.length !=0){
			this.items.forEach(item => {
				this.renderer.removeClass(item.element.nativeElement,'active');
			})
		}
	}
}
