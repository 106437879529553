import { Component, OnInit } from '@angular/core';

import { Log } from 'configuration/models/log';
import { LogService } from 'configuration/services/log.service';


@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {

	public logs: Log[];
	public toggledView: string = 'list';

  constructor(
  	private logService: LogService
	) { }

  ngOnInit() {
  	this.logService.list()
  		.subscribe(res => {
  			this.logs = res.sort((a,b) => b.time - a.time);
  		});
  }

  public toggleView(){
  	this.toggledView = this.toggledView == 'list' ? 'table' : 'list';
  }

}
