import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { map, merge, filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as _ from 'lodash';


@Injectable()
export class BreadcrumbService {

  private _breadcrumbSource = new BehaviorSubject([]);
  public breadcrumb = this._breadcrumbSource.asObservable();

  add(breadcrumbItem : any){
    let tmp;
    if(sessionStorage.getItem('tmpBreadcrumb')) {
      tmp = JSON.parse(sessionStorage.getItem('tmpBreadcrumb'));
      sessionStorage.removeItem('tmpBreadcrumb');
    } else {
      tmp = this._breadcrumbSource.getValue();
    }
    let index = _.findIndex(tmp,['label',breadcrumbItem.label]);
    if(index < 0 ){
      tmp.push(breadcrumbItem);
      this._breadcrumbSource.next(tmp);
    }
  }

  removeLast(){
    let tmp = this._breadcrumbSource.getValue();
    tmp.pop();
    this._breadcrumbSource.next(tmp);
  }

  removeFrom(index : number){
    let tmp = this._breadcrumbSource.getValue();
    tmp.splice(index);
    this._breadcrumbSource.next(tmp);
  }

  reset(){
    this._breadcrumbSource.next([{label : "Home", route : "administration/organisation/list"}]);
  }

  constructor(private router : Router) { }
}