import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpResponse, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import {FormControl} from '@angular/forms';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { saveAs } from 'file-saver/FileSaver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

import { BanqueModalComponent } from 'administration/components/banque-modal/banque-modal.component';
import { AttachementModalComponent } from 'administration/components/organisation/site/attachement-modal/attachement-modal.component';
import { ConfirmationGuardService } from 'global/services/confirmation-guard/confirmation-guard.service';
import { BreadcrumbService } from 'global/services/breadcrumb/breadcrumb.service';
import { SiteService } from 'administration/services/site/site.service';
import { AttachmentService } from 'administration/services/attachment/attachment.service';
import { ParametresService } from 'global/services/parametres/parametres.service';
import { LoginService } from 'app/services/login/login.service';

import { ParametresApplication } from 'administration/models/parametres-application';
import { Site } from 'administration/models/site';
import { Administrateur } from 'administration/models/administrateur';
import { Contact } from 'administration/models/contact';
import { Banque } from 'administration/models/banque';
import { Entreprise } from 'administration/models/entreprise';

@Component({
  selector: 'app-site-update',
  templateUrl: './site-update.component.html',
  styleUrls: ['./site-update.component.scss']
})
export class SiteUpdateComponent implements OnInit {
  site: Site = new Site();
  loggedUser: any;
  orga : any = {};
  parametres: any[];
  invalidSubmitted: boolean = false;
  attachements: any[] = [];
  attachementToAdd: boolean = false;
  attachementSize: number = 0;
  loading: boolean = false;
  loadingMessage: string = '';
  codeSiteExists: boolean;
  contactFacturationEmails: any[] = [];
  columnListBanque: any[];
  selectedBanques: any[];
  selectedNegociantBanques: any[];

  constructor(
    private siteService: SiteService,
    private attachmentService: AttachmentService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private appConfigService: ParametresService,
    private loginService: LoginService,
    public domSanitizer: DomSanitizer,
    private breadcrumbService: BreadcrumbService,
    private confirmationGuardService: ConfirmationGuardService,
    private modalService: NgbModal,
  ) {

  }

  ngOnInit() {
    this.loggedUser = this.loginService.getLoggedUser();
    this.parametres = this.appConfigService.getParametresApplication();
    this.constructBanqueColumnList();
    if(this.route.snapshot.paramMap.keys.indexOf('idSite')>=0){
      let id: string = this.route.snapshot.paramMap.get('idSite');
      this.getSite(id);
    }
    if(this.route.snapshot.paramMap.keys.indexOf('idOrga')>=0){
      this.orga['id'] = this.route.snapshot.paramMap.get('idOrga');
    }
  }

  siteNomChanged(){

  }

  updateNegociantBanque(event) {
    const modalRef = this.modalService.open(BanqueModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.banque = this.site.negociant.compteBancaires[event.index];
    modalRef.componentInstance.title = "Modification d'une banque ";
    modalRef.componentInstance.banques = this.site.negociant.compteBancaires;
  }

  negociantBanqueSelected(event) {
    this.selectedNegociantBanques = event.length ? event : undefined;
  }

  deleteNegociantBanques(){
    if(this.selectedNegociantBanques)
      this.selectedNegociantBanques.forEach((value, index) =>{
        this.site.negociant.compteBancaires.splice(value.index-index,1);
      })
      this.selectedNegociantBanques = [];
  }

  openAddNegociantBanqueModal(){
    const modalRef = this.modalService.open(BanqueModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.title = "Ajout d'une banque";
    modalRef.componentInstance.banques = this.site.negociant.compteBancaires;
    modalRef.result
      .then(result => {
        this.site.negociant.compteBancaires.push(result);
      })
      .catch(error => console.error(error))
  }

  updateBanque(event) {
    const modalRef = this.modalService.open(BanqueModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.banque = this.site.porteurProjet.compteBancaires[event.index];
    modalRef.componentInstance.title = "Modification d'une banque ";
    modalRef.componentInstance.banques = this.site.porteurProjet.compteBancaires;
  }

  banqueSelected(event) {
    this.selectedBanques = event.length ? event : undefined;
  }

  deleteBanques(){
    if(this.selectedBanques)
      this.selectedBanques.forEach((value, index) =>{
        this.site.porteurProjet.compteBancaires.splice(value.index-index,1);
      })
      this.selectedBanques = [];
  }

  openAddBanqueModal(){
    const modalRef = this.modalService.open(BanqueModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.title = "Ajout d'une banque";
    modalRef.componentInstance.banques = this.site.porteurProjet.compteBancaires;
    modalRef.result
      .then(result => {
        this.site.porteurProjet.compteBancaires.push(result);
      })
      .catch(error => console.error(error))
  }

  showAttachment(att: any){
    if(att && att.file){
      saveAs(att.file, att.name);
    }
    else if(att && att.id){
      this.loading = true;
      this.loadingMessage = 'Téléchargement de la pièce jointe';
      this.attachmentService
        .getDocument(att.id)
          .subscribe( (res:Blob) =>{
            if(res != undefined && res instanceof HttpResponse){
              saveAs(res.body, att.name);
              this.loading = false;
            }
          })
    }
  }

  addAttachment(){
    const modal = this.modalService.open(AttachementModalComponent,{size: 'lg'});
    modal.componentInstance.attachements = this.attachements;
    modal.result
      .then(result => {
        let user = JSON.parse(sessionStorage.getItem('loggedUser'));
        if(this.attachementSize/1000000 <= 2 && this.attachements.length+1 <= 2){
          this.attachementToAdd = this.attachementToAdd ? this.attachementToAdd : !this.attachementToAdd;
          let help = result.name.split('.');
          let nameCount = this.attachements.filter( att => att.name.split('.')[0].split('(')[0] == help[0]).length;
          result.name = nameCount ?  help[0]+'('+nameCount+').'+help[1] : result.name;
          result.createdBy = user.principal.username;
          this.attachements.push(result);
        }else{
          this.confirmationGuardService
            .alert("Les pièces jointes sont au plus 2 et ne peuvent pas dépasser 2 Mo")
        }
      })
      .catch(error => console.log(error));
  }

  deleteAttachment(index: number){
    if(this.attachements[index].id){
      this.attachmentService.delete(this.attachements[index].id)
        .subscribe(
          res => {
            if(res && res.id) this.attachements.splice(index,1);
            else if(res && res.error) console.error(res.error);
          }
        )
    }
    else this.attachements.splice(index,1);
  }

  submit(formValid: boolean){
    formValid ? this.updateSite() : this.invalidSubmitted = true;
  }

  private getSite(id: string){
    this.siteService.get(id)
      .subscribe(site => {
        site.dateRaccordement = new Date(site.dateRaccordement);
        site.dateCreation = new Date(site.dateCreation);
        site.dateMonitoring = new Date(site.dateMonitoring);
        site.porteurProjet.noteFacturation = site.porteurProjet.noteFacturation ? site.porteurProjet.noteFacturation : "Dénomination sociale, forme juridique et capital social, adresse, SIRET, N° TVA, N° et ville du RCS ,conditions d'escompte";
        this.site = site;
        this.setContactFacturation();
        this.setPorteurProjetBanque();
        this.setNegociant();
        this.listAttachment();
        this.breadcrumbService.add({label : site.nom, route : this.router.routerState.snapshot.url});
      });
  }
  /**
  * update site
  */
  public updateSite(){
    this.site.modifiedBy = this.loggedUser.email;
    this.site.modifiedOn = Date.now();
    if(this.contactFacturationEmails.length > 0) {
      this.site.porteurProjet.contactFacturation.email = this.contactFacturationEmails
        .map(dest => dest.value ? dest.value : dest)
        .reduce((a,b) => `${a}, ${b}`);
    } else this.site.porteurProjet.contactFacturation.email = undefined;
    this.loadingMessage = 'Mise à jour du site: '+this.site.nom;
    this.loading = true;
    this.transformSiteDates();
  	this.siteService.update(this.site, this.attachements)
  		.subscribe((res: any)=> {
  			if((res != null && res.id != undefined && res.revision != undefined) || res.type === HttpEventType.Response){
          this.breadcrumbService.removeFrom(-2);
					this.router.navigate(['administration/organisation',this.orga.id]);
				}
  		})
  }

  onBlur(){
    this.siteService.checkIfExists(this.site)
      .subscribe(res => {
        this.codeSiteExists = res && res.id != this.site.id ? true : false;
      })
  }

  transformSiteDates(){
    if(this.site.dateCreation) this.site.dateCreation = this.site.dateCreation.getTime();
    if(this.site.dateMonitoring) this.site.dateMonitoring = this.site.dateMonitoring.getTime();
    if(this.site.dateRaccordement) this.site.dateRaccordement = this.site.dateRaccordement.getTime();
  }

  public errorMessages = {
    'emailValidator': 'les adresses mails sont uniquement acceptées'
  };

  get validators(){
    return [this.emailValidator];
  }

  public emailValidator(control: FormControl) {
    const regex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");
    if(!regex.test(control.value)) {
      return {
        emailValidator: true
      };
    }
    return null;
  }

  /**
  * Add site
  */
  public deleteSite(){
  	this.siteService.delete(this.site.id)
  		.subscribe((res: any)=> {
  			if(res != null && res.id != undefined && res.revision != undefined){
					this.router.navigate(['administration/site/list']);
				}
  		})
  }

  goToOrga(){
    this.router.navigate(['administration/organisation',this.orga.id]);
  }

  private listAttachment(){
    if(this.site && this.site.id)
      this.attachmentService.list(this.site.id)
        .subscribe(attachments => {
          this.attachements = attachments;
          console.log(attachments);
        })
  }

  private setContactFacturation() {
    if(this.site.porteurProjet.contactFacturation && this.site.porteurProjet.contactFacturation.email) {
      this.contactFacturationEmails = this.site.porteurProjet.contactFacturation.email.split(', ');
    } else {
      this.contactFacturationEmails = [];
      this.site.porteurProjet.contactFacturation = new Contact();
    }
  }

  private setPorteurProjetBanque() {
    if(!this.site.porteurProjet.compteBancaires){
      this.site.porteurProjet.compteBancaires = new Array<Banque>();
    }
  }

  private setNegociant() {
    if(!this.site.negociant){
      this.site.negociant = new Entreprise();
    }
  }

  private constructBanqueColumnList(){
    this.columnListBanque = [];
    for(let header of this.siteService.banqueHeaders()){
      let col : any = {};
      col.dataKey = header.nom;
      col.name = header.label;
      col.excluded = true;
      this.columnListBanque.push(col);
    }
  }

}
