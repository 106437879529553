import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AdministrationComponent } from './components/administration.component';
import { IntrantListComponent } from './components/intrant/intrant-list/intrant-list.component';
import { IntrantAddComponent } from './components/intrant/intrant-add/intrant-add.component';
import { IntrantAddFromFileComponent } from './components/intrant/intrant-add-from-file/intrant-add-from-file.component';
import { IntrantUpdateComponent } from './components/intrant/intrant-update/intrant-update.component';
import { OrganisationAddComponent } from './components/organisation/organisation-add/organisation-add.component';
import { OrganisationUpdateComponent } from './components/organisation/organisation-update/organisation-update.component';
import { OrganisationListComponent } from './components/organisation/organisation-list/organisation-list.component';
import { SiteAddComponent } from './components/organisation/site/site-add/site-add.component';
import { SiteUpdateComponent } from './components/organisation/site/site-update/site-update.component';
import { UserAddComponent } from './components/organisation/user/user-add/user-add.component';
import { UserUpdateComponent } from './components/organisation/user/user-update/user-update.component';
import { UserExterneAddComponent } from './components/user-externe/user-externe-add/user-externe-add.component';
import { UserExterneUpdateComponent } from './components/user-externe/user-externe-update/user-externe-update.component';
import { UserExterneListComponent } from './components/user-externe/user-externe-list/user-externe-list.component';
import { IndexFacturationComponent } from './components/index-facturation/index-facturation.component';
import { ConnexionsUtilisateurComponent } from './components/connexions-utilisateur/connexions-utilisateur.component';


const routes =[
	{
		path: 'administration',
		component: AdministrationComponent,
		children:[
			{
				path: 'organisation',
				children:[
					{path: 'list', component: OrganisationListComponent},
					{path: 'add', component: OrganisationAddComponent},
					{
						path: ':idOrga',
						children:[
							{
								path: 'site',
								children:[
									{path: 'add', component: SiteAddComponent},
									{path: 'update/:idSite', component: SiteUpdateComponent}
								]
							},
							{
								path: 'user',
								children:[
									{path: 'add', component: UserAddComponent},
									{path: 'update/:idUser', component: UserUpdateComponent}
								]
							},
							{path: '', component: OrganisationUpdateComponent}
						]
					},
					{path: '', component: OrganisationListComponent, pathMatch: 'full'}
				]
			},
			{
				path: 'user',
				children:[
					{path: 'add', component: UserExterneAddComponent},
					{path: 'update/:idUser', component: UserExterneUpdateComponent},
					{path: 'list', component: UserExterneListComponent},
					{path: '', component: UserExterneListComponent, pathMatch: 'full'}
				]
			},
			{
				path: 'intrant',
				children:[
					{path: 'list', component: IntrantListComponent},
					{path: 'update:/id', component: IntrantUpdateComponent},
					{path: 'add', component: IntrantAddComponent},
					{path: 'add_from_file', component: IntrantAddFromFileComponent},
					{path: '', component: IntrantListComponent, pathMatch: 'full'}
				]
			},
			{path: 'index-facturation', component: IndexFacturationComponent},
			{path: '', component: OrganisationListComponent, pathMatch: 'full'},
			{path: 'connexions-utilisateur', component: ConnexionsUtilisateurComponent}
		]
	}
];

@NgModule({
	exports:[
		RouterModule
	],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes,{useHash: true})
  ],
  declarations: []
})
export class AdministrationRoutingModule { }
