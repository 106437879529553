import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

import { ITableFooter } from '../interfaces/ITableFooter';
import { PaginationService } from '../services/PaginationService';
import { PaginationType } from '../enums/pagination-type';
@Component({
    selector: 'table-footer',
    templateUrl: '../views/table-footer.html',
})
export class TableFooterComponent implements OnInit{
	@Input('table-footer') tableFooter: ITableFooter;
  @Output('onchangepage') onChangePage: EventEmitter<any> = new EventEmitter();

  constructor(private paginationService_: PaginationService,private cdRef:ChangeDetectorRef){

  }

  ngOnInit(){
  }

  ngAfterViewChecked(){
    this.tableFooter.pagination.rowsPerPage =  this.tableFooter.pagination.rowsPerPage || [];
    if(this.isEnabled()){
      this.tableFooter.pagination.defaultRowsPerPage = this.tableFooter.pagination.defaultRowsPerPage
                                                          || this.tableFooter.pagination.rowsPerPage[0];

      this.paginationService_.setItemsPerPage(this.tableFooter.pagination.defaultRowsPerPage);
    }
    if(this.tableFooter.pagination.type == 'AJAX'){
      this.paginationService_.setTotalRowsCount(this.tableFooter.pagination.totalRowsCount);
    }
    this.cdRef.detectChanges();
  }

  isEnabled(){
    return this.tableFooter.pagination.rowsPerPage.length > 0;
  }

  setPageSize(pageSize:number){
    this.paginationService_.setItemsPerPage(+pageSize);
  }

  nextPage(){
    this.paginationService_.nextPage();
    if(this.ajaxPaginationIsAllowed() && this.onChangePage)
      this.onChangePage.emit({
        page: this.paginationService_.page,
        itemsPerPage: this.paginationService_.itemsPerPage
      })
  }

  previousPage(){
    this.paginationService_.previousPage();
    if(this.ajaxPaginationIsAllowed() && this.onChangePage) 
      this.onChangePage.emit({
        page: this.paginationService_.page,
        itemsPerPage: this.paginationService_.itemsPerPage
      })
  }

  isPreviousButtonDisabled(): string {
    if(this.paginationService_.hasPreviousPage() == false){
      return 'dt-dark dt-inactive';
    }
    return '';
  }

  isNextButtonDisabled(): string {
    if(this.paginationService_.hasNextPage() == false){
      return 'dt-dark dt-inactive';
    }
    return '';
  }

  private ajaxPaginationIsAllowed(): boolean{
    return this.tableFooter.pagination.type == 'AJAX';
  }
}