
export class BiogasviewDocument{
	id: string;
	revision: string;
	dataType: string;
	createdBy: string;
	modifiedBy: string;
	attachments: any;
	createdOn: number;
	modifiedOn: number;
}