import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';

import { GlobalModule } from 'global/global.module';
import { AdministrationRoutingModule } from './administration-routing.module';

import { AnalyseService } from './services/analyse/analyse.service';
import { OrganisationService } from './services/organisation/organisation.service';
import { UserService } from './services/user/user.service';
import { UserExterneService } from './services/user-externe/user-externe.service';
import { SiteService } from './services/site/site.service';
import { IntrantService } from './services/intrant/intrant.service';
import { AttachmentService } from './services/attachment/attachment.service';
import { IndexFacturationService } from './services/index-facturation/index-facturation.service';
import { AdministrationComponent } from './components/administration.component';
import { OrganisationAddComponent } from './components/organisation/organisation-add/organisation-add.component';
import { OrganisationUpdateComponent } from './components/organisation/organisation-update/organisation-update.component';
import { OrganisationListComponent } from './components/organisation/organisation-list/organisation-list.component';
import { UserAddComponent } from './components/organisation/user/user-add/user-add.component';
import { UserUpdateComponent } from './components/organisation/user/user-update/user-update.component';
import { SiteAddComponent } from './components/organisation/site/site-add/site-add.component';
import { SiteUpdateComponent } from './components/organisation/site/site-update/site-update.component';
import { IntrantListComponent } from './components/intrant/intrant-list/intrant-list.component';
import { IntrantAddComponent } from './components/intrant/intrant-add/intrant-add.component';
import { IntrantUpdateComponent } from './components/intrant/intrant-update/intrant-update.component';
import { UserExterneAddComponent } from './components/user-externe/user-externe-add/user-externe-add.component';
import { UserExterneUpdateComponent } from './components/user-externe/user-externe-update/user-externe-update.component';
import { UserExterneListComponent } from './components/user-externe/user-externe-list/user-externe-list.component';
import { IntrantAddFromFileComponent } from './components/intrant/intrant-add-from-file/intrant-add-from-file.component';
import { FormGroupAnalyseComponent } from './components/intrant/form-group-analyse/form-group-analyse.component';
import { PasswordModalComponent } from './components/password-modal/password-modal.component';
import { BlocAccessModalComponent } from './components/bloc-access-modal/bloc-access-modal.component';
import { AttachementModalComponent } from './components/organisation/site/attachement-modal/attachement-modal.component';
import { SiteModalComponent } from './components/user-externe/site-modal/site-modal.component';
import { IndexFacturationComponent } from './components/index-facturation/index-facturation.component';
import { ConnexionsUtilisateurComponent } from './components/connexions-utilisateur/connexions-utilisateur.component';
import { BanqueModalComponent } from './components/banque-modal/banque-modal.component';

@NgModule({
  imports: [
    CommonModule,
    GlobalModule,
    NgbModule,
    AdministrationRoutingModule,
    TagInputModule
  ],
  declarations: [
  	OrganisationAddComponent, 
  	OrganisationUpdateComponent, 
  	OrganisationListComponent, 
  	UserAddComponent, 
  	UserUpdateComponent, 
  	SiteAddComponent, 
  	SiteUpdateComponent, 
  	IntrantListComponent, 
  	IntrantAddComponent, 
  	IntrantUpdateComponent, 
  	UserExterneAddComponent, 
  	UserExterneUpdateComponent, 
  	UserExterneListComponent, 
  	IntrantAddFromFileComponent, 
  	FormGroupAnalyseComponent, 
    PasswordModalComponent,
    AdministrationComponent,
    BlocAccessModalComponent,
    AttachementModalComponent,
    SiteModalComponent,
    IndexFacturationComponent,
    ConnexionsUtilisateurComponent,
    BanqueModalComponent
	],
  providers:[
    OrganisationService,
    SiteService,
    UserService,
    UserExterneService,
    IntrantService,
    AnalyseService,
    AttachmentService,
    IndexFacturationService
  ],
  entryComponents:[
    PasswordModalComponent,
    BlocAccessModalComponent,
    AttachementModalComponent,
    SiteModalComponent,
    BanqueModalComponent
  ],
  exports: [BanqueModalComponent]
})
export class AdministrationModule { }
