import {Component, Injectable} from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct, NgbDateAdapter} from '@ng-bootstrap/ng-bootstrap';
import { padNumber, isNumber, toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import * as moment from 'moment-timezone';

const I18N_VALUES = {
  'fr': {
    weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
    months: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'fr';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n, private ngbDateAdapter : NgbDateAdapter<Date>) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date : NgbDateStruct){
    return date.day + "/" + date.month + "/" + date.year;
  }

  calculateDaysInBetweenToString(debut, fin){let dateDebut = new Date(debut);
      let dateFin = new Date(fin);
      let days = [];
      if(dateDebut && dateFin && debut < fin){
          let date = new Date(dateDebut);
          while(date.getTime() < dateFin.getTime()){
              let dateString = this.toLocaleDateStringCustom(date);
              days.push(dateString);
              date.setDate(date.getDate() + 1 );
          }
      }
      return days;
  }

  calculateDaysInBetween(debut, fin){
      let dateDebut = new Date(debut);
      let dateFin = new Date(fin);
      let days = [];
      if(dateDebut && dateFin && debut < fin){
          let date = new Date(dateDebut);
          while(date.getTime() < dateFin.getTime()){
              days.push(date);
              date = new Date(date);
              date.setDate(date.getDate() + 1 );

          }
      }
      return days;
  }

  toLocaleDateStringCustom(date : Date){
      let options = {weekday: "short", month: "long", day: "numeric"};
      let dateString = date.toLocaleDateString("fr-FR",options);
      dateString = dateString[0].toUpperCase() + dateString.slice(1);
      return dateString;
  }

  getWeekRangeFromDate(date){
      date = new Date(date);
      let dateDebut = new Date(date.getFullYear(),date.getMonth(),date.getDate());
      let numOfdaysPastSinceLastMonday = date.getDay()- 1;
      dateDebut.setDate(date.getDate() - numOfdaysPastSinceLastMonday);
      let dateFin = new Date(dateDebut);
      dateFin.setDate(dateDebut.getDate() + 6);
      dateFin.setHours(23);
      dateFin.setMinutes(59);
      dateFin.setSeconds(59);
      return {debut : dateDebut, fin : dateFin};
  }

  getDateFromModel(date : number){
    if(date){
        let tmp = new Date(date);
        let dateFormat = "";
        dateFormat = ""+padNumber(tmp.getDate())+"/"+padNumber(tmp.getMonth()+1)+"/"+tmp.getFullYear();
        return dateFormat;
    }
  }

  getDateAndTimeFromModel(date : number){
    if(date){
        let tmp = new Date(date);
        let dateFormat = "";
        dateFormat = ""+padNumber(tmp.getDate())+"/"+padNumber(tmp.getMonth()+1)+"/"+tmp.getFullYear()+" ";
        if (tmp.getHours()<10){
          dateFormat += "0"+tmp.getHours()+":";
        } else {
          dateFormat += tmp.getHours()+":";
        }
        if (tmp.getMinutes()<10){
          dateFormat += "0"+tmp.getMinutes()+":";
        } else {
          dateFormat += tmp.getMinutes()+":";
        }
        if (tmp.getSeconds()<10){
          dateFormat += "0"+tmp.getSeconds();
        } else {
          dateFormat += tmp.getSeconds();
        }
        return dateFormat;
    }
  }

  getMonthRangesByYear(year : number){
      let date = new Date(year, 0, 1);
      let result = [];
      let dateDebutMois = new Date(date);
      let dateFinMois = new Date(date.getFullYear(),date.getMonth()+1,0,23,59,59);
      result.push([dateDebutMois,dateFinMois]);
      for(let i = 1; i < 12; i++){
          dateDebutMois = new Date(dateDebutMois.getFullYear(), dateDebutMois.getMonth()+1,1);
          dateFinMois = new Date(dateDebutMois.getFullYear(),dateDebutMois.getMonth()+1,0,23,59,59);
          result.push([dateDebutMois,dateFinMois]);
      }
      return result;
  }

  getDateMonthYearFromModel(date : number){
      if(date){
          let tmp = new Date(date);
          let dateFormat = "";
          dateFormat = ""+padNumber(tmp.getMonth()+1)+"/"+tmp.getFullYear();
          return dateFormat;
      }
  }

  public stringFromDateRange(dateRange){
      return "Du "+this.getDateFromModel(dateRange.debut.getTime())+"\nau "+this.getDateFromModel(dateRange.fin.getTime());
  }

  ngbDateFromDate(date: Date): NgbDateStruct {
    return (date && date.getFullYear) ? {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()} : null;
  }

  dateFromNgbDate(date: NgbDateStruct): Date {
    return date ? new Date(date.year, date.month - 1, date.day) : null;
  }

  getModelFromDateString(value : string) : number{
      let time: number;
      if (typeof value === "string") {
        const dateParts = value.trim().split('/');
        if(dateParts.length == 3  && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])){
          time = +(new Date(toInteger(dateParts[2]),toInteger(dateParts[1])-1,toInteger(dateParts[0])).getTime());
        }
        if(dateParts.length == 2  && isNumber(dateParts[0]) && isNumber(dateParts[1])){
          time = +(new Date(toInteger(dateParts[1]),toInteger(dateParts[0])-1).getTime());
        }
      }
      else if(typeof value === "number") time = value;
      return this.getTimeZoneTime(time);
  }

  isDateString(dateString : any){
      if (typeof dateString === "string") {
        const dateParts = dateString.trim().split('/');
        if(dateParts.length == 3  && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])){
            return true;
        }
        if(dateParts.length == 2  && isNumber(dateParts[0]) && isNumber(dateParts[1])){
            return true;
        }
      }
      return false;
  }

  timeZoneDate(zoneId?: string): number{
    let timeZone = zoneId != undefined && zoneId != '' ? zoneId : 'Europe/Paris';
    return moment().tz(timeZone).valueOf();
  }

  getTimeZoneTime(date: any,zoneId?: string): number{
    let timeZone = zoneId != undefined && zoneId != '' ? zoneId : 'Europe/Paris';
    return moment.tz(date,timeZone).valueOf();
  }
}
