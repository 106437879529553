import { Exclude, Expose, Type } from 'class-transformer';
import { CaracteristiquePhysicoChimique } from './caracteristique-physico-chimique';

@Exclude()
export class AnalyseBiologique {

 //    @Expose()
	// acideAcetique : number = null;

 //    @Expose()
 //    acidePropionique : number = null;

 //    @Expose()
 //    acideIsoButyrique : number = null;

 //    @Expose()
 //    acideNButyrique : number = null;

 //    @Expose()
 //    acideIsoValerique : number = null;

 //    @Expose()
 //    acideNValerique : number = null;

 //    @Expose()
 //    ph : number = null;

 //    @Expose()
 //    agvTotal : number = null;
    
 //    @Expose()
 //    tac : number = null;

 //    @Expose()
 //    fos : number = null;

 //    @Expose()
 //    fostac : number = null;

    @Type(()=>CaracteristiquePhysicoChimique)
    cpc : CaracteristiquePhysicoChimique[];
    constructor(){}
}
