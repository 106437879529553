import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Utilisateur } from 'administration/models/utilisateur';
import { Site } from 'administration/models/site';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Access-Control-Allow-Origin':'*'
  })
};

@Injectable()
export class UserExterneService {

  private _headers: any[];
  private _moduleHeaders: any[];
  private _siteHeaders: any[];

  constructor(private http : HttpClient,private router : Router) {
    this._headers = [
      {nom: 'nom', label: 'Nom'},
      {nom: 'prenom', label: 'Prenom'},
      {nom :'email', label: 'Email'}
    ]

    this._moduleHeaders = [
      {nom: 'name', label: 'Bloc'},
      {nom: 'name', label: 'Module'},
      {nom: 'access', label: "Droit d'accès"}
    ]

    this._siteHeaders = [
      {nom:"organisation.nom",label:"Organisation"},
      {nom:"nom",label:"Nom"},
      {nom: "adresse.codePostal", label: "Code postal"},
      {nom: "adresse.commune", label: "Commune"},
      {nom: "dateCreation", label: "Date de création"},
      {nom: "dateMonitoring", label: "Date monitoring"}
    ]

  }

  get headers(){
    return this._headers;
  }

  get moduleHeaders(){
    return this._moduleHeaders;
  }

  get siteHeaders(){
    return this._siteHeaders;
  }

     /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {

        if(error.status == 401 && error.statusText == 'OK'){
          this.router.navigate(['signin']);
        }
        // TODO: send the error to remote logging infrastructure
        console.error(error); // log to console instead

        

        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }

   /**
  * Delete Utilisateur
  * @param id - Utilisateur document id
  */
  public delete(id: string): Observable<Utilisateur>{
    return this.http.delete<Utilisateur>(environment.api+'/utilisateur/delete/'+id)
      .pipe(
        catchError(this.handleError<Utilisateur>('utilisateur-delete'))
      )
  }

  /**
  * Delete multiple organizations from the database
  * @param organisation - the organisation to delete
  */
  public bulkDelete(users : Utilisateur[]){
    return this.http.post<any>(environment.api+'/utilisateur/delete/',users)
      .pipe(
        catchError(this.handleError<any>('user-delete-multiple'))
      )
  }

  /**
  * Get Utilisateur by id
  * @param id - Utilisateur document id
  */
  public get(id: string): Observable<Utilisateur>{
    return this.http.get<Utilisateur>(environment.api+'/utilisateur/find/'+id)
      .pipe(
        catchError(this.handleError<Utilisateur>('utilisateur-get'))
      )
  }

  /**
  * Add Utilisateur
  * @param Utilisateur - new Utilisateur to add
  */
  public add(Utilisateur: Utilisateur): Observable<Utilisateur>{
    return this.http.post<Utilisateur>(environment.api+'/utilisateur/create',Utilisateur)
      .pipe(
        catchError(this.handleError<Utilisateur>('utilisateur-create'))
      )
  }

  /**
  // * Update Utilisateur
  // * @param Utilisateur - Utilisateur to update
  // */
  public update(Utilisateur: Utilisateur): Observable<Utilisateur>{
    return this.http.put<Utilisateur>(environment.api+'/utilisateur/update',Utilisateur)
      .pipe(
        catchError(this.handleError<Utilisateur>('utilisateur-update'))
      )
  }

  /**
  * List all Utilisateurs
  */
  public list(): Observable<Utilisateur[]>{
    return this.http.get<Utilisateur[]>(environment.api+'/utilisateur/list')
      .pipe(
        catchError(this.handleError<Utilisateur[]>('utilisateur-list'))
      )
  }
  // list(){
  //   return this.users;
  // }

  /**
  * Check by email if Utilisateur exists
  * @param name - site database name
  */
  public checkIfExists(email: string): Observable<boolean>{

    return this.http.get<boolean>(environment.api+'/utilisateur/byEmail?email='+email)
      .pipe(
        catchError(this.handleError<boolean>('utilisateur-exists'))
      )
  }

  /**
  * Change password of the user
  * @param user - user to update
  */
  public changePassword(user : any): Observable<Utilisateur>{
    return this.http.put<Utilisateur>(environment.api+'/utilisateur/changePassword',user)
    .pipe(
      catchError(this.handleError<Utilisateur>('utilisateur-changePassword'))
      )
  }


}
