import { BiogasviewDocument } from 'global/models/BiogasviewDocument';
import { Adresse } from './adresse';
import { Site } from './site';
import { Bloc } from './bloc';

export class BiogasviewUtilisateur extends BiogasviewDocument{
	email: string ="";
	password: string = "";
	prenom: string = "";
	nom: string = "" ;
	numeroPortable: string = "";
	numeroFixe: string = "";
	adresse: Adresse = new Adresse();
	langue: string;
	lignesParPage: number;
	sites: Site[] = new Array<Site>();
	role: string;
	accessOrga: boolean = false;
	blocs: Bloc[] = new Array<Bloc>();
}