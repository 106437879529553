import { Adresse } from './adresse';
import { Contact } from './contact';
import { Banque } from './banque';

export class Entreprise{
	nom: string;
	siret: string;
	tvaNumber: string;
	contact: Contact = new Contact();
	adresse: Adresse = new Adresse();
	compteBancaires: Banque[] = new Array<Banque>();
	noteFacturation = "Dénomination sociale, forme juridique et capital social, adresse, SIRET, N° TVA, N° et ville du RCS ,conditions d'escompte";
}