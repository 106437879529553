import { Directive, ElementRef, Renderer2, ContentChildren ,AfterViewInit, QueryList} from '@angular/core';

import { SidebarItemDirective } from './../sidebar-item/sidebar-item.directive';
import { NavSidebarDirective } from './../nav-sidebar/nav-sidebar.directive';

@Directive({
	selector: '[sidebar-top-level-items]',
})
export class SidebarTopLevelDirective implements AfterViewInit{

	@ContentChildren(SidebarItemDirective) items: QueryList<SidebarItemDirective>;

	constructor(
		private el: ElementRef,
		private renderer: Renderer2
	){

	}

	ngAfterViewInit(){
		if(this.items != undefined){
			this.items.forEach(item =>{
				this.renderer.listen(item.element.nativeElement, 'click',(event)=>{
					this.toggleActivation();
					this.renderer.addClass(item.element.nativeElement,'active');
				})
			})
		}
	}

	/**
	* remove active class from items
	*/
	public toggleActivation(){
		if(this.items != undefined && this.items.length !=0){
			this.items.forEach(item => {
				this.renderer.removeClass(item.element.nativeElement,'active');
			})
		}
	}
}