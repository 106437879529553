import { BiogasviewDocument } from 'global/models/BiogasviewDocument';
import { IndexFacturation } from './index-facturation';
export class IndexFacturationAnnuelle extends BiogasviewDocument{
	annee: number;
	janvier: IndexFacturation[] ;
	fevrier: IndexFacturation[];
	mars: IndexFacturation[];
	avril: IndexFacturation[];
	mai: IndexFacturation[];
	juin: IndexFacturation[];
	juillet: IndexFacturation[];
	aout: IndexFacturation[];
	septembre: IndexFacturation[];
	octobre: IndexFacturation[];
	novembre: IndexFacturation[];
	decembre: IndexFacturation[];

	constructor(year: number){
		super();
		this.annee = year;
		this.janvier = [new IndexFacturation("04510"),new IndexFacturation("ISRP"),new IndexFacturation("ICHTE"), new IndexFacturation("FSD3"), new IndexFacturation("ICHTrev-TS1"), new IndexFacturation("FM0ABE000")];
		this.fevrier = [new IndexFacturation("04510"),new IndexFacturation("ISRP"),new IndexFacturation("ICHTE"), new IndexFacturation("FSD3"), new IndexFacturation("ICHTrev-TS1"), new IndexFacturation("FM0ABE000")];
		this.mars = [new IndexFacturation("04510"),new IndexFacturation("ISRP"),new IndexFacturation("ICHTE"), new IndexFacturation("FSD3"), new IndexFacturation("ICHTrev-TS1"), new IndexFacturation("FM0ABE000")];
		this.avril = [new IndexFacturation("04510"),new IndexFacturation("ISRP"),new IndexFacturation("ICHTE"), new IndexFacturation("FSD3"), new IndexFacturation("ICHTrev-TS1"), new IndexFacturation("FM0ABE000")];
		this.mai = [new IndexFacturation("04510"),new IndexFacturation("ISRP"),new IndexFacturation("ICHTE"), new IndexFacturation("FSD3"), new IndexFacturation("ICHTrev-TS1"), new IndexFacturation("FM0ABE000")];
		this.juin = [new IndexFacturation("04510"),new IndexFacturation("ISRP"),new IndexFacturation("ICHTE"), new IndexFacturation("FSD3"), new IndexFacturation("ICHTrev-TS1"), new IndexFacturation("FM0ABE000")];
		this.juillet = [new IndexFacturation("04510"),new IndexFacturation("ISRP"),new IndexFacturation("ICHTE"), new IndexFacturation("FSD3"), new IndexFacturation("ICHTrev-TS1"), new IndexFacturation("FM0ABE000")];
		this.aout = [new IndexFacturation("04510"),new IndexFacturation("ISRP"),new IndexFacturation("ICHTE"), new IndexFacturation("FSD3"), new IndexFacturation("ICHTrev-TS1"), new IndexFacturation("FM0ABE000")];
		this.septembre = [new IndexFacturation("04510"),new IndexFacturation("ISRP"),new IndexFacturation("ICHTE"), new IndexFacturation("FSD3"), new IndexFacturation("ICHTrev-TS1"), new IndexFacturation("FM0ABE000")];
		this.octobre = [new IndexFacturation("04510"),new IndexFacturation("ISRP"),new IndexFacturation("ICHTE"), new IndexFacturation("FSD3"), new IndexFacturation("ICHTrev-TS1"), new IndexFacturation("FM0ABE000")];
		this.novembre = [new IndexFacturation("04510"),new IndexFacturation("ISRP"),new IndexFacturation("ICHTE"), new IndexFacturation("FSD3"), new IndexFacturation("ICHTrev-TS1"), new IndexFacturation("FM0ABE000")];
		this.decembre = [new IndexFacturation("04510"),new IndexFacturation("ISRP"),new IndexFacturation("ICHTE"), new IndexFacturation("FSD3"), new IndexFacturation("ICHTrev-TS1"), new IndexFacturation("FM0ABE000")];
	}
}