import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TableComponent } from './components/table.component';
import { TableRowsComponent } from './components/table-rows.component';
import { TableHeaderComponent } from './components/table-header.component';
import { TableFooterComponent } from './components/table-footer.component';
import { TableColumnsComponent } from './components/table-columns.component';
import { TableRowComponent } from './components/table-row.component';
import { TableColumnSelectorComponent } from './components/table-column-selector.component';
import { ColumnFilterCheckboxComponent } from './components/column-filter-checkbox.component';
import { ColumnFilterDropDownComponent } from './components/column-filter-dropdown.component';
import { ColumnFilterInputComponent } from './components/column-filter-input.component';
import { ColumnFilterIntervalComponent } from './components/column-filter-interval.component';
import { TableCellAlignDirective } from './directives/table-cell-align.directive';

import { CustomDatepickerI18n } from 'global/services/custom-date-picker-i18n/custom-date-picker-i18n.service';


@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		NgbModule.forRoot()
	],
	providers:[
		CustomDatepickerI18n
	],
	declarations: [
		TableComponent,
		TableFooterComponent,
		TableHeaderComponent,
		TableRowsComponent,
		TableColumnsComponent,
		TableRowComponent,
		TableColumnSelectorComponent,
		ColumnFilterCheckboxComponent,
		ColumnFilterDropDownComponent,
		ColumnFilterInputComponent,
		ColumnFilterIntervalComponent,
		TableCellAlignDirective
	],
	exports:[
		TableComponent
	]
})
export class DataTableModule{ }
