import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal, NgbTypeahead, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

import { Administrateur } from 'administration/models/administrateur';
import { LoginService } from 'app/services/login/login.service';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';


import { IntrantService } from 'administration/services/intrant/intrant.service';
import { AnalyseService } from 'administration/services/analyse/analyse.service';
import { CpcService } from 'administration/services/cpc/cpc.service';
import { Intrant } from 'administration/models/intrant';
import { Analyse } from 'administration/models/analyses';

@Component({
	selector: 'app-intrant-add',
	templateUrl: './intrant-add.component.html',
	styleUrls: ['./intrant-add.component.scss']
})
export class IntrantAddComponent implements OnInit {

	@ViewChild('instanceCode') instanceCode : NgbTypeahead;
	@ViewChild('instanceMode') instanceMode : NgbTypeahead;
	@ViewChild('date') instanceDate : NgbInputDatepicker;
	focusCode$ = new Subject<string>();
	clickCode$ = new Subject<string>();
	focusMode$ = new Subject<string>();
	clickMode$ = new Subject<string>();

	public loggedUser: Administrateur;
	public intrant : Intrant;
	public pwdConfirmation: boolean;
	public passwordConfirm: string;
	public categories;
	public consistances;
	public modeDeLivraison;
	public documentAEmettre;
	public codeDechets;
	public sousProduitsAnimaux;	
	public analyseNames;
	public analyses : any;
	public analyseArray;
	public toBeCreatedAnalyses = [];
	public dateAnalyse : Date = null;
	public fieldNames;
	public typesAnalyseDTO;
	public units;
	public cpc = {};

	constructor(
		private router: Router,
		private loginService: LoginService,
		private intrantService : IntrantService,
		private analyseService : AnalyseService,
		private cpcService : CpcService) 
	{
		this.intrant = this.intrantService.constructIntrantBase();
		this.intrant.analyses = null;
		console.log(this.intrant);		 
	}


	ngOnInit() {
		this.loggedUser = this.loginService.getLoggedUser();
		this.toBeCreatedAnalyses = this.analyseService.typesAnalyseDTO;
		this.fieldNames = this.intrantService.fieldNames;
		this.typesAnalyseDTO = this.analyseService.typesAnalyseDTO;
		this.analyseNames = this.analyseService.analyseNames;
		this.units = this.analyseService.units;		
		this.categories = this.intrantService.categories;
		this.consistances = this.intrantService.consistances;
		this.sousProduitsAnimaux = this.intrantService.sousProduitsAnimaux;
		this.documentAEmettre = this.intrantService.documentAEmettre;
		this.modeDeLivraison = this.intrantService.modeDeLivraison;
		this.codeDechets = Object.entries(this.intrantService.codeDechets);
		this.cpc = this.cpcService.transformToJson(this.intrant.cpco);
	}

	public addIntrant(){
		this.intrant.modifiedBy = this.loggedUser.email;
		this.intrant.createdBy = this.loggedUser.email;
		this.intrant.dataType = 'intrant';
		this.intrant.createdOn = Date.now();
		this.intrant.modifiedOn = Date.now();
		this.intrant.codeDechet = this.intrant.codeDechet[0];
		this.setAnalyseHeader();
		this.intrant.analyses = this.analyses? <Analyse>this.analyses : null;
		this.intrant.cpco = Object.values(this.cpc);
		this.intrantService.add(this.intrant)
		.subscribe((res : Intrant)=>{
				if(res != undefined && res.id != undefined) 
					this.router.navigate(['/administration/intrants/list']);
		})
	}

	public deleteIntrant(){
		this.intrantService.delete(this.intrant.id)
		.subscribe((res : Intrant)=>{
			if(res !=undefined && res.id != undefined) this.router.navigate(["/administration/intrants/list"]);
		})
	}

	public addAnalyse(){
		if(!this.intrant.analyses){
			this.intrant.analyses = this.analyseService.newAnalyseDTO();
			this.toBeCreatedAnalyses = [];
			this.analyses = {};
			for(let analyse of Object.entries(this.intrant.analyses)){
				if(this.typesAnalyseDTO.includes(analyse[0])){
					if(analyse[1] != null && analyse[1] != undefined){
						//creates the instance with only the right fields
						let analyseDTO = this.analyseService.createTypeAnalyseDTO(analyse[0]);
						//maps the values to the right fields
						this.analyses[analyse[0]]=this.analyseService.toDTO(analyseDTO,analyse[1]);		
					}else{
						this.toBeCreatedAnalyses.push(analyse[0]);				
					}
				}
			}
			this.analyseArray = Object.entries(this.analyses);
		}
	}

	public addTypeAnalyse(analyseName){
		let newAnalyse = this.analyseService.createTypeAnalyseDTO(analyseName);
		this.analyses[analyseName] = newAnalyse;
		this.analyseArray = Object.entries(this.analyses);
		this.toBeCreatedAnalyses = this.toBeCreatedAnalyses.filter(analyse => analyse !== analyseName);
	}

	public deleteTypeAnalyse(analyseName : string ){
		this.analyses[analyseName] = undefined;
		this.analyseArray = Object.entries(this.analyses);
		this.toBeCreatedAnalyses.push(analyseName);
	}

	public deleteAnalyse(){
		this.analyses = {};
		this.analyseArray = [];
		this.intrant.analyses = null;
		this.toBeCreatedAnalyses = this.typesAnalyseDTO;

	}


	public onChange(event : any, analyseName){
		this.intrant.analyses[analyseName] = event;
	}

	public calc(event, cpc){
		if(cpc == "potentielBiogaz"){
			this.calcPM(event);
			this.cpc[cpc].valeur = event;
		}else if(cpc == "potentielMethane"){
			this.calcPB(event);
			this.cpc[cpc].valeur = event;
		}else{
			this.cpc[cpc].valeur = event;
		}
	}

	private calcPB(pm){
		this.cpc["potentielBiogaz"].valeur = this.analyseService.calcPB(pm,this.cpc["tauxCh4"].valeur,this.cpc["matiereOrganique"].valeur);
	}

	private calcPM(pb){
		this.cpc["potentielMethane"].valeur = this.analyseService.calcPM(pb,this.cpc["tauxCh4"].valeur,this.cpc["matiereOrganique"].valeur);
	}

	public setAnalyseHeader(){
		if(this.intrant.analyses){
			this.analyses["typeAnalyse"] = this.intrant.analyses.typeAnalyse;
			if(this.analyses["typeAnalyse"] != "Biblio"){
				this.analyses["siteMetha"] = this.intrant.analyses.siteMetha;
				this.analyses["dateAnalyse"] = this.dateAnalyse.getTime();
			}
		}
	}

	/*------------------HERE IMPORTANT CODE PART ---------------------*/
	public handleClickDatepicker(event : any){
		let element = document.getElementsByTagName("ngb-datepicker")[0];
		let clickedInside = false;
		if(element){
			let el = event.target;
			while(el.parentElement){				
				if(el == element) {
					clickedInside = true;
				}
				el = el.parentElement;
			}
		}
		if(!clickedInside){
			if(this.instanceDate) this.instanceDate.close();
		}
	}

	searchCode = (text$: Observable<string>) =>
		text$
			.debounceTime(200).distinctUntilChanged()
			.merge(this.focusCode$)
			.merge(this.clickCode$.filter(() => !this.instanceCode.isPopupOpen()))
			.map(term => (term === '' ? this.codeDechets : this.codeDechets.filter(
				c => {
					c = String(c);
					c = c.toLowerCase();
					return c.indexOf(term.toLowerCase()) > -1;
					})
			.slice(0, 10)));


	searchMode = (text$: Observable<string>) =>
		text$
			.debounceTime(200).distinctUntilChanged()
			.merge(this.focusMode$)
			.merge(this.clickMode$.filter(() => !this.instanceMode.isPopupOpen()))
			.map(term => (term === '' ? this.modeDeLivraison : this.modeDeLivraison.filter((m : string) => m.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10));


}
