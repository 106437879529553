import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class WelcomeService {

	private welcomeModeSource = new Subject<boolean>();

	public welcomeMode$ = this.welcomeModeSource.asObservable();

	public startTutorial(){
		this.welcomeModeSource.next(true);
	}

  constructor() { }

}
