import { BiogasviewDocument } from 'global/models/BiogasviewDocument';
import { Adresse } from './adresse';
import { Site } from './site';
import { Bloc } from './bloc';
import { BiogasviewUtilisateur } from './BiogasviewUtilisateur';
import { Organisation } from './organisation';

export class Utilisateur extends BiogasviewUtilisateur{

	organisations: Organisation[] = new Array<Organisation>();

	constructor(){
		super();
		this.dataType = 'utilisateur';
	}
}