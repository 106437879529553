import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'toggle-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
    @Input('headers') headers;
    @Input('legende') legende;
    @Input('rows') rows;
    @Input('leftStyleType') leftStyleType;
    @Input('rightStyleType') rightStyleType;
    @Input('firstStyleType') firstStyleType;
    @Input('highlightedColumn') highlightedColumn;
    @Input('totalToggleRowRight') totalToggleRowRight;
    @Input('min') min;
    @Input('totalColumn') totalColumn : boolean = true;
    @Output() valueChange = new EventEmitter();
    @Output() rowClicked = new EventEmitter();
    cellsNumber : number;
    toggled : boolean;

  constructor() {

  }

  ngOnInit() {
      this.cellsNumber = this.legende.length;
  }

  rowChanged(row, index){
      this.valueChange.emit([row,index]);
  }

  rowClickedFunction(row){
      this.rowClicked.emit(row);
  }

  toggleRows(toggle : boolean){
      this.toggled = toggle;
  }

  // $(document).ready(function(){
  //   $(".toggle-table").floatThead({scrollingTop:50});
  // });

}
