import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from 'app/services/login/login.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  public username: string;
  public password: string;
  public remember: boolean;
  public showMessage: boolean;
  public loginMessage: string;
  private onLoad = false;

  constructor(private loginService: LoginService, private router: Router) {
  }

  ngOnInit() {
  }

  public signIn() {
    this.onLoad = true;
    this.loginService.login(this.username, this.password)
      .subscribe((token) => {
        if (token != null && token.accessToken) {
          this.loginService.user()
            .subscribe(user => {
              if (user !== undefined) {
                this.showMessage = true;
                this.loginMessage = 'Authentification réussie.';
                this.router.navigate(['administration/organisation/list']);
              } else {
                this.showMessage = true;
                this.loginMessage = 'Impossible de créer une session. Veuillez récommencer';
              }
            });
        } else {
          this.showMessage = true;
          this.loginMessage = 'Adresse email/Mot de passe Incorrect.';
        }
        this.onLoad = false;
      });
  }

}
