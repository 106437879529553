export class Module{
	id: string;
	label: string;
	readWrite: DroitAcces;
}

export enum DroitAcces {
	ReadOnly = 0,
	ReadWrite = 1
}
