import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver/FileSaver';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';

import { BanqueModalComponent } from 'administration/components/banque-modal/banque-modal.component';
import { ConfirmationGuardService } from 'global/services/confirmation-guard/confirmation-guard.service';
import { AttachementModalComponent } from 'administration/components/organisation/site/attachement-modal/attachement-modal.component';
import { BreadcrumbService } from 'global/services/breadcrumb/breadcrumb.service';
import { SiteService } from 'administration/services/site/site.service';
import { LoginService } from 'app/services/login/login.service';
import { ParametresService } from 'global/services/parametres/parametres.service';
import { ParametresApplication } from 'administration/models/parametres-application';
import { Site } from 'administration/models/site';
import { Administrateur } from 'administration/models/administrateur';
import { Organisation } from 'administration/models/organisation';
import { OrganisationService } from 'administration/services/organisation/organisation.service'

@Component({
  selector: 'app-site-add',
  templateUrl: './site-add.component.html',
  styleUrls: ['./site-add.component.scss']
})
export class SiteAddComponent implements OnInit {
	site: Site;
  loggedUser: any;
  orga : any = {};
  parametres: any[];
  invalidSubmitted: boolean = false;
  attachements: any[] = [];
  attachementToAdd: boolean = false;
  attachementSize: number = 0;
  loading: boolean = false;
  loadingMessage: string = '';
  codeSiteExists: boolean;
  contactFacturationEmails: any[] = [];
  columnListBanque: any[] = [];
  selectedBanques: any[];
  selectedNegociantBanques: any[];

  constructor(
    private siteService: SiteService,
    private router: Router,
    private route : ActivatedRoute,
    private datePipe: DatePipe,
    private appConfigService: ParametresService,
    private loginService: LoginService,
    public domSanitizer: DomSanitizer,
    private organisationService : OrganisationService,
    private breadcrumbService: BreadcrumbService,
    private modalService: NgbModal,
    private confirmationGuardService: ConfirmationGuardService
  ) {
  	this.site = new Site();
    this.site.adresse.pays = 'France';
    this.site.porteurProjet.adresse.pays = "France";
    this.site.installateur.adresse.pays = "France";
    this.site.proprietaire.adresse.pays = "France";
    this.site.dataType = "site";
    this.site.attachments = {};
    this.domSanitizer.bypassSecurityTrustHtml("mmmm");
    this.constructBanqueColumnList();
  }

  ngOnInit() {
    this.loggedUser = this.loginService.getLoggedUser();
    this.parametres = this.appConfigService.getParametresApplication();
    this.organisationService.get(this.route.snapshot.paramMap.get('idOrga'))
      .subscribe(res =>{
        if(res != null && res != undefined){
          this.orga = res;
          this.site.organisation = res;
          this.breadcrumbService.add({label : 'Nouveau site', route : this.router.routerState.snapshot.url});
        }
      })
  }

  siteNomChanged(){

  }

  showAttachment(att: any){
    if(att && att.file){
      saveAs(att.file, att.name);
    }
  }

  addAttachment(){
    const modal = this.modalService.open(AttachementModalComponent,{size: 'lg'});
    modal.componentInstance.attachements = this.attachements;
    modal.result
      .then(result => {
        let user = JSON.parse(sessionStorage.getItem('loggedUser'));
        if(this.attachementSize/1000000 <= 2 && this.attachements.length+1 <= 2){
          this.attachementToAdd = this.attachementToAdd ? this.attachementToAdd : !this.attachementToAdd;
          let help = result.name.split('.');
          let nameCount = this.attachements.filter( att => att.name.split('.')[0].split('(')[0] == help[0]).length;
          result.name = nameCount ?  help[0]+'('+nameCount+').'+help[1] : result.name;
          result.createdBy = user.principal.username;
          this.attachements.push(result);
        }else{
          this.confirmationGuardService
            .alert("Les pièces jointes sont au plus 2 et ne peuvent pas dépasser 2 Mo")
        }
      })
      .catch(error => console.log(error));
  }

  deleteAttachment(index: number){
    this.attachements.splice(index,1);
  }

  submit(formValid: boolean){
    formValid ? this.addSite() : this.invalidSubmitted = true;
  }

  updateNegociantBanque(event) {
    const modalRef = this.modalService.open(BanqueModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.banque = this.site.negociant.compteBancaires[event.index];
    modalRef.componentInstance.title = "Modification d'une banque ";
    modalRef.componentInstance.banques = this.site.negociant.compteBancaires;
  }

  negociantBanqueSelected(event) {
    this.selectedNegociantBanques = event.length ? event : undefined;
  }

  deleteNegociantBanques(){
    if(this.selectedNegociantBanques)
      this.selectedNegociantBanques.forEach((value, index) =>{
        this.site.negociant.compteBancaires.splice(value.index-index,1);
      })
      this.selectedNegociantBanques = [];
  }

  openAddNegociantBanqueModal(){
    const modalRef = this.modalService.open(BanqueModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.title = "Ajout d'une banque";
    modalRef.componentInstance.banques = this.site.negociant.compteBancaires;
    modalRef.result
      .then(result => {
        this.site.negociant.compteBancaires.push(result);
      })
      .catch(error => console.error(error))
  }

  updateBanque(event) {
    const modalRef = this.modalService.open(BanqueModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.banque = this.site.porteurProjet.compteBancaires[event.index];
    modalRef.componentInstance.title = "Modification d'une banque ";
    modalRef.componentInstance.banques = this.site.porteurProjet.compteBancaires;
  }

  banqueSelected(event) {
    this.selectedBanques = event.length ? event : undefined;
  }

  deleteBanques(){
    if(this.selectedBanques)
      this.selectedBanques.forEach((value, index) =>{
        this.site.porteurProjet.compteBancaires.splice(value.index-index,1);
      })
      this.selectedBanques = [];
  }

  openAddBanqueModal(){
    const modalRef = this.modalService.open(BanqueModalComponent, {size: 'lg', backdrop: 'static'});
    modalRef.componentInstance.title = "Ajout d'une banque";
    modalRef.componentInstance.banques = this.site.porteurProjet.compteBancaires;
    modalRef.result
      .then(result => {
        this.site.porteurProjet.compteBancaires.push(result);
      })
      .catch(error => console.error(error))
  }

  /**
  * Add site
  * Format dates
  */
  addSite() {
    this.site.createdBy = this.loggedUser.name;
    this.site.modifiedBy = this.loggedUser.name;
    this.site.createdOn = Date.now();
    this.site.modifiedOn = Date.now();
    this.site.dateCreation = new Date();
    if(this.contactFacturationEmails.length > 0) {
      this.site.porteurProjet.contactFacturation.email = this.contactFacturationEmails
        .map(dest => dest.value ? dest.value : dest)
        .reduce((a, b) => `${a}, ${b}`);
    }
    this.transformSiteDates();
    this.loadingMessage = 'Ajout du site: '+this.site.nom;
    this.loading = true;
  	this.siteService.add(this.site, this.attachements)
  		.subscribe((res: any)=> {
  			if((res != null && res.id != undefined && res.revision != undefined) || res.type === HttpEventType.Response){
          this.breadcrumbService.removeFrom(-2);
					this.router.navigate(['administration/organisation/',this.orga.id]);
				}
  		})
  }

  onBlur(){
    this.siteService.checkIfExists(this.site)
      .subscribe(res => {
        this.codeSiteExists = res && res.id != this.site.id ? true : false;
      })
  }

  transformSiteDates(){
    this.site.dateCreation && this.site.dateCreation!=0? this.site.dateCreation = this.site.dateCreation.getTime() : this.site.dateCreation = null;
    this.site.dateMonitoring && this.site.dateMonitoring!=0? this.site.dateMonitoring = this.site.dateMonitoring.getTime() : this.site.dateMonitoring = null;
    this.site.dateRaccordement && this.site.dateRaccordement!=0? this.site.dateRaccordement = this.site.dateRaccordement.getTime() : this.site.dateRaccordement = null;
  }

  public errorMessages = {
    'emailValidator': 'les adresses mails sont uniquement acceptées'
  };

  get validators(){
    return [this.emailValidator];
  }

  public emailValidator(control: FormControl) {
    const regex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");
    if(!regex.test(control.value)) {
      return {
        emailValidator: true
      };
    }
    return null;
  }

  private constructBanqueColumnList(){
    this.columnListBanque = [];
    for(let header of this.siteService.banqueHeaders()){
      let col : any = {};
      col.dataKey = header.nom;
      col.name = header.label;
      col.excluded = true;
      this.columnListBanque.push(col);
    }
  }

}
