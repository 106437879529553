import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../services/DataService';
import { PaginationService } from '../services/PaginationService';
import { SortService } from '../services/SortService';
import { ColumnSelectorService } from '../services/ColumnSelectorService';
import { FilterService } from '../services/FilterService';
import { ITableHeader } from '../interfaces/ITableHeader';
import { IPagination } from '../interfaces/IPagination';
import { ITableFooter } from '../interfaces/ITableFooter';
import { IRow } from '../interfaces/IRow';
import { IColumn } from '../interfaces/IColumn';
import * as _ from 'lodash';

export { AlignRule } from '../enums/align-rule';

@Component({
	selector: 'data-table',
  templateUrl: '../views/table.html',
  providers: [ DataService, PaginationService, SortService, ColumnSelectorService, FilterService]
})
export class TableComponent implements OnInit{
  @Input('all-row-selected') allRowSelected: boolean;
	@Input('table-row-id') tableRowId: any;
	@Input('columns') columns: any;
  @Input('selected-rows') selectedRows: any;
	@Input('rows') rows: any;
	@Input('table-header') tableHeader: ITableHeader;
	@Input('pagination') pagination: IPagination;
	@Input('sortable-columns') sortableColumns: boolean;
	@Input('selectable-columns') selectableColumns: boolean;
	@Input('selectable-rows') selectableRows: boolean;
	@Input('clickable-row') clickableRow : boolean;
	@Output('onrowselect') onRowSelect: EventEmitter<any> = new EventEmitter();
	@Output('onchangepage') onChangePage: EventEmitter<any> = new EventEmitter();
	@Output('oncolumnselect') onColumnSelect : EventEmitter<any> = new EventEmitter();
	@Output('onrowclick') onRowClick : EventEmitter<any> = new EventEmitter();

	private oldRows = [];

  tableFooter: ITableFooter;
  constructor(
  	protected dataService: DataService,
    protected arrayPaginationService: PaginationService,
    protected sortService: SortService,
	  private ref : ChangeDetectorRef
  ){

  }

  ngOnInit(){
    if(this.allRowSelected == undefined) this.allRowSelected = false;
    this.dataService.setRowId(this.tableRowId || 'id');
    this.dataService.removeColumns();
    this.dataService.addColumns(this.columns);
    this.dataService.addRows(this.rows);
    this.dataService.setSelectedRows(this.selectedRows||[]);
    this.dataService.setRowSelectionEnabled(this.selectableRows);
    this.sortService.setSortingEnabled(this.sortableColumns);
    this.tableFooter = {
      pagination: this.pagination || <IPagination>{}
    };
  }

  // ngAfterContentChecked(){
	//   this.ref.detectChanges();
  // }

  ngOnChanges(changes: SimpleChanges){
    if(changes.columns) {
  		const columnChange: SimpleChange = changes.columns.currentValue;
  		if(columnChange != undefined){
	      this.dataService.removeColumns();
	      this.dataService.addColumns(this.columns);
	    }
	  }
    if(changes.rows) {
  		const change: SimpleChange = changes.rows.currentValue;
  		if( change != undefined){
	      this.dataService.addRows(this.rows);
  		  this.oldRows = _.cloneDeep(this.rows);
      }
	  }
    if(changes.selectedRows) {
      const change: SimpleChange = changes.selectedRows.currentValue;
      if( change != undefined){
        this.dataService.setSelectedRows(this.selectedRows);
      }
    }
  }

  ngDoCheck(){
	  if(!_.isEqual(this.oldRows, this.rows)){
		  this.oldRows = _.cloneDeep(this.rows);
		  this.dataService.addRows(this.rows);
	  }
  }

  rowSelected(rows: Array<IRow>){
    if(this.selectableRows) this.onRowSelect.emit(rows);
  }

  rowClicked(row : IRow){
    if(this.clickableRow) this.onRowClick.emit(row);
  }

  columnSelected(cols : Array<IColumn>){
    if(this.selectableColumns) this.onColumnSelect.emit(cols);
  }

  pageChanged(newPage: any){
    if(this.onChangePage) this.onChangePage.emit(newPage);
  }
}
