import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class NavigationService {

  activeItems : boolean[];
  private _activeItemsSource = new BehaviorSubject(new Array<boolean>());
  public activeItems$ = this._activeItemsSource.asObservable();
  _oldSubNavs;
  _subNavs;

  constructor() { }
  
  resetActiveItems(){
    let activeItems = new Array(this._subNavs.length);
    activeItems.forEach(item => item = false);
    this._activeItemsSource.next(activeItems);
  }

  initialiseActiveItems(){
    let activeItems = new Array(this._subNavs.length);
    activeItems.forEach(item => item = false);
    activeItems[0] = true;
    this._activeItemsSource.next(activeItems);
  }

  setActive(index : number){
    let activeItems = new Array(this._subNavs.length);
    activeItems.forEach(item => item = false);
    activeItems[index] = true;
    this._activeItemsSource.next(activeItems);
  }

  set subNavs(subNavs){
    this._subNavs = subNavs;
    if(!_.isEqual(this._oldSubNavs, this._subNavs)){
      this.initialiseActiveItems();
      this._oldSubNavs = _.cloneDeep(this._subNavs);
    }
  }

  get subNavs(){
    return this._subNavs;
  }
}
