import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError, tap } from 'rxjs/operators';

import { Administrateur } from 'administration/models/administrateur';


@Injectable()
export class LoginService {

  constructor(private http: HttpClient) { }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
	private handleError<T> (operation = 'operation', result?: T) {
	  return (error: any): Observable<T> => {

	    // TODO: send the error to remote logging infrastructure
	    console.error(error); // log to console instead



	    // Let the app keep running by returning an empty result.
	    return of(result as T);
	  };
	}

  public login(username: string,password: string): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      })
    };
  	return this.http.post<any>(environment.api+'/login', {usernameOrEmail: username, password: password}, httpOptions)
  		.pipe(
        tap(jwtoken => this.setSession(jwtoken)),
  			catchError(this.handleError<any>('login'))
			)
  }

  public logout(): Observable<any>{
    return this.http.post(environment.api+'/logout', {})
      .pipe(
        catchError(this.handleError<any>('login'))
      );
  }

  public user(): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json'
      })
    };
  	return this.http.get<any>(environment.api+'/user',httpOptions)
  		.pipe(
        tap(user => this.setLoggedUser(user)),
  			catchError(this.handleError<any>('session'))
			)
  }
  /**
  * Get logged user
  * @return logged user
  */
  public getLoggedUser(): any{
  	return JSON.parse(sessionStorage.getItem("loggedUser"))
  }

  /**
  * Clear session data;
  */
  public resetLoggedUser(){
    sessionStorage.clear();
  }

  public setLoggedUser(user: any) {
    sessionStorage.setItem("loggedUser",JSON.stringify(user));
  }

  private setSession(jwtResponse: any) {
    sessionStorage.setItem('expireAt', jwtResponse.expiration);
    sessionStorage.setItem('token', jwtResponse.accessToken)
  }

}
