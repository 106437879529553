import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { environment } from 'environments/environment';

import { Log } from 'configuration/models/log';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Access-Control-Allow-Origin':'*'
  })
};

@Injectable()
export class LogService {

  constructor(
  	private httpClient: HttpClient
	) { }

	public list(): Observable<Log[]>{
		return this.httpClient.get<Log[]>(environment.api+"log/list",httpOptions)
			.pipe(
				catchError(this.handleError<Log[]>('log'))
			)
	}

	 /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
	private handleError<T> (operation = 'operation', result?: T) {
	  return (error: any): Observable<T> => {

	    // TODO: send the error to remote logging infrastructure
	    console.error(error); // log to console instead

	    

	    // Let the app keep running by returning an empty result.
	    return of(result as T);
	  };
	}

}