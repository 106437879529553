import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableNumberFixed'
})
export class TableNumberFixedPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return isNaN(parseFloat(value)) || isNaN(parseInt(args)) ? value : new Intl.NumberFormat('fr').format(value.toFixed(args));
  }

}
