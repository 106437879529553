import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[close-mobile]'
})
export class CloseMobileDirective {

  constructor(private el: ElementRef) { }

  get element(){
		return this.el;
	}

}
