import { Directive, ElementRef, Renderer2, ContentChild, AfterViewInit } from '@angular/core';
import { NavSidebarDirective } from './../nav-sidebar/nav-sidebar.directive';
import { MobileTogglerDirective } from './../mobile-toggler/mobile-toggler.directive';

@Directive({
	selector: '[page-layout]'
})
export class PageLayoutDirective implements AfterViewInit{

	@ContentChild(NavSidebarDirective) sideNav: NavSidebarDirective;
	@ContentChild(MobileTogglerDirective) mobileToggler: MobileTogglerDirective;

	constructor(
		private el: ElementRef,
		private renderer: Renderer2
	){

	}

	ngAfterViewInit(){
		if(this.sideNav != undefined && this.sideNav.toggler){
			// toggle
			if(this.sideNav.toggler != undefined){
				this.renderer.listen(this.sideNav.toggler.element.nativeElement,'click',(event) =>{
					this.toggleClass(this.sideNav.element,'sidebar-collapsed-desktop');
					this.toggleClass(this.el,'page-with-icon-sidebar');
				})
			}
		}
		if(this.mobileToggler != undefined){
			this.renderer.listen(this.mobileToggler.element.nativeElement,'click',(event) =>{
				this.renderer.addClass(this.sideNav.element.nativeElement,'sidebar-expanded-mobile');
			})
		}
	}

	/**
	* toggle css class to element
	* @param element 
	* @param csClass 
	*/
	private toggleClass(element: ElementRef, cssClass: string){
		if(this.hasClass(element.nativeElement.classList,cssClass)) 
			this.renderer.removeClass(element.nativeElement,cssClass);
		else this.renderer.addClass(element.nativeElement,cssClass);
	}
	/**
	* check if element has a css class
	* @param classList - element class list
	* @param csClass - css class to find
	*/
	private hasClass(classList: DOMTokenList, cssClass: string): boolean{
		return classList.contains(cssClass);
	}
}
