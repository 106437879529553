import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import { ParametresService } from 'global/services/parametres/parametres.service';
import { DroitAcces } from 'administration/models/module';

@Component({
  selector: 'app-bloc-access-modal',
  templateUrl: './bloc-access-modal.component.html',
  styleUrls: ['./bloc-access-modal.component.scss']
})
export class BlocAccessModalComponent implements OnInit {
	blocs: any[];
  @Input()  cbest: false;
	@Input() bloc: any;
	@Input() modules: any[];
	accessValue: any;
  parametres;
  constructor(
  	private _activeModal: NgbActiveModal,
  	private parametresService: ParametresService
	) { }

  async ngOnInit() {
  	this.accessValue = [
  		{label: 'Lecture', value: DroitAcces.ReadOnly},
      {label: 'Ecriture', value: DroitAcces.ReadWrite},
      {label: 'Aucun', value: -1}
    ];

    this.parametres = this.parametresService.getParametresApplication();
    this.blocs = this.parametres.blocs.map(bloc => {
      return {
          id: bloc.id,
        label: bloc.label,
        modules: bloc.modules
        };
    });

    if(this.bloc && this.bloc.id === 'performances'){
      this.accessValue = [
    		{label:'Pas accès', value: DroitAcces.ReadOnly},
        {label:'Accès', value: DroitAcces.ReadWrite},
    	]
    }

  	if(this.bloc && this.modules){
  		let help = this.parametres.blocs.filter(bloc => bloc.id == this.bloc.id)
  		if(help.length) {
  			help[0].modules = help[0].modules.map(module =>{
	  			module.readWrite = 1;
	  			return module;
	  		});
	  		this.modules = this.modules.concat(help[0].modules);
	  		this.modules = _.uniqBy(this.modules,'id');
  		}
  	}
  }

  blocChanged(){
  	if(this.bloc){
  		let help = this.parametres.blocs.filter(bloc => bloc.id == this.bloc.id)
  		if(help.length) this.modules = help[0].modules.map(module =>{
  			module.readWrite = 1;
  			return module;
  		});
  	}
    if(this.bloc.id === 'performances'){
      this.accessValue = [
    		{label:'Pas accès', value: DroitAcces.ReadOnly},
        {label:'Accès', value: DroitAcces.ReadWrite},
    	]
    } else {
      this.accessValue = [
        {label:'Lecture', value: DroitAcces.ReadOnly},
        {label:'Ecriture', value: DroitAcces.ReadWrite},
        {label:"Aucun", value: -1}
      ]
    }
  }

  save(){
  	this.modules = this.modules.filter(module => module.readWrite >= 0);
  	let bloc = Object.assign({}, this.bloc);
    if(this.modules.length){
      this.modules  = this.modules.map(mod => {
        if(mod.children) mod.children = mod.children.map(child =>{child.readWrite = mod.readWrite; return child;})
        return mod;
      })
      bloc.modules = this.modules;
      this._activeModal.close(bloc);
    }
    else this._activeModal.close({});
  }

  close(){
  	this._activeModal.dismiss('dismiss');
  }

}
