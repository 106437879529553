import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';

export function PatternValidator(regex: RegExp): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
		const test = regex.test(control.value);
    return !test ? {'pattern': {value: control.value}} : null;
  };
}

@Directive({
	selector: '[appPattern]',
	providers: [{provide: NG_VALIDATORS, useExisting: PatternValidatorDirective, multi: true}]
})
export class PatternValidatorDirective{
	@Input('appPattern') pattern: string;
	validate(control: AbstractControl): {[key: string]: any} {
    return control.value ? PatternValidator(new RegExp(this.pattern,'i'))(control) : null;
  }

}
