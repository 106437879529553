import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConnexionUtilisateur } from 'administration/models/connexion-utilisateur';
import { ConnexionUtilisateurService } from 'administration/services/connexion-utilisateur/connexion-utilisateur.service';
import { BreadcrumbService } from 'global/services/breadcrumb/breadcrumb.service';
import { ConfirmationGuardService } from 'global/services/confirmation-guard/confirmation-guard.service';
import { CustomDatepickerI18n } from 'global/services/custom-date-picker-i18n/custom-date-picker-i18n.service';
import * as _ from 'lodash';



@Component({
  selector: 'app-connexions-utilisateur',
  templateUrl: './connexions-utilisateur.component.html',
  styleUrls: ['./connexions-utilisateur.component.scss']
})
export class ConnexionsUtilisateurComponent implements OnInit {

	connexions : ConnexionUtilisateur[] = [];
	filteredConnexions : ConnexionUtilisateur[] = [];
  headers : any[] = new Array<any>();
  view: string = 'table';
  columnList: any[] = [];
  loading: boolean = false;
  loadingMessage: string = '';

  constructor(
    private router : Router, 
    private connexionService : ConnexionUtilisateurService,
    private confirmationGuardService: ConfirmationGuardService,
    private breadcrumbService: BreadcrumbService,
    private customDatePickerService: CustomDatepickerI18n
  ) { }

  ngOnInit() {
    this.breadcrumbService.add({label : "Connexions utilisateur", route : this.router.routerState.snapshot.url});
  	this.fetchData();
    this.headers = this.connexionService.headers;
    this.constructColumnList();  	
  }

  getCardValue(recep : ConnexionUtilisateur, key : string){
    return _.get(recep,key);
  }

  searchTermChanged(term : string){
    this.filteredConnexions = this.connexions.filter(tier => {
      for(let header of this.headers){
        let value = _.get(tier,header.nom);
        if(value && value.toString().toLowerCase().includes(term.toLowerCase())) return true;
      }
      return false;
    })
    return this.filteredConnexions;
  }

  toggleView(view : string){
    this.view = view;
  }

  private fetchData(){
    this.loading = true;
    this.loadingMessage = 'Chargement de la liste des connexions';
    this.connexionService.list()
    .subscribe((connexions: ConnexionUtilisateur[])=>{
      if(connexions && !connexions[0].id){
      	connexions = [];
      }
      this.connexions = connexions.map(connexion => {
      	connexion.dateConnexionString = this.customDatePickerService.getDateAndTimeFromModel(connexion.dateConnexion);
      	return connexion;
      });
      //this.connexions = connexions.filter(connexion => connexion.id);
      this.filteredConnexions = this.connexions;
      this.loading = false;
      
    })
  }

  private constructColumnList(){
    this.columnList = [];
    for(let header of this.headers){
      let col : any = {};
      col.dataKey = header.nom;
      col.name = header.label;
      col.excluded = true;
      this.columnList.push(col);
    }
  }

}
