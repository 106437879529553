import { Component, OnInit, ViewChild } from '@angular/core';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';


import { BreadcrumbService } from 'global/services/breadcrumb/breadcrumb.service';
import { LoginService } from 'app/services/login/login.service';
import { WelcomeService } from 'app/services/welcome/welcome.service';
import { Administrateur } from 'administration/models/administrateur';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	
  modules: any;
  moduleName: string = 'BG Admin';

  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService 
  ) { 
    
  }

  ngOnInit() {
    this.breadcrumbService.add({label : 'Home', route : 'administration/organisation/list'});
    this.modules = [
      ['Organisation','organisation'],
      ['Utilisateurs externes','user'],
      ['Intrants','intrant'],
      ['Indexs facturation','index-facturation'],
      ['Log','log'],
      ['Connexions utilisateur','connexions-utilisateur']
    ]
  }
}
