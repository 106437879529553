import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: '[toggle-table-body]',
  templateUrl: './table-body.component.html',
  styleUrls: ['./table-body.component.scss']
})
export class TableBodyComponent implements OnInit {

    @Input('row') row;
    @Input('leftStyleType') leftStyleType;
    @Input('rightStyleType') rightStyleType;
    @Input('firstStyleType') firstStyleType;
    @Input('min') min;
    @Output() rowChanged = new EventEmitter();
    @Output() rowClicked = new EventEmitter();
    @Input('highlightedColumn') highlightedColumn;
    @Input('totalToggleRowRight') totalToggleRowRight;
    @Input('totalColumn') totalColumn : boolean;

    private toggleRow = [];
    private total;
    private totalRowLeft;
    private totalRowRight;
    @Input() toggled = false;
    private debouncer: Subject<any> = new Subject();
    private cellsNumber : number;

    /**
    *row : {
    * firstRow : []
    * toggleRowLeft : []
    * toggleRowRight : []
    * toggled : boolean
    * source : any but with 'nom' attribute
    *}
    */

  constructor() {
      this.debouncer
        .pipe(debounceTime(3000))
        .subscribe((val) => this.rowChanged.emit(val));
   }

  ngOnInit() {
      if(this.row.firstRow && this.row.toggleRowLeft && this.row.toggleRowLeft.length) this.cellsNumber = 2;
      if(this.row.toggleRowRight) this.cellsNumber +=1;
  }

  ngOnChanges(changes : SimpleChanges){
      if(changes.row){
          if(changes.row.currentValue.firstRow &&  changes.row.currentValue.toggleRowLeft && changes.row.currentValue.toggleRowLeft.length) this.cellsNumber = 2;
          if(changes.row.currentValue.toggleRowRight) this.cellsNumber +=1;
          this.calculateTotal(changes.row.currentValue);
          this.calculateToggleRow(changes.row.currentValue);
          this.toggled = changes.row.currentValue.toggled;
      }
      if(changes.toggled && changes.toggled.currentValue != undefined && this.row.toggleRowLeft && this.row.toggleRowLeft.length){
          this.toggled = changes.toggled.currentValue;
          this.row.toggled = changes.toggled.currentValue;
      }
  }

  calculateTotal(row : any){
    let total = 0;
    row.firstRow.forEach(cell => total += cell.value);
    this.total = total*10/10;
    let totalLeft = 0;
    if(row.toggleRowLeft) row.toggleRowLeft.forEach(cell => totalLeft += cell.value);
    this.totalRowLeft = totalLeft*10/10;
    let totalRight = 0;
    if(row.toggleRowRight) row.toggleRowRight.forEach(cell => totalRight += cell.value);
    this.totalRowRight = this.totalToggleRowRight? totalRight*10/10 : '-';

  }

  calculateToggleRow(row : any){
      let toggleRow = [];
      for(let i = 0; i < row.firstRow.length; i++){
          if(this.cellsNumber == 3){
              row.toggleRowLeft[i].class += " left "+ this.leftStyleType;
              toggleRow.push(row.toggleRowLeft[i]);
              row.toggleRowRight[i].class += " right " + this.rightStyleType;
              toggleRow.push(row.toggleRowRight[i]);
          } else if(this.cellsNumber == 2){
              row.toggleRowLeft[i].class += " full "+ this.leftStyleType;
              toggleRow.push(row.toggleRowLeft[i])
          }
      }
      this.toggleRow = toggleRow;
  }

  toggle(){
      if(this.row.toggleRowLeft && this.row.toggleRowLeft.length ) this.row.toggled = !this.row.toggled;
  }

  rowValueChanged(){
    this.calculateTotal(this.row);
    this.debouncer.next(this.row);
  }

  rowClickedFunction(){
    this.rowClicked.emit(this.row);
  }

  validateInput(event : any){
    let char = event.which || event.keyCode;
    char = String.fromCharCode(char);
    if(this.min == 0 && !/[0-9.,]/.test(char)){
      event.preventDefault();
    }
  }



}
