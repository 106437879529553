import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
@Component({
  selector: 'app-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.scss']
})
export class PasswordModalComponent implements OnInit {

	@Input() user: any;
	confirmPassword: any;
  password: any;
	invalidSubmitted: boolean = false;


  constructor(
  	private _activeModal: NgbActiveModal,
    private _router: Router
	) { }

  ngOnInit() {
  }

  public passwordConfirmChanged(){
    if(this.password != this.confirmPassword) this.invalidSubmitted = true;
    else this.invalidSubmitted = false;
  }

  save(){
  	this._activeModal.close({password: this.password, email: this.user.email});
    this._router.navigate(["/administration/organisation"]);
  }

  close(){
    this._activeModal.dismiss('dismiss');
  }

}
