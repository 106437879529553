import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';
import { Attachment } from 'administration/models/attachment';

@Injectable()
export class AttachmentService {

  constructor(
  	private httpClient: HttpClient,
  	private router : Router
	) { }

	 /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
	private handleError<T> (operation = 'operation', result?: T) {
	  return (error: any): Observable<T> => {
	  	if(error.status == 401 && error.statusText == 'OK'){
	        this.router.navigate(['signin']);
	    }
	    // TODO: send the error to remote logging infrastructure
	    console.error(error); // log to console instead

	    

	    // Let the app keep running by returning an empty result.
	    return of(result as T);
	  };
	}

	/**
	* List all attachment by document
	*/
	public list(id: string): Observable<Attachment[]>{
		const httpOptions = {
		  headers: new HttpHeaders({
		  	'Content-type': 'application/json',
		    'Access-Control-Allow-Origin':'*'
		  })
		};
		return this.httpClient.get<Attachment[]>(environment.api+'/attachment/list/'+id,httpOptions)
  		.pipe(
  			catchError(this.handleError<Attachment[]>('attachment-list'))
			)
	}

	/**
	* List all attachment by document
	*/
	public getDocument(id: string){
		const req = new HttpRequest('GET', environment.api+'/attachment/'+id,null,{
      responseType: 'blob'
    });
		/// TS_IGNORE
		return this.httpClient.request(req)
		.pipe(
			catchError(this.handleError<any>('attachment'))
		)
	}

	/**
	* Delete a document
	* @param id - document id
	*/
	public delete(id: string){
		return this.httpClient.delete<any>(environment.api+'/attachment/'+id)
			.pipe(
				catchError(this.handleError<any>('attachment-delete'))
			)
	}

}
