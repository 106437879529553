import { Injectable } from '@angular/core';

import { Intrant } from 'administration/models/intrant';
import * as XLSX from 'xlsx';
import { IntrantService } from 'administration/services/intrant/intrant.service';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class FileService {

  constructor(private intrantService : IntrantService) { }

  public readFileToIntrantArray(event) {
  	let file = event.target.files[0];
  	let arrayBuffer: any;
		let fileType;
		let csv;
		let workbook;
		let first_sheet_name;
		let worksheet;
		let intrants : Intrant[] = [];
		let complexObject;
		let rawIntrants;
		let headers : string[] = [];
		//trying to recognize the type of file (accepts csv, xlsx, xls)
		if(file.name.indexOf(".csv")>0){
		  fileType = "csv";
		}else if(file.name.indexOf(".xls")>0){
		  fileType = "xls";
		}else{
		  console.log("Type not recognized and error not handled");
		}
		let fileReader = new FileReader();
		fileReader.readAsArrayBuffer(file);
		//we need this as observable (FileReader)
		return Observable.create( observer =>{
			fileReader.onload = (e) => {
				//the type of arrayBuffer relies on the readAsArrayBuffer (or readAsDataUrl...) operation
				arrayBuffer = fileReader.result;
				var data = new Uint8Array(arrayBuffer);
				var arr = new Array();
				for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
				//either a proper string if file is csv, or gibberish if xls
				var bstr = arr.join("");
				switch (fileType) {
				  case "csv":
				    //harminize the numbers format
				  	bstr = bstr.replace(/,/g,".");
				  	bstr = bstr.replace(/;/g,",");
				  	//build the json and cast into intrant array
				  	complexObject = <any>this.intrantService.csvToJson(bstr,",");
					complexObject.intrants.forEach(intrant =>{
						intrants.push(this.intrantService.mapPlainJsonToIntrant(JSON.stringify(intrant)));
					})
					rawIntrants = complexObject.rawIntrants;
					for(let value in rawIntrants[0]) headers.push(value);
					intrants = intrants.filter(intrant=>intrant.nom);
					rawIntrants = rawIntrants.filter(intrant=>intrant.nom);
					observer.next({intrants,headers,rawIntrants});
					observer.complete();
					break;
				  case "xls":
				  	workbook = XLSX.read(bstr, {type:"binary"});
					first_sheet_name = workbook.SheetNames[0];
					worksheet = workbook.Sheets[first_sheet_name];
					csv = XLSX.utils.sheet_to_csv(worksheet);
					complexObject = <any>this.intrantService.csvToJson(csv,",");

					complexObject.intrants.forEach(intrant =>{
						intrants.push(this.intrantService.mapPlainJsonToIntrant(JSON.stringify(intrant)));
					})
					rawIntrants = complexObject.rawIntrants;
					for(let value in rawIntrants[0]) headers.push(value);
					intrants = intrants.filter(intrant=>intrant.nom);
					rawIntrants = rawIntrants.filter(intrant=>intrant.nom);
					observer.next({intrants,headers,rawIntrants});
					observer.complete();
					break;
					default:
					// code...
					break;
				}
				
			}
		})	
  }


}
