import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { Observable } from 'rxjs/Observable';

import { Organisation } from 'administration/models/organisation';
import { Site } from 'administration/models/site';
import { UtilisateurOrganisation } from 'administration/models/utilisateur-organisation';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Access-Control-Allow-Origin':'*'
  })
};


@Injectable()
export class OrganisationService{
  private _headers: any[];

  constructor(
  	private http : HttpClient,
    private router : Router) {
    let organisation = new Organisation();
  	organisation.nom = "EvilCorp";
  	organisation.id = "organisation_ea04aee6-e3fa-41eb-bf1d-6790121e8901";
  	organisation.dataType = "organisation";
  	organisation.createdBy = "vincent.cotineau@biogasview.com";

    this._headers = [
      {nom:"nom",label:"Nom"},
      {nom:"database",label:"Base de données"},
      {nom: "sites.length", label: "Nombre de sites"}
    ]
   }

  public get headers(){
    return this._headers;
  }

   /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
	private handleError<T> (operation = 'operation', result?: T) {
	  return (error: any): Observable<T> => {

      if(error.status == 401 && error.statusText == 'OK'){
        this.router.navigate(['signin']);
      }
	    // TODO: send the error to remote logging infrastructure
	    console.error(error); // log to console instead

	    

	    // Let the app keep running by returning an empty result.
	    return of(result as T);
	  };
	}

   ngOnInit(){
   }

   /**
   *Get all organisations
   */
   public list(): Observable<Organisation[]>{
   	return this.http.get<Organisation[]>(environment.api+'/organisation/list')
   	.pipe(
   		catchError(this.handleError<Organisation[]>('organisation-list'))
   		);
   }

   /**
	* Get organisation by id
	* @param id - organisation document id
	*/
	public get(id: string): Observable<Organisation>{
		return this.http.get<Organisation>(environment.api+'/organisation/find/'+id)
		.pipe(
			catchError(this.handleError<Organisation>('organisation-get'))
			)
	}

	/**
	* Add an organization to the database
	* @param organisation - the organisation to add
	*/
  public add(organisation : Organisation): Observable<Organisation>{
    return this.http.post<Organisation>(environment.api+'/organisation/create',organisation)
    .pipe(
      catchError(this.handleError<Organisation>('organsiation'))
    )
  }

  /**
	* Update an organization to the database
	* @param organisation - the organisation to update
	*/
  public update(organisation : Organisation){
    return this.http.put<Organisation>(environment.api+'/organisation/update',organisation)
    .pipe(
      catchError(this.handleError<Organisation>('organsiation'))
    )
  }

  /**
	* Delete an organization from the database
	* @param organisation - the organisation to delete
	*/
  public delete(id : string){
    return this.http.delete<Organisation>(environment.api+'/organisation/delete/'+id)
      .pipe(
				catchError(this.handleError<Organisation>('organisation'))
			)
  }

  /**
  * Delete multiple organizations from the database
  * @param organisation - the organisation to delete
  */
  public bulkDelete(organisations : Organisation[]){
    return this.http.post<any>(environment.api+'/organisation/delete/',organisations)
      .pipe(
        catchError(this.handleError<any>('organisation-delete-multiple'))
      )
  }

  /**
  * Update an organization to the database
  * @param organisation - the organisation to update
  */
  public checkIfExists(nom: string){
    return this.http.get<Organisation>(environment.api+'/organisation/byName?nom='+nom)
    .pipe(
      catchError(this.handleError<Organisation>('organsiation'))
    )
  }


}

