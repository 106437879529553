import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import *  as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Intrant } from 'administration/models/intrant';
import { IntrantService } from 'administration/services/intrant/intrant.service';
import { AnalyseService } from 'administration/services/analyse/analyse.service';
import { Administrateur } from 'administration/models/administrateur';
import { AlignRule} from "global/modules/data-table/components/table.component";

import { ExportCsvService } from 'global/services/export-csv/export-csv.service';
import { ExporterComponent } from 'global/components/exporter/exporter.component';

import { CaracteristiquePhysicoChimique } from 'administration/models/caracteristique-physico-chimique';


@Component({
  selector: 'app-intrant-list',
  templateUrl: './intrant-list.component.html',
  styleUrls: ['./intrant-list.component.scss']
})
export class IntrantListComponent implements OnInit {

	public loggedUser :Administrateur;
	public toggledView: string = 'table';
	public intrants : any[] = [];
	public filteredIntrants : any[] = [];
	public selectedIntrants : Array<any>;
	public searchTerm : string = '';
	public headers : Array<any>;
	public columnList : Array<any> = [];
	private fieldNames;
	private nodes : any[] = [];
	private consistances;
	private categories;
	private sousProduitsAnimaux;
	private documentAEmettre;
	private onLoad = false;


  constructor(
	private router: Router, 
	private intrantService : IntrantService,
	private csvExporter: ExportCsvService,
    private modalService: NgbModal,
    private analyseService : AnalyseService
	) { }

	ngOnInit() {
		this.onLoad = true;
		this.headers = Object.keys(this.flatten(this.intrantService.constructIntrantDTO()));
		this.fieldNames = this.intrantService.fieldNames;
		this.headers.forEach(header=>{
			this.columnList.push(this.constructColumnObject(header));
		});
		this.intrantService.list()
			.subscribe((res: Intrant[])=> {
				res.forEach(intrant=>{
					if(intrant.analyses){
						let date : Date;
						intrant.analyses.dateAnalyse? date = new Date(intrant.analyses.dateAnalyse) : date = null;
						date? intrant.analyses.dateAnalyse = date.toLocaleDateString() : null;
					}
				})
				this.intrants = this.flattenArray(res);
				console.log(this.intrants);
				this.filteredIntrants = this.flattenArray(res);
				this.onLoad = false;
			});		
		this.selectedIntrants = [];
		this.categories = this.intrantService.categories;
		this.consistances = this.intrantService.consistances;
		this.sousProduitsAnimaux = this.intrantService.sousProduitsAnimaux;
		this.documentAEmettre = this.intrantService.documentAEmettre;

	}


	public toggleView(){
		this.toggledView = this.toggledView == 'list' ? 'table' : 'list';
	}

	public intrantDetail(id : string){
		if(this.selectedIntrants.length==0){
			this.router.navigate(['administration/intrants/update',id]);
		}
	}

	public addIntrant(type : string){
		type == 'form'?	this.router.navigate(['administration/intrants/add']): this.router.navigate(['administration/intrants/add_from_file']);
	}

	public searchTermChanged(){
		this.filteredIntrants = this.intrants.filter(intrants=>{
			return intrants.nom.toLowerCase().includes(this.searchTerm)
		})
	}

	public deleteIntrants(){
		this.selectedIntrants.forEach((row : any) =>{
			this.intrantService.delete(row.rowId).subscribe(res => {
				if(res && res.id) {
					this.intrantService.list()
					.subscribe((res: Intrant[])=> {this.intrants = res;this.filteredIntrants = res});
				}
				this.selectedIntrants = [];
			})
		})
	}

	onToggleRow(intrant : Intrant){
		if(this.selectedIntrants.includes(intrant.id)){
			this.selectedIntrants = this.selectedIntrants.filter(id => id!= intrant.id);
		}else{
			this.selectedIntrants.push(intrant.id)
		}
	}

	rowSelected(rows : any){
		this.selectedIntrants = rows;
	}

	colSelected(cols : any){
		this.columnList = cols;
	}

	rowClicked(row : any){
		row.rowId? this.intrantDetail(row.rowId) : null;
	}

	public sortByColumn(name: string){
		this.filteredIntrants.sort((a,b) =>{
		  return a[name] - b[name];
		})
	}

	deleteSelectedRows(){
		let help = _.differenceWith(this.intrants,this.filteredIntrants,(a,b) =>{
		  return a.id == b.rowId;
		})
		this.intrants = _.cloneDeep(help);
	}

	flatten(obj, path = null, result = null) {
	  var key, val, _path;
	  path = path || [];
	  result = result || {};
	  for (key in obj) {
	    val = obj[key];
	    _path = key;
	    if(path == "cpco"){
		  for(let cpc of obj){
		  	cpc = <CaracteristiquePhysicoChimique>cpc;
		    result[cpc.nom] = cpc.valeur
		  }
		  return result;
		}
	    if (val instanceof Object) {
	      this.flatten(val, _path, result);
	    } else {
	    	if(!_path.includes('analyse')){
	    		result[_path] = val;
	    	}
	    }
	  }
	  return result;
	};

	flattenArray(array : any[]){
		for(let i = 0; i <= array.length-1; i++){
			array[i] = this.flatten(array[i]);
		}		
		return array;
	}

	constructColumnObject(header : string){
		let col : any = {};
		col.dataKey = header;
		let unit ="";
		if(this.analyseService.units[col.dataKey]!=undefined){
			unit = " ("+this.analyseService.units[col.dataKey]+")";
			col.name = this.fieldNames[header]+unit;
			col.filterable = {
				type : "interval"
			}
			col.align = AlignRule.Right;
		}else if(["categorie","consistance","sousProduitsAnimaux","documentAEmettre"].includes(col.dataKey)){
			col.name = this.fieldNames[header];
			col.align = AlignRule.Left;
			let array = [];
			if(col.dataKey == "categorie"){
				array = this.intrantService.categories;
			}else if(col.dataKey == "consistance"){
				array = this.intrantService.consistances;
			}else{
				array = this.intrantService[col.dataKey];				
			}
			let tmpFilters = [];
			for(let obj in array){
				let tmp :any = {};
				tmp.label = array[obj];
				tmp.value = array[obj];
				tmpFilters.push(tmp);
			}
			col.filterable = {
				type : 'checkbox',
				filters : tmpFilters
			}
		}else{
			col.name = this.fieldNames[header];
			col.align = AlignRule.Left;
			col.filterable = {
				type : 'input'
			}
		}		
		this.intrantService.defaultColumnList.includes(header)? col.exclude = false : col.exclude = true;
		return col;
	}

	exportToCsv(){
		this.nodes = [];
		let data = _.intersectionWith(this.intrants,this.selectedIntrants,(a,b) =>{
      return a.id == b.rowId;
    });
    let nodeAgro : any = {};
    let nodeNutri : any = {};
    let nodeOligo : any = {};	    
    this.columnList.forEach((aCol : any, colIndex : number)=>{
    	if(!aCol.excluded){
    		let node : any = {};
    		node.id = colIndex;
	    	node.dataKey = aCol.dataKey;
	    	node.name = aCol.name;
	    	if(Object.keys(this.intrantService.constructIntrantDTO()).includes(aCol.dataKey)){
	    		this.nodes.push(node);
	    	}else if(Object.keys(this.intrantService.createTypeAnalyseDTO("analyseAgronomique")).includes(aCol.dataKey)){
	    		node.parent = -1;
	    		if(nodeAgro.id==-1){
	    			nodeAgro.children.push(node);
	    		}else{
	    			nodeAgro.id=-1;
	    			nodeAgro.dataKey = "analyseAgronomique";
	    			nodeAgro.name = this.intrantService.analyseNames["analyseAgronomique"];
	    			nodeAgro.children = [];
	    			nodeAgro.children.push(node)
	    		}
	    	}else if(Object.keys(this.intrantService.createTypeAnalyseDTO("analyseNutritionnelle")).includes(aCol.dataKey)){
	    		node.parent = -2;
	    		if(nodeNutri.id==-2){
	    			nodeNutri.children.push(node);
	    		}else{
	    			nodeNutri.id = -2;
	    			nodeNutri.dataKey = "analyseNutritionnelle";
	    			nodeNutri.name = this.intrantService.analyseNames["analyseNutritionnelle"];
	    			nodeNutri.children = [];
	    			nodeNutri.children.push(node);
	    		}
	    	}else if(Object.keys(this.intrantService.createTypeAnalyseDTO("analyseOligoEtm")).includes(aCol.dataKey)){
	    		node.parent = -3;
	    		if(nodeOligo.id==-3){
	    			nodeOligo.children.push(node);
	    		}else{
	    			nodeOligo.id = -3;
	    			nodeOligo.dataKey = "analyseOligoEtm";
	    			nodeOligo.name = this.intrantService.analyseNames["analyseOligoEtm"];
	    			nodeOligo.children = [];
	    			nodeOligo.children.push(node);
	    		}
	    	}
    	}
    })
    nodeAgro.id? this.nodes.push(nodeAgro):null;
    nodeNutri.id? this.nodes.push(nodeNutri):null;
    nodeOligo.id? this.nodes.push(nodeOligo):null;

    const modalRef = this.modalService.open(ExporterComponent,{ size: 'lg' });
    modalRef.componentInstance.nodes1 = this.nodes;
    modalRef.result
      .then(res => {
        console.log(res);
        this.csvExporter.createCSV(res,data,';','intrants')
      })
      .catch(res => console.log(res))


	}





}
