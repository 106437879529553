import {Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { CustomDatepickerI18n } from 'global/services/custom-date-picker-i18n/custom-date-picker-i18n.service';
import { PasswordModalComponent } from 'administration/components/password-modal/password-modal.component';
import { BlocAccessModalComponent } from 'administration/components/bloc-access-modal/bloc-access-modal.component';
import { ParametresService } from 'global/services/parametres/parametres.service';
import { LoginService } from 'app/services/login/login.service';
import { UserService } from 'administration/services/user/user.service';
import { SiteService } from 'administration/services/site/site.service';
import { UtilisateurOrganisation } from 'administration/models/utilisateur-organisation';
import { Site } from 'administration/models/site';
import { Administrateur } from 'administration/models/administrateur';
import { OrganisationService } from 'administration/services/organisation/organisation.service';
import { Organisation } from 'administration/models/organisation';


@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
  styleUrls: ['./user-update.component.scss']
})
export class UserUpdateComponent implements OnInit {

  public sites: Site[] = [];
  public user: UtilisateurOrganisation = new UtilisateurOrganisation();
  public invalidSubmitted = false;
  public emailExists = false;
  public loggedUser: Administrateur;
  private organisation: any = {};
  public parametres: any;
  public siteHeaders: any[];
  public siteColumnList: any[];
  public moduleColumnList: any[];
  public moduleHeaders: any[];
  oldUser: UtilisateurOrganisation = new UtilisateurOrganisation();
  selectAllBloc = false;
  selectedBloc: any[] = new Array<any>();
  loading = false;
  loadingMessage = '';
  blocCheckBox: boolean[] = new Array<boolean>();

  constructor(
    private appConfigService: ParametresService,
    private userService: UserService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private siteService: SiteService,
    private modalService: NgbModal,
    private organisationService: OrganisationService,
    private customDatePickerService: CustomDatepickerI18n
  ) {

  }

  async ngOnInit() {
    this.loggedUser = this.loginService.getLoggedUser();
    // get appConfig
    this.parametres = this.appConfigService.getParametresApplication();
    if (this.route.snapshot.paramMap.keys.indexOf('idOrga') >= 0) {
      this.organisationService.get(this.route.snapshot.paramMap.get('idOrga'))
        .subscribe((res: Organisation) => {
          this.organisation = res;
          this.sites = res.sites.map(site => {
            site.dateMonitoring = this.datePipe.transform(new Date(site.dateMonitoring), 'dd/MM/yyyy');
            site.dateCreation = this.datePipe.transform(new Date(site.dateCreation), 'dd/MM/yyyy');
            return site;
          });
          if (this.route.snapshot.paramMap.keys.indexOf('idUser') >= 0){
            this.userService.get(this.route.snapshot.paramMap.get('idUser'))
              .subscribe((res: UtilisateurOrganisation) => {
                this.user = res;
                this.oldUser = res;
              });
          }
        });
    }
    this.siteHeaders = this.siteService.headers;
    this.moduleHeaders = this.userService.moduleHeaders;
    this.constructModuleColumnList();
    this.constructSiteColumnList();
  }

  public userEmailChanged(){
    if (this.oldUser.email != this.user.email)
      this.userService.checkIfExists(this.user.email)
        .subscribe((res: boolean) => {
          if (res != undefined) {
            this.emailExists = res;
          }
        });
  }

  submit(formValid: boolean){
    formValid ? this.updateUser() : this.invalidSubmitted = true;
  }

  public updateUser(){
    this.user.createdBy = this.loggedUser.email;
    this.user.modifiedBy = this.loggedUser.email;
    this.user.modifiedOn = Date.now();
    this.user.sites = this.user.sites.map(site => {
      site.dateCreation = this.customDatePickerService.getModelFromDateString(site.dateCreation);
      site.dateMonitoring = this.customDatePickerService.getModelFromDateString(site.dateMonitoring);
      return site;
    });
    this.loading = true;
    this.loadingMessage = 'Ajout de l\'utilisateur ' + this.user.email;
    this.userService.update(this.user)
      .subscribe((res: any) => {
        if (res != null && res != undefined && res.id != undefined){
          this.router.navigate(['/administration/organisation/', this.organisation.id]);
        }
      });
  }

  public siteSelected(event: any){
    this.user.sites = this.organisation.sites
      .filter(site => event.map(e => e.rowId).includes(site.id));
  }


  public deleteSite(s: Site){
    if (this.user.sites != undefined && this.user.sites.length){
      const index = this.user.sites.map(site => site.id).indexOf(s.id);
      this.user.sites.splice(index, 1);
      // add to sites list
      this.sites.push(s);
    }
  }

  public changePassword(){
    const modal = this.modalService.open(PasswordModalComponent, {size: 'lg'});
    modal.componentInstance.user = this.user;
    modal.result.then(result => {
      this.loadingMessage = 'Changement de mot de passe';
      this.loading = true;
      this.userService.changePassword(result)
        .subscribe(res => {
          if (res != undefined){
            this.user.revision = res.revision;
            this.loading = false;
          }
        });
    })
    .catch(error => console.log(error));
  }

  public addBlocAccess(){
    const modal = this.modalService.open(BlocAccessModalComponent, {size: 'lg'});
    modal.componentInstance.cbest = this.organisation.nom === 'CBEST';
    modal.result.then(result => {
      if (result.modules && result.modules.length){
        if (!this.user.blocs) this.user.blocs = new Array<any>();
        const bloc = this.user.blocs.filter(bloc => bloc.id == result.id)[0];
        if (bloc) _.mergeWith(bloc.modules, result.modules);
        else this.user.blocs.push(result);
      }
    })
    .catch( error => console.log(error));
  }

  public deleteBlocs(){
    this.user.blocs = this.user.blocs.filter(bloc => !this.selectedBloc.map(b => b.id).includes(bloc.id));
  }

  public blockRowSelected(bloc: any){
    this.selectedBloc.push(bloc);
    this.selectedBloc = _.uniqBy(this.selectedBloc, 'id');
  }

  public updateBloc(bloc: any){
    const help = Object.assign({}, bloc);
    const modal = this.modalService.open(BlocAccessModalComponent, {size: 'lg'});
    modal.componentInstance.bloc = help;
    modal.componentInstance.modules = help.modules;
    delete help.modules;
    modal.result.then(result => {
      if (result.modules && result.modules.length){
        if (!this.user.blocs) this.user.blocs = new Array<any>();
        const bloc = this.user.blocs.filter(bloc => bloc.id == result.id)[0];
        if (bloc) bloc.modules = result.modules;
      }
    })
    .catch( error => console.log(error));
  }

  public toggleBlocSelection(){
    if (this.selectAllBloc){
      this.selectedBloc = this.user.blocs;
      this.blocCheckBox = this.user.blocs.map(b => true);
    }
    else{
      this.selectedBloc = [];
      this.blocCheckBox = this.user.blocs.map(b => false);
    }
  }

  constructSiteColumnList(){
    this.siteColumnList = [];
    for (const header of this.siteHeaders){
      const col: any = {};
      col.dataKey = header.nom;
      col.name = header.label;
      col.excluded = true;
      this.siteColumnList.push(col);
    }
  }

  constructModuleColumnList(){
    this.moduleColumnList = [];
    for (const header of this.moduleHeaders){
      const col: any = {};
      col.dataKey = header.nom;
      col.name = header.label;
      col.excluded = true;
      this.moduleColumnList.push(col);
    }
  }

}
