import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';
import { Site } from 'administration/models/site';

@Injectable()
export class SiteService {

	private _headers: any[];

  constructor(private http: HttpClient,private router : Router) { 
  	this._headers = [
      {nom:"nom",label:"Nom"},
      {nom: "adresse.codePostal", label: "Code postal"},
      {nom: "adresse.commune", label: "Commune"},
      {nom: "dateRaccordement", label: "Date de raccordement"},
      {nom: "dateMonitoring", label: "Date monitoring"}
    ]
  }

  public get headers(){
    return this._headers;
  }

  public banqueHeaders() {
  	return [
  		{nom:"libelle",label:"Libellé"},
      {nom: "domiciliationBancaire", label: "Domiciliation bancaire"},
      {nom: "titulaire", label: "Titulaire"},
      {nom: "codeBic", label: "Code BIC"},
      {nom: "codeIban", label: "Code IBAN"}
  	]
  }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
	private handleError<T> (operation = 'operation', result?: T) {
	  return (error: any): Observable<T> => {
	  	if(error.status == 401 && error.statusText == 'OK'){
	        this.router.navigate(['signin']);
	    }
	    // TODO: send the error to remote logging infrastructure
	    console.error(error); // log to console instead

	    

	    // Let the app keep running by returning an empty result.
	    return of(result as T);
	  };
	}

	/**
	* Delete site
	* @param id - site document id
	*/
	public delete(id: string): Observable<Site>{
		const httpOptions = {
		  headers: new HttpHeaders({
		  	'Content-type': 'application/json',
		    'Access-Control-Allow-Origin':'*'
		  })
		};
		return this.http.delete<Site>(environment.api+'/site/delete/'+id,httpOptions)
  		.pipe(
  			catchError(this.handleError<Site>('site-delete'))
			)
	}


	/**
	* Get site by id
	* @param id - site document id
	*/
	public get(id: string): Observable<Site>{
		const httpOptions = {
		  headers: new HttpHeaders({
		  	'Content-type': 'application/json',
		    'Access-Control-Allow-Origin':'*'
		  })
		};
		return this.http.get<Site>(environment.api+'/site/find/'+id,httpOptions)
  		.pipe(
  			catchError(this.handleError<Site>('site-get'))
			)
	}

	/**
	* Add site
	* @param site - new site to add
	*/
	public add(site: Site, files: any[]): Observable<Site>{
		let formData: FormData = new FormData();
		for(let i=0;i<files.length;i++){
      if(files[i].id == undefined){
      	formData.append(
	        `files`,
	        files[i].file,
	        files[i].name
	      );
      	site.biogasviewAttachments.push({
	      	name: files[i].name,
	      	documentType: files[i].documentType,
	      	size: files[i].size
	      })
      }
    }
    formData.append(`site`, new Blob([JSON.stringify(site)],{'type': 'application/json'}));
    const httpOptions = {
		  headers: new HttpHeaders({
		    'Access-Control-Allow-Origin':'*'
		  })
		};
		const req = new HttpRequest('POST', environment.api+'/site/create',formData,httpOptions);
		return this.http.request(req)
			.pipe(
  			catchError(this.handleError<any>('document'))
			)
	}

	/**
	* Update site
	* @param site - site to update
	*/
	public update(site: Site, files: any[]): Observable<Site>{
		let formData: FormData = new FormData();
		for(let i=0;i<files.length;i++){
			if(!site.biogasviewAttachments)
				site.biogasviewAttachments = new Array<any>();
      if(files[i].id == undefined){
      	formData.append(
	        `files`,
	        files[i].file,
	        files[i].name
	      );
	      site.biogasviewAttachments.push({
	      	name: files[i].name,
	      	documentType: files[i].documentType,
	      	size: files[i].size
	      })
      }
    }
    formData.append(`site`, new Blob([JSON.stringify(site)],{'type': 'application/json'}));
    const httpOptions = {
		  headers: new HttpHeaders({
		    'Access-Control-Allow-Origin':'*'
		  })
		};
		const req = new HttpRequest('PUT', environment.api+'/site/update',formData,httpOptions);
		return this.http.request(req)
			.pipe(
  			catchError(this.handleError<any>('document'))
			)
	}

	/**
	* List all sites
	*/
	public list(): Observable<Site[]>{
		const httpOptions = {
		  headers: new HttpHeaders({
		  	'Content-type': 'application/json',
		    'Access-Control-Allow-Origin':'*'
		  })
		};
		return this.http.get<Site[]>(environment.api+'/site/list',httpOptions)
  		.pipe(
  			catchError(this.handleError<Site[]>('site-list'))
			)
	}

	checkIfExists(site: Site): Observable<Site>{
		const httpOptions = {
		  headers: new HttpHeaders({
		  	'Content-type': 'application/json',
		    'Access-Control-Allow-Origin':'*'
		  })
		};
		const codeSite = site.codeSite ? site.codeSite : "";
		return this.http.get<Site>(environment.api+`/site/byCode/${codeSite}`,httpOptions)
  		.pipe(
  			catchError(this.handleError<Site>('site-code'))
			)
	}

}