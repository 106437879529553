import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Analyse } from 'administration/models/analyses';
import { IntrantService } from 'administration/services/intrant/intrant.service';
import { AnalyseService } from 'administration/services/analyse/analyse.service';
import { CpcService } from 'administration/services/cpc/cpc.service';


@Component({
  selector:'form-group-analyse',
  template:`
  <div class="row">
    <div class="col-md-4" *ngFor="let attr of analyseFor">
      <div class="form-group">
        <label for="attr.nom" >{{attr.label}}</label>
        <div class="input-group input-group-sm">
          <input class="form-control form-control-sm" type="number" [id]="attr.nom" [name]="attr.nom" [ngModel]="attr.valeur" (ngModelChange)="onInput($event,attr)" />
          <div *ngIf="attr.unit" class="input-group-append">
            <span class="input-group-text">{{attr.unit}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>`,
  styleUrls: ['./form-group-analyse.component.scss']
})
export class FormGroupAnalyseComponent implements OnInit{
  // ,ControlValueAccessor

  
  private _analyse;
  private fieldNames;
  private analyseNames;
  private units;

  //This little is used in the ngFor directive (remember, no functions in this directive)
  private analyseFor;

  @Output() changeAnalyse : EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set analyse(analyseObject){
    this._analyse = analyseObject;
  }

  get analyse(){
    return Object.entries(this._analyse);
  }

  constructor(private intrantService : IntrantService, private analyseService : AnalyseService, private cpcService : CpcService){
  }

  private setAttribute(value,attribute){
    console.log("analyse.cpc = ",this._analyse.cpc);
    let cpc = this.cpcService.transformToJson(this._analyse.cpc);
    cpc[attribute.nom].valeur = value;
    this._analyse.cpc = Object.values(cpc);
    console.log("---------------------");
    console.log("analyse.cpc = ",this._analyse.cpc);
    this.changeAnalyse.emit(this._analyse);
  }

  ngOnInit(){
    // console.log("On init");
    // console.log("Yeah?",analyse);    
    // console.log(this._attribute);
    // console.log("Initial value: ", analyse[this._attribute[0]]);
    this.analyseFor = Object.values(this._analyse.cpc);
    console.log(this._analyse.cpc); 
  }

  onInput(value,str : string){
    if(value){
      this.setAttribute(+value,str);
    }
  }


}