import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { NgbModule, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TreeModule } from 'angular-tree-component';

import { AppRoutingModule } from './app-routing.module';
import { GlobalModule } from 'global/global.module';
import { AdministrationModule } from 'administration/administration.module';
import { ConfigurationModule } from 'configuration/configuration.module';
import { LoginService } from 'app/services/login/login.service';
import { WelcomeService } from 'app/services/welcome/welcome.service';
import { ConnexionUtilisateurService } from 'administration/services/connexion-utilisateur/connexion-utilisateur.service';

import { AppComponent } from './app.component';
import { SigninComponent } from './components/signin/signin.component';



import 'reflect-metadata';

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent
  ],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    TreeModule,
    GlobalModule,
    ConfigurationModule,
    AdministrationModule
  ],
  providers: [
    LoginService,
    WelcomeService,
    ConnexionUtilisateurService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
