import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver/FileSaver';
import * as _ from 'lodash';

export interface CSVColumn{
	name: string;
	dataKey: string;
}

@Injectable()
export class ExportCsvService {

  constructor() { }

  /**
	* Create csv file
	* @param columns - csv file columns
	* @param data - csv data
	* @param separator - csv file separator
	* @param filename - csv file name
	*/
	createCSV(colums: CSVColumn[], data: any[],separator: string, filename: string){
		var csv = this.createHeader(colums,separator);
		filename += '.csv';
		_.each(data,(r: any) =>{
			for(let i=0; i< colums.length-1; i++){
				let value = _.get(r,colums[i].dataKey);
				csv += (value != undefined && value != null) ? value+separator : ''+separator;
			}
			csv += _.get(r,colums[colums.length-1].dataKey)+'\n';
		})
		const blob = new Blob([csv],{type: 'text/plain;charset=utf-8'})
		saveAs(blob, filename);
	}

	/**
	* Create csv file header
	* @param colums - csv columns
	* @param separator - csv separator
	*/
	private createHeader(colums: CSVColumn[], separator: string): string{
		let help  = '';
		for(let i = 0; i< colums.length-1; i++) help += colums[i].name+separator;
		help += colums[colums.length-1].name+'\n';
		return help;
	}
}
