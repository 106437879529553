import { Directive, ElementRef, ContentChild, AfterViewInit, Renderer2 } from '@angular/core';
import { SidebarTopLevelDirective } from './../sidebar-top-level/sidebar-top-level.directive';
import { SidebarTogglerDirective } from './../sidebar-toggler/sidebar-toggler.directive';
import { CloseMobileDirective } from './../close-mobile/close-mobile.directive';

@Directive({
	selector: '[nav-sidebar]'
})
export class NavSidebarDirective implements AfterViewInit{
	
	@ContentChild(SidebarTogglerDirective) toggler: SidebarTogglerDirective;
	@ContentChild(CloseMobileDirective) closeMobile: CloseMobileDirective;
	@ContentChild(SidebarTopLevelDirective) topLevel: SidebarTopLevelDirective;

	constructor(
		private el: ElementRef,
		private renderer: Renderer2
	) {
		
	}

	get element(){
		return this.el;
	}

	ngAfterViewInit(){
		// top level items
		if(this.topLevel != undefined && this.topLevel.items != undefined){
			this.topLevel.items.forEach(item =>{
				//mouse enter listener
				this.renderer.listen(item.element.nativeElement, 'mouseenter',(event)=>{
					let offset = item.element.nativeElement.getBoundingClientRect().top-56;
					if(!this.hasClass(this.el.nativeElement.classList,'sidebar-expanded-mobile')){
						if(this.hasClass(this.el.nativeElement.classList,'sidebar-collapsed-desktop')){
							this.renderer.addClass(item.element.nativeElement,'is-over');
							this.renderer.addClass(item.element.nativeElement,'is-showing-fly-out');
							if(item.subItem){
								this.renderer.setStyle(item.subItem.element.nativeElement,'display','block');
								this.renderer.setStyle(item.subItem.element.nativeElement,'transform',`translate3d(50px,${offset}px,0px)`);
							}
						}else if(!this.hasClass(item.element.nativeElement.classList,'active')){
							this.renderer.addClass(item.element.nativeElement,'is-over');
							this.renderer.addClass(item.element.nativeElement,'is-showing-fly-out');
							if(item.subItem){
								this.renderer.setStyle(item.subItem.element.nativeElement,'display','block');
								this.renderer.setStyle(item.subItem.element.nativeElement,'transform',`translate3d(220px,${offset}px,0px)`);
							}
						}
					}
					
					
				})
				//mouse leave listener
				this.renderer.listen(item.element.nativeElement, 'mouseleave',(event)=>{
					this.renderer.removeClass(item.element.nativeElement,'is-over');
					this.renderer.removeClass(item.element.nativeElement,'is-showing-fly-out');
					if(item.subItem) this.renderer.setAttribute(item.subItem.element.nativeElement,'style','');
				})
			})
		}
		// mobile close
		if(this.closeMobile != undefined){
			this.renderer.listen(this.closeMobile.element.nativeElement,'click',(event) =>{
				this.renderer.removeClass(this.element.nativeElement,'sidebar-expanded-mobile');
			})
		}
		
	}

	/**
	* check if element has a css class
	* @param classList - element class list
	* @param csClass - css class to find
	*/
	private hasClass(classList: DOMTokenList, cssClass: string): boolean{
		return classList.contains(cssClass);
	}
	/**
	* toggle css class to element
	* @param element 
	* @param csClass 
	*/
	private toggleClass(element: ElementRef, cssClass: string){
		if(this.hasClass(element.nativeElement.classList,cssClass)) 
			this.renderer.removeClass(element.nativeElement,cssClass);
		else this.renderer.addClass(element.nativeElement,cssClass);
	}


}
