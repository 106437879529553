import { Exclude, Expose, Type } from 'class-transformer';
import { CaracteristiquePhysicoChimique } from './caracteristique-physico-chimique';


@Exclude()
export class AnalyseSanitaire {
	// @Expose()
	// salmonella : number = null;

	// @Expose()
 //    oeufsHelminthes : number = null;

 //    @Expose()
 //    germesButyriques : number = null;

 //    @Expose()
 //    coliformesThermotolerants : number = null;

 //    @Expose()
 //    escherichiaColi : number = null;

 //    @Expose()
 //    sporesAnaerobies : number = null;

    @Type(()=>CaracteristiquePhysicoChimique)
    cpc : CaracteristiquePhysicoChimique[] = [];
    constructor(){}
}
