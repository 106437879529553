import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ConfigurationComponent } from './components/configuration.component';
import { LogComponent } from './components/log/log.component';

const routes = [
	{
		path: 'configuration', component: ConfigurationComponent,
		children:[
			{
				path: 'log',
				children:[
					{path: 'list', component: LogComponent}
				]
			}
		]
	}
]

@NgModule({
	exports:[
		RouterModule
	],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes,{useHash: true})
  ],
  declarations: []
})
export class ConfigurationRoutingModule { }
