import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { Utilisateur } from 'administration/models/utilisateur';
import { UserExterneService } from 'administration/services/user-externe/user-externe.service';
import { BreadcrumbService } from 'global/services/breadcrumb/breadcrumb.service';
import { ConfirmationGuardService } from 'global/services/confirmation-guard/confirmation-guard.service';

import { Site } from 'administration/models/site';

@Component({
  selector: 'app-user-externe-list',
  templateUrl: './user-externe-list.component.html',
  styleUrls: ['./user-externe-list.component.scss'],

})
export class UserExterneListComponent implements OnInit {

	users : Utilisateur[] = [];
  filteredUsers: Utilisateur[] = [];
  selectedUsers: Utilisateur[] ;
  headers : any[] = new Array<any>();
  view: string = 'table';
  columnList: any[] = [];
  loading: boolean = false;
  loadingMessage: string = '';

  constructor(
    private router : Router, 
    private userService : UserExterneService,
    private confirmationGuardService: ConfirmationGuardService,
    private breadcrumbService: BreadcrumbService,
  ) { }

  ngOnInit() {
    this.breadcrumbService.add({label : "Utilisateurs externes", route : this.router.routerState.snapshot.url});
  	this.fetchData();
    this.headers = this.userService.headers;
    this.constructColumnList();
  }

  userDetail(id : string ) {
  	this.router.navigate(['administration/user/update',id]);
  }

  addUser(){
  	this.router.navigate(['administration/user/add']);
  }

  delete(id : string){
    this.userService.delete(id)
    .subscribe(res=>{
      if(res != undefined && res.id){
        this.users = this.users.filter(user => user.id !== id);
        this.router.navigate(['administration/user/list']);
      } 
    })
  }

  userAdd(){
    this.router.navigate(['administration/user/add']);
  }

  userRemove(user : Utilisateur){
    this.confirmationGuardService.confirm('Voulez-vous vraiment supprimer l\'utilisateur '+user.email+' ?')
      .then(result =>{
        this.userService.delete(user.id)
          .subscribe(res=>{
            if(res && res.id) this.fetchData();
          })
      })
      .catch(error => console.log(error));
  }

  userSelected(event: any){
    this.selectedUsers = event.length ? this.users.filter(orga => event.map(e => e.rowId).includes(orga.id)) : undefined;
  }
  deleteSelectedUser(){
    let message = this.selectedUsers.length == 1 ? "Voulez-vous supprimer cette user ?" : "Voulez-vous supprimer ces "+this.selectedUsers.length+" users?";
    this.confirmationGuardService.confirm(message)
      .then(confirm =>{
        this.loading = true;
        this.loadingMessage = "Suppression d'users";
        this.selectedUsers = this.selectedUsers.map(user =>{
          delete user.sites;
          return user;
        })
        this.userService.bulkDelete(this.selectedUsers)
          .subscribe(result =>{
            if(result && !result.error) {
              this.loading = false;
              this.fetchData();
            }
            this.selectedUsers = [];
          })
      })
      .catch(cancel => console.log(cancel))
  }

  searchTermChanged(term : string){
    this.filteredUsers = this.users.filter(tier => {
      for(let header of this.headers){
        let value = _.get(tier,header.nom);
        if(value && value.toString().toLowerCase().includes(term.toLowerCase())) return true;
      }
      return false;
    })
    return this.filteredUsers;
  }

  toggleView(view : string){
    this.view = view;
  }

  getCardValue(recep : Utilisateur, key : string){
    return _.get(recep,key);
  }

  private fetchData(){
    this.loading = true;
    this.loadingMessage = 'Chargement de la liste des users';
    this.userService.list()
    .subscribe(users=>{
      if(users.length > 0 ){
        this.users = users.filter(user => user.id);
        this.filteredUsers = this.users;
        this.loading = false;
      }
    })
  }

  private constructColumnList(){
    this.columnList = [];
    for(let header of this.headers){
      let col : any = {};
      col.dataKey = header.nom;
      col.name = header.label;
      col.excluded = true;
      this.columnList.push(col);
    }
  }

}
