import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ISortService } from '../interfaces/ISortService';
import { IColumn } from '../interfaces/IColumn';
import { SortDirection } from '../enums/sort-direction';
import { IRow } from '../interfaces/IRow';
import { ICell } from '../interfaces/ICell';
import { CustomDatepickerI18n } from 'global/services/custom-date-picker-i18n/custom-date-picker-i18n.service';

@Injectable()
export class SortService implements ISortService{
	private columnToSort_: IColumn;
  private isEnabled_: boolean;
  private direction_: SortDirection;

  constructor(private _dateService : CustomDatepickerI18n){
    this.direction_ = SortDirection.Ascending;
  }

  setColumnToSort(column: IColumn) :void{
    if(!column){
      return;
    }
    this.calculateSortDirection(column);
    this.columnToSort_ = column;
  }

  transformRows(rows: Array<IRow>):Array<IRow> {
    if(this.isSortingEnabled() == false || this.isSortingNeeded() == false){
      return rows;
    }
    let res;
    res = this.applySortOnColumn(rows);
    res = this.applySortDirection(res);
    return res;
  }

  isSortingNeeded(): boolean{
    return this.columnToSort_ != undefined;
  }

  isSortingEnabled(): boolean{
    return this.isEnabled_;
  }

  setSortingEnabled(isEnabled: boolean): void{
    this.isEnabled_ = isEnabled ? true : false;
  }

  isSortedAscending():boolean {
    return this.direction_ == SortDirection.Ascending;
  }

  isSortedDescending():boolean {
    return this.direction_ == SortDirection.Descending;
  }

  isSortedByColumn(column:IColumn): boolean {
    return this.columnToSort_ && column && this.columnToSort_.dataKey == column.dataKey;
  }

  private applySortOnColumn(rows:Array<IRow>): Array<any> {
    let res = _.sortBy(rows, (aRow:IRow) => {
      let index = _.findIndex(aRow.value, (cell:ICell) => {
        return cell.dataKey == this.columnToSort_.dataKey;
      });
	  if(this._dateService.isDateString(aRow.value[index].value)){
		  return this._dateService.getModelFromDateString(aRow.value[index].value);
	  }
	  try{
		  return aRow.value[index].value.toLowerCase();
	  } catch(error){
	  }
	  return aRow.value[index].value;
    });
    return res;
  }

  private calculateSortDirection(column:IColumn) {
    if(!this.columnToSort_){
      this.direction_ = SortDirection.Ascending;
      return;
    }

    if(column.dataKey != this.columnToSort_.dataKey){
      this.direction_ = SortDirection.Ascending;
    }else{
      this.direction_ = this.direction_ == SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;
    }
  }

  private applySortDirection(res:IRow[]) {
    if(this.direction_ == SortDirection.Descending){
      res = _.reverse(res);
    }
    return res;
  }
}
