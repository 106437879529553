import { Injectable } from '@angular/core';
import { Analyse } from 'administration/models/analyses';
import { AnalyseAgronomique } from 'administration/models/analyse-agronomique';
import { AnalyseBiologique } from 'administration/models/analyse-biologique';
import { AnalyseBMP } from 'administration/models/analyse-bmp';
import { AnalyseCTO } from 'administration/models/analyse-cto';
import { AnalyseNutritionnelle } from 'administration/models/analyse-nutritionnelle';
import { AnalyseOligoEtm } from 'administration/models/analyse-oligo-etm';
import { AnalyseSanitaire } from 'administration/models/analyse-sanitaire';
import { CaracteristiquePhysicoChimique } from 'administration/models/caracteristique-physico-chimique';




@Injectable()
export class AnalyseService {

  private _typesAnalyse = ["analyseBMP","analyseCTO","analyseNutritionnelle",
"analyseAgronomique","analyseBiologique","analyseOligoEtm","analyseSanitaire"];

  private _typesAnalyseDTO = ["analyseAgronomique","analyseOligoEtm","analyseNutritionnelle"];

  private _analyseNames = {
    analyseOligoEtm : "Oligo-éléments et ETM",
    analyseOligoETM : "Oligo-éléments et ETM",
    analyseAgronomique : "Valeurs agronomiques",
    analyseBiologique : "Analyse biologique",
    analyseCTO : "Analyse CTO",
    analyseNutritionnelle : "Valeurs nutritionnelles",
    analyseBMP : "Analyse BMP",
    analyseSanitaire : "Analyse sanitaire"
  }

  constructor() { }

  public createTypeAnalyse(analyseName){
  	switch (analyseName) {
  		case "analyseBMP":
  			return JSON.parse(JSON.stringify(new AnalyseBMP));
  		case "analyseCTO":
  			return JSON.parse(JSON.stringify(new AnalyseCTO));
  		case "analyseNutritionnelle":
  			return JSON.parse(JSON.stringify(new AnalyseNutritionnelle));
  		case "analyseAgronomique":
  			return JSON.parse(JSON.stringify(new AnalyseAgronomique));
  		case "analyseBiologique":
  			return JSON.parse(JSON.stringify(new AnalyseBiologique));
  		case "analyseOligoEtm":
  			return JSON.parse(JSON.stringify(new AnalyseOligoEtm));
      case "analyseOligoETM":
        return JSON.parse(JSON.stringify(new AnalyseOligoEtm));
  		case "analyseSanitaire":
  			return JSON.parse(JSON.stringify(new AnalyseSanitaire));
  		
  		default:
  			// code...
  			break;
  	}
  }

  public newAnalyse(){
    return new Analyse();
  }

  public newAnalyseDTO(){
    let analyse = new Analyse;
    analyse.typeAnalyse = "Labo"
    //we don't want all these types
    analyse.analyseBiologique = undefined;
    analyse.analyseBMP = undefined;
    analyse.analyseCTO = undefined;
    analyse.analyseSanitaire = undefined;
    return analyse as Analyse;
  }

  public createTypeAnalyseDTO(analyseName){
    switch (analyseName) {
      case "analyseNutritionnelle":
        let analyseN = this.newAnalyseNutritionnelle();
        console.log(analyseN);
        return JSON.parse(JSON.stringify(analyseN));
      case "analyseAgronomique":
        let analyseA = this.newAnalyseAgronomique();
        return JSON.parse(JSON.stringify(analyseA));      
      case "analyseOligoEtm":
        let analyseO = this.newAnalyseOligoEtm();
        return JSON.parse(JSON.stringify(analyseO));
      case "analyseOligoETM":
        let analyseO2 = this.newAnalyseOligoEtm();
        return JSON.parse(JSON.stringify(analyseO2));
      default:
        // code...
        break;
    }
  }

  public newAnalyseNutritionnelle(){
    let analyse = new AnalyseNutritionnelle;
    analyse.cpc = [
      new CaracteristiquePhysicoChimique("carboneAzote", this._fieldNames["carboneAzote"], this.units["carboneAzote"], null, false),
      new CaracteristiquePhysicoChimique("cendreTotal", this._fieldNames["cendreTotal"], this.units["cendreTotal"], null, false),
      new CaracteristiquePhysicoChimique("carboneOrganique", this._fieldNames["carboneOrganique"], this.units["carboneOrganique"], null, false),
      new CaracteristiquePhysicoChimique("celluloseBrute", this._fieldNames["celluloseBrute"], this.units["celluloseBrute"], null, false),
      new CaracteristiquePhysicoChimique("digestibiliteMo", this._fieldNames["digestibiliteMo"], this.units["digestibiliteMo"], null, false),
      new CaracteristiquePhysicoChimique("extractifNonAzoteReel", this._fieldNames["extractifNonAzoteReel"], this.units["extractifNonAzoteReel"], null, false),
      new CaracteristiquePhysicoChimique("matiereGrasseBrute", this._fieldNames["matiereGrasseBrute"], this.units["matiereGrasseBrute"], null, false),
      new CaracteristiquePhysicoChimique("matiereOrganiqueIndigeree", this._fieldNames["matiereOrganiqueIndigeree"], this.units["matiereOrganiqueIndigeree"], null, false),
      new CaracteristiquePhysicoChimique("proteineBrute", this._fieldNames["proteineBrute"], this.units["proteineBrute"], null, false),
      new CaracteristiquePhysicoChimique("proteineBruteReelle", this._fieldNames["proteineBruteReelle"], this.units["proteineBruteReelle"], null, false),
    ];
    return analyse;
  }

  public newAnalyseAgronomique(){
    let analyse = new AnalyseAgronomique;
    analyse.cpc = [
      new CaracteristiquePhysicoChimique("ammoniacalNonIonique", this._fieldNames["ammoniacalNonIonique"], this.units["ammoniacalNonIonique"], null, false),
      new CaracteristiquePhysicoChimique("azoteNitrique", this._fieldNames["azoteNitrique"], this.units["azoteNitrique"], null, false),
      new CaracteristiquePhysicoChimique("calciumCaO", this._fieldNames["calciumCaO"], this.units["calciumCaO"], null, false),
      new CaracteristiquePhysicoChimique("magnesiumMgO", this._fieldNames["magnesiumMgO"], this.units["magnesiumMgO"], null, false),
      new CaracteristiquePhysicoChimique("sodiumNa2O", this._fieldNames["sodiumNa2O"], this.units["sodiumNa2O"], null, false),
      new CaracteristiquePhysicoChimique("souffreSO3", this._fieldNames["souffreSO3"], this.units["souffreSO3"], null, false),
      new CaracteristiquePhysicoChimique("chlorures", this._fieldNames["chlorures"], this.units["chlorures"], null, false),
    ];
    return analyse;
  }

  public newAnalyseOligoEtm(){
    let analyse = new AnalyseOligoEtm;
    analyse.cpc = [
      new CaracteristiquePhysicoChimique("bore", this._fieldNames["bore"], this.units["bore"], null, false),
      new CaracteristiquePhysicoChimique("fer", this._fieldNames["fer"], this.units["fer"], null, false),
      new CaracteristiquePhysicoChimique("manganese", this._fieldNames["manganese"], this.units["manganese"], null, false),
      new CaracteristiquePhysicoChimique("molybdene", this._fieldNames["molybdene"], this.units["molybdene"], null, false),
      new CaracteristiquePhysicoChimique("cobalt", this._fieldNames["cobalt"], this.units["cobalt"], null, false),
      new CaracteristiquePhysicoChimique("cadmium", this._fieldNames["cadmium"], this.units["cadmium"], null, false),
      new CaracteristiquePhysicoChimique("chrome", this._fieldNames["chrome"], this.units["chrome"], null, false),
      new CaracteristiquePhysicoChimique("cuivre", this._fieldNames["cuivre"], this.units["cuivre"], null, false),
      new CaracteristiquePhysicoChimique("mercure", this._fieldNames["mercure"], this.units["mercure"], null, false),
      new CaracteristiquePhysicoChimique("nickel", this._fieldNames["nickel"], this.units["nickel"], null, false),
      new CaracteristiquePhysicoChimique("plomb", this._fieldNames["plomb"], this.units["plomb"], null, false),
      new CaracteristiquePhysicoChimique("zinc", this._fieldNames["zinc"], this.units["zinc"], null, false),
      new CaracteristiquePhysicoChimique("phosphoreP", this._fieldNames["phosphoreP"], this.units["phosphoreP"], null, false),
      new CaracteristiquePhysicoChimique("potassiumK", this._fieldNames["potassiumK"], this.units["potassiumK"], null, false),
      new CaracteristiquePhysicoChimique("calciumCa", this._fieldNames["calciumCa"], this.units["calciumCa"], null, false),
      new CaracteristiquePhysicoChimique("magnesiumMg", this._fieldNames["magnesiumMg"], this.units["magnesiumMg"], null, false),
      new CaracteristiquePhysicoChimique("sodiumNa", this._fieldNames["sodiumNa"], this.units["sodiumNa"], null, false),
      new CaracteristiquePhysicoChimique("souffreS", this._fieldNames["souffreS"], this.units["souffreS"], null, false),
      new CaracteristiquePhysicoChimique("selenium", this._fieldNames["selenium"], this.units["selenium"], null, false),
      new CaracteristiquePhysicoChimique("arsenic", this._fieldNames["arsenic"], this.units["arsenic"], null, false),
      new CaracteristiquePhysicoChimique("crCuNiZn", this._fieldNames["crCuNiZn"], this.units["crCuNiZn"], null, false),
    ];
    return analyse;
  } 

  public toDTO(targetJson, curJson){
    let curArray = Object.entries(curJson);
    for(let field of curArray){
      if(targetJson[field[0]]===null) targetJson[field[0]] = field[1];
    }
    return targetJson;
  }

  get typesAnalyseDTO(){
    return this._typesAnalyseDTO;
  }

  get typesAnalyse(){
    return this._typesAnalyse;
  }

  get analyseNames(){
    return this._analyseNames;
  }

  get units(){
    return this._units;
  }

  public calcPB(pm,ch4,mo){
    return +((ch4*pm)/mo).toFixed(1);
  }

  public calcPM(pb,ch4,mo){
    return +((pb*mo)/(ch4)).toFixed(1);
  }

  private _units = {
    matiereSeche : "%MS/MB",
    matiereOrganique : "%MO/MB",
    potentielMethane : "Nm3",
    azoteAmmoniacal : "g/kg MB",
    azoteTotal : "g/kg MB",
    phosphoreP2O5 :"g/kg MB",
    potassiumK2O : "g/kg MB",
    ammoniacalNonIonique : "g/kg MB",
    azoteNitrique : "g/kg MB",
    calciumCaO : "g/kg MB",
    magnesiumMgO : "g/kg MB",
    sodiumNa2O : "g/kg MB",
    souffreSO3 : "g/kg MB",
    chlorures : "g/kg MB",
    tauxCh4 : "%",
    nh4 : "-",
    nh4N : "-",
    potentielBiogaz : "Nm3",
    cn : "-",
    cN : "-",
    carboneAzote : "-",
    carboneOrganique : "g/kg",
    celluloseBrute : "%",
    cendreTotal : "%",
    digestibiliteMo : "%",
    extractifNonAzoteReel : "%",
    matiereGrasseBrute : "%",
    matiereOrganiqueIndigeree : "%",
    proteineBrute : "%",
    proteineBruteReelle : "%",
    bore : "mg/kg MB",
    fer : "mg/kg MB",
    manganese : "mg/kg MB",
    molybdene : "mg/kg MB",
    cobalt : "mg/kg MB",
    cadmium : "mg/kg MB",
    chrome : "mg/kg MB",
    cuivre : "mg/kg MB",
    mercure : "mg/kg MB",
    nickel : "mg/kg MB",
    plomb : "mg/kg MB",
    zinc : "mg/kg MB",
    phosphoreP : "mg/kg MB",
    potassiumK : "mg/kg MB",
    calciumCa : "mg/kg MB",
    magnesiumMg : "mg/kg MB",
    sodiumNa : "mg/kg MB",
    souffreS : "mg/kg MB",
    selenium : "mg/kg MB",
    arsenic : "mg/kg MB",
    crCuNiZn : "mg/kg MB",
    salmonella : "Salmonella",
    oeufsHelminthes : "Oeufs d'helmynthes",
    germesButyriques : "Germes butyriques",
    coliformesThermotolerants : "Coliformes thermotolérants",
    escherichiaColi : "Escherichia Coli",
    sporesAnaerobies : "Spores d'anaérobies sulfito-réducteurs",
    acideAcetique : "Acide acétique",
    acidePropionique : "Acide propionique",
    acideIsoButyrique : "Acide iso-butyrique",
    acideNButyrique : "Acide n-butyrique",
    acideIsoValerique : "Acide iso-valerique",
    acideNValerique : "Acide n-valerique",
    ph : "Ph",
    agvTotal : "AGV total", 
    tac : "TAC",
    fos : "FOS",
    fostac : "FOSTAC", 
    fluoranthene : "Fluoranthene",
    benzoFluoranthene : "Benzo-fluoranthene",
    benzoPyrene : "Benzo-pyrene",
    pcbTotal : "PCB total",
    hapTotaux : "Hap totaux"
  }

  private _fieldNames = {
    ms : "Matière sèche",
    mo : "Matière organique",
    matiereSeche : "Matière sèche",
    matiereOrganique : "Matière organique",
    codeDechet : "Code déchet",
    typeAnalyse : "Type d'analyse",
    nom : "Description",
    categorie : "Catégorie",
    siteMetha : "Site de méthanisation",
    code : "Code",
    nbValeursObligatoires :"# valeurs obligatoires",
    laboratoire : "Laboratoire",
    commentaire : "Commentaires",
    consistance : "Consistance",
    modeDeLivraison : "Mode de livraison",
    sousProduitsAnimaux  : "Sous-produit-animaux",
    documentAEmettre : "Document à émettre",
    tags : "Tags",
    potentielMethane : "Potentiel méthane",
    azoteAmmoniacal : "Azote ammoniacal",
    azoteTotal : "Azote totale",
    phosphoreP2O5 :"Phosphore (P2O5)",
    potassiumK2O : "Potassium (K2O)",
    dateAnalyse : "Date d'analyse",
    ammoniacalNonIonique : "Ammoniacal non ionique",
    azoteNitrique : "Azote nitrique",
    calciumCaO : "Calcium (CaO)",
    magnesiumMgO : "Magnesium (MgO)",
    sodiumNa2O : "Sodium (Na2O)",
    souffreSO3 : "Souffre (SO3)",
    chlorures : "Chlorures",
    acideAcetique : "Acide acétique",
    acidePropionique : "Acide propionique",
    acideIsoButyrique : "Acide iso-butyrique",
    acideNButyrique : "Acide n-butyrique",
    acideIsoValerique : "Acide iso-valerique",
    acideNValerique : "Acide n-valerique",
    ph : "Ph",
    agvTotal : "AGV total", 
    tac : "TAC",
    fos : "FOS",
    fostac : "FOSTAC",
    tauxCh4 : "Taux CH4",
    nh4 : "NH4/N",
    nh4N : "NH4/N",
    potentielBiogaz : "Potentiel biogaz", 
    fluoranthene : "Fluoranthene",
    benzoFluoranthene : "Benzo-fluoranthene",
    benzoPyrene : "Benzo-pyrene",
    pcbTotal : "PCB total",
    hapTotaux : "Hap totaux",
    cn : "C/N",
    cN : "C/N",
    carboneAzote : "C/N",
    carboneOrganique : "Carbone organique",
    celluloseBrute : "Cellulose brute",
    cendreTotal : "Cendre total",
    digestibiliteMo : "Digestibilité de la MO",
    extractifNonAzoteReel : "Extractif non azoté réel",
    matiereGrasseBrute : "Matière grasse brute",
    matiereOrganiqueIndigeree : "Matière organique indigérée",
    proteineBrute : "Protéine brute",
    proteineBruteReelle : "Protéine brute réelle",
    bore : "Bore",
    fer : "Fer",
    manganese : "Manganese",
    molybdene : "Molybdene",
    cobalt : "Cobalt",
    cadmium : "Cadmium",
    chrome : "Chrome",
    cuivre : "Cuivre",
    mercure : "Mercure",
    nickel : "Nickel",
    plomb : "Plomb",
    zinc : "Zinc",
    phosphoreP : "Phosphore (P)",
    potassiumK : "Potassium (K)",
    calciumCa : "Calcium (Ca)",
    magnesiumMg : "Magnesium (Mg)",
    sodiumNa : "Sodium (Na)",
    souffreS : "Souffre (S)",
    selenium : "Selenium",
    arsenic : "Arsenic",
    crCuNiZn : "Cr+Cu+Ni+Zn",
    salmonella : "Salmonella",
    oeufsHelminthes : "Oeufs d'helmynthes",
    germesButyriques : "Germes butyriques",
    coliformesThermotolerants : "Coliformes thermotolérants",
    escherichiaColi : "Escherichia Coli",
    sporesAnaerobies : "Spores d'anaérobies sulfito-réducteurs"
  }

}

