import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as uuid from 'uuid/v4';
import * as _ from 'lodash';

import { Banque } from '../../models/banque';
@Component({
  selector: 'app-banque-modal',
  templateUrl: './banque-modal.component.html',
  styleUrls: ['./banque-modal.component.scss']
})
export class BanqueModalComponent implements OnInit {

	@Input() banque: any;
	@Input() banques: any[];
	labelleExists: boolean;
  private help: any;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  	if(!this.banque){
  		this.banque = new Banque();
  		this.banque.id = uuid();
  	} 
    this.help= _.cloneDeep(this.banque);
  }

  onBlur(){
    if(this.banques && this.banques.find(b => b.libelle == this.help.libelle && b.id !== this.help.id)) {
      this.labelleExists = true;
    } else{
      this.labelleExists = false;
    }
  }

  save(){
  	if(this.labelleExists) {
  		return ;
  	}
    _.merge(this.banque, this.help);
  	this.activeModal.close(this.banque);
  }

  close(){
    this.activeModal.dismiss('dismiss');
  }

}
